import {Component, Input, EventEmitter, OnChanges, OnInit, Output} from '@angular/core';
import {BlobService} from "../../../../core/base/blob.service";
import {FileNode} from "../../../../core/cms/interfaces/file.node.interface";
import {MatTableDataSource, MatTable} from "@angular/material/table";
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {NewFolderComponent} from "../new-folder/new-folder.component";
import {ConfirmationDialogComponent} from "../../../../shared/confirmation-dialog/confirmation-dialog.component";
import {ConfirmationDialogModel} from "../../../../core/deliveries/models/confirmation.dialog.model";
import { Clipboard } from '@angular/cdk/clipboard';
import {DialogComponent} from "../../../../shared/dialog/dialog.component";
import {DialogTextModel} from "../../../../core/cms/models/dialog.text.model";
import {FileUploadComponent} from "../../file-upload/file-upload.component";

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrl: './file-list.component.scss'
})
export class FileListComponent implements OnInit, OnChanges {
  @Input() container = 'cms';
  @Input() folder: string = '';
  @Input() user: any;
  children: any[] = [];
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['filename', 'type', 'modified_by', 'date', 'actions'];
  private ngUnsubscribe$ = new Subject<void>();
  success = '';
  error = '';
  @Output() newFolder = new EventEmitter();

  constructor(private blobService: BlobService,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    if (this.container) {
      this.listFiles(this.container, this.folder);
    }
  }

  ngOnChanges(e: any) {
    if (this.container) {
      this.listFiles(this.container, this.folder);
    }
  }

  async listFiles(container: string, folder: string) {
    let clist = [];

    if (container) {
      const list = await this.blobService.fileListInFolder(container, folder);
      for await (const item of list) {
        clist.push(item);
      }
    }

    this.dataSource.data = [];
    this.dataSource.data = clist;
    this.children = clist;
  }

  findType(node: FileNode) {
    let type: string;
    const contentType = node.properties.contentType.toLowerCase();

    switch (contentType) {
      case 'image/jpeg':
        type = 'JPG';
        break;
      case 'image/svg+xml':
        type = 'SVG';
        break;
      case 'image/png':
        type = 'PNG';
        break;
      case 'application/pdf':
        type = 'PDF';
        break;
      default:
        type = 'text';
        break;
    }


    return type;
  }

  getDate(createdOn: any) {
    const date = createdOn.toString().split(', ');
    return Date.parse(date[date.length - 1].trim());
  }

  getName(name: string) {
    return name.replace(this.folder, '').trim();
  }


  remove(actions: any) {
    this.blobService.deleteFile(actions.name, this.container);
    this.listFiles(this.container, this.folder);
  }


  addFolder() {
    let fileConfig = new MatDialogConfig();
    fileConfig.autoFocus = true;
    fileConfig.width = '35rem';

    fileConfig.data = {
      container: this.container,
      folder: this.folder,
      user: this.user
    }

    this.dialog.open(NewFolderComponent, fileConfig).afterClosed()
    .pipe(takeUntil(this.ngUnsubscribe$)).subscribe((result: any) => {
      this.listFiles(this.container, this.folder);

      if (!result.error) {
        this.success = 'Folder ' + result.filename + ' saved';
        this.newFolder.next(result.filename);
        this.notify();
      } else {
        this.error = 'There was an error while saving your folder, please, try again later.'
      }
    })
  }

  addFile() {
    let fileConfig = new MatDialogConfig();
    fileConfig.autoFocus = true;
    fileConfig.width = '35rem';

    fileConfig.data = {
      container: this.container,
      folder: this.folder,
      user: this.user
    }

    this.dialog.open(FileUploadComponent, fileConfig).afterClosed()
    .pipe(takeUntil(this.ngUnsubscribe$)).subscribe((result: any) => {
      this.listFiles(this.container, this.folder);

      if (!result.error) {
        this.success = 'File ' + result.filename + ' saved';
        this.notify();
      } else {
        this.error = 'There was an error while saving your file, please, try again later.'
      }
    })
  }

  async listSubFolders(container: string, prefix: string) {

    const foldersList = await this.blobService.folderSublist(container, prefix);
    const folders: any[] = [];
    for await (const item of foldersList) {
      if (item.kind === 'prefix') {
        folders.push({
          name: item.name.replace('/', '').trim(),
          properties: {...{container: container, folder: item.name}},
          children: await this.listSubFolders(container, item.name)
        });
      }
    }
    return folders;
  }

  async deleteFolder(node: any) {

    await this.blobService.deleteFolder(node.name.replace(new RegExp('/' + '$'),'').trim(), this.container);
  }

  private notify() {
    setTimeout(() => {
      this.success = '';
      this.error = '';
    }, 15000);
  }

  getUser(element: any) {
    if (element.kind === 'blob') {
      return element.metadata.modified_by;
    }
  }
}
