import {Injectable, OnDestroy} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {map, takeUntil} from 'rxjs/operators';
import {InvoicesRequest} from '../api/invoices.request.model';
import {DocRequest} from '../api/doc.request.model';
import {DocTypesRequest} from '../api/doc.types.request.model';
import {RestServiceAbstract} from '../../base/rest.service.abstract';
import {Subject} from 'rxjs';
import {OlInvoicesRequest} from '../api/ol.invoices.request.model';
import {ResponseModel} from '../../base/response.model';
import {FindPdfRequest} from '../api/findPdf.request.model';

@Injectable({
  providedIn: 'root',
})
export class InvoicesRestService extends RestServiceAbstract implements OnDestroy {

  public ngUnsubscribe$: Subject<void> = new Subject<void>();

  public requestOldDocuments(request: OlInvoicesRequest) {
    return this.post(`/${this.RESOURCE}/documents/oldDocuments`, request).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((response: Object) => {
        return new ResponseModel(response);
      })
    );
  }

  /**
   * Performs a request using the remote API.
   *
   * @returns {Observable<ResponseModel>}
   * @param request InvoicesRequest
   * @throws HttpErrorResponse
   */
  public searchDocuments(request: InvoicesRequest): Observable<ResponseModel> {
    // this.log.info('4. InvoicesService:searchDocuments:params ', request);
    return this.get(`/${this.RESOURCE}/documents/searchDocuments`, {params: request}).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  /**
   * Required by invoices list to request invoice download
   *
   * @returns {Observable<any>}
   * @param request
   */
  public getDocument(request: DocRequest) {
    this.get(`/${this.RESOURCE}/documents/pdfDocument`, {
      params: request,
      observe: 'response',
      responseType: 'blob'
    }).subscribe(
      (response: any) => {
        if (response && response.hasOwnProperty('body')) {
          const blob = new Blob([response.body], {type: 'application/pdf'});
          // TODO: show pdf in new tab
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          const filename = request.docType + '-' + request.docNr + '-' + request.docDate + '.pdf';
          let navigator = (window.navigator as any);
          if (navigator && navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(response, filename);
          } else {
            link.download = filename;
            link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
          }
          link.remove();
        }
      }
    );
  }

  /**
   * @returns {Observable<Blob>}
   * @data request
   */
  public findPdf(request: FindPdfRequest) {
    this.postAndGetBlob(`/${this.RESOURCE}/documents/find-pdf`, request)
    .subscribe(
      (response: any) => {
        if (response && response.type === 'application/pdf') {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(response);
          const date = request.startDate.toISOString().substr(0, 10);
          const filename = request.documentType + '-' + request.documentNumber + '-' + date + '.pdf';
          let navigator = (window.navigator as any);
          if (navigator && navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(response, filename);
          } else {
            link.download = filename;
            link.dispatchEvent(new MouseEvent(`click`, {
              bubbles: true,
              cancelable: true,
              view: window
            }));
          }
          link.remove();
        }
      }
    );
  }

  /**
   * Required by search form to request selectable document types
   *
   * @returns {Observable<any>}
   */
  public getDocumentTypes(request: DocTypesRequest) {
    return this.get(`/${this.RESOURCE}/documents/types`, {params: request}).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  /**
   * Required by search form to request selectable document types
   *
   * @returns {Observable<any>}
   */
  public getAllDocumentTypes() {
    return this.get(`/${this.RESOURCE}/documents/allDocTypes`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  public getLatestDocumentsForIdf(idf: string) {
    return this.get(`/${this.RESOURCE}/documents/latest`, {params: {idfs: idf}}).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      }));
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    //this.ngUnsubscribe$.next();
    // This completes the subject properlly.
    this.ngUnsubscribe$.complete();
  }
}
