import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {PreordersRestService} from "./services/preorders.rest.service";
import {FormBuilder, FormControl} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {UserService} from "../../core/user/services/user.service";
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from "@angular/material/dialog";
import {ConfirmationDialogComponent} from "../../shared/confirmation-dialog/confirmation-dialog.component";
import {ConfirmationDialogModel} from "../../core/deliveries/models/confirmation.dialog.model";
import {DialogComponent} from "../../shared/dialog/dialog.component";
import {DialogTextModel} from "../../core/cms/models/dialog.text.model";
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-preorders',
  templateUrl: './preorders.component.html',
  styleUrls: ['./preorders.component.scss']
})
export class PreordersComponent implements OnInit, OnDestroy {
  protected preorders: any;
  protected description: any;
  displayedColumns: string[] = ['pzn', 'unit', 'name', 'comment', 'aep','rap','min_qty', 'discount', 'qty',];
  forms: any[] = [];
  private selectedIdf: any = null;
  private ngUnsubscribe$ = new Subject<boolean>();
  private category: any;
  notification: any = 'success';
  showNotification: any = false;
  public currentDate = formatDate(Date.now(), 'yyyy-MM-ddTHH:mm:ss', 'de_DE');

  constructor(private preordersRestService: PreordersRestService,
              private formBuilder: FormBuilder,
              private userService: UserService,
              private route: ActivatedRoute,
              private dialog: MatDialog
  ) {
    this.userService.getMainIdfObservable()?.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((response: any) => {
      if (response.return_object != undefined) {
        // this.getPreorders()
        this.selectedIdf = response.return_object;
      }
    });
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('categoryUrl');
    if (id) {
      this.category = id;
    }
    this.getPreorders();
  }


  saveForm(period_id: string) {
    const data = this.forms.find(form => form.period === period_id).form.value;
    const keys = Object.keys(data);
    let preorders: any[] = [];

    keys.forEach((key: any) => {
      if (data[key] !== '') {
        const preorder = {pzn: key, quantity: data[key], doctorsCount: 0};
        preorders.push(preorder);
      }
    })

    this.preordersRestService.updatePreorders(period_id, this.selectedIdf, preorders).subscribe((result: any) => {
      const data = new DialogTextModel({});
      if(result.error_code === null) {
        data.type = 'notice';
        data.title= 'Erfolg!';
        data.content= 'Ihre Vorbestellung wurde erfolgreich gespeichert';
        data.icon = 'info_outline'
      } else{
        data.type = 'notice';
        data.title= 'Fehler!';
        data.content= 'Ihre Vorbestellung konnte nicht gespeichert werden.';
        data.icon = 'error_outline';
      }
      const notice = this.dialog.open(DialogComponent,{
        width: '400px',
        data: data
      })

      setTimeout(()=>{
        notice.close();
      }, 5000)
    })
  }

  notify() {
    this.showNotification = true;
    setTimeout(() => {
      this.showNotification = false;
    }, 10000);
  }

  getForm(period_id: string) {
    return this.forms.find(form => form.period === period_id)?.form;
  }

  getPreorders() {
    this.preordersRestService.getPreorders(this.category).subscribe((preorders: any) => {
      this.preorders = {};

      // @ts-ignore
      this.preorders['products'] = []
      this.description = preorders.return_object;
      this.preorders = preorders.return_object.periods;
      this.preorders.forEach((preorder: any) => {
        let form = this.formBuilder.group({});
        let values: {} = {};
        preorder.products.forEach((product: any) => {
          form.addControl(product.id.pzn, new FormControl(''));
          // @ts-ignore
          values[product.id.pzn] = 0;
        });

        this.forms.push({period: preorder.pre_order_period_id, form: form});
        this.getPersonalPreorders(this.selectedIdf);
      })
    });
  }

  getPersonalPreorders(idf: string) {
    this.selectedIdf = idf;
    if (this.forms.length > 0) {
      this.forms.forEach((formData: any) => {
        formData.form.reset();
        this.preordersRestService.getPeriodPreorders(formData.period, idf).subscribe((order: any) => {
          let values: {} = {};

          order.return_object.contingents.forEach((item: any) => {
            // @ts-ignore
            values[item.pzn] = item.quantity;
          });
          formData.form.patchValue(values);
        });
      })
    }
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(false);
    this.ngUnsubscribe$.complete();
  }

  checkQty(form: any, pzn: string, name: string) {
    const value = form.get(pzn).value;


    if(value >= 100) {
      const dialogData = new ConfirmationDialogModel('Bestellmenge Bestätigung', 'Bitte bestätigen Sie Ihre Bestellmenge'
        +`\r\n`
        + `${value}x ${pzn} ${name}`);

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        closeOnNavigation: true,
        data: dialogData
      }
    );

      dialogRef.afterClosed().subscribe((dialogResult: number) => {
        if (dialogResult != 1) {
          let values = {};

          // @ts-ignore
          values[pzn] = 99;
          form.patchValue(values);
        }
      });
    }
  }

  isExpired(shopEnd: any) {
    const end = formatDate(shopEnd, 'yyyy-MM-ddTHH:mm:ss', 'de_DE');
    return end < this.currentDate;
  }
}
