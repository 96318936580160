import {createReducer, on} from '@ngrx/store';
import {phx} from "../@types/models";
import Invoices = phx.portal.models.Invoices;
import * as InvoiceActions from "../actions/invoices.actions";

export const initialState: Invoices = {} as Invoices;

export const invoiceReducer = createReducer(
  initialState,
  on(InvoiceActions.LOAD_DOC_TYPES,(state, data: any)=>{
    return {
      ...state,
      ...data.payload,
      docTypesLoading: true
    }
  }),
  on(InvoiceActions.LOAD_DOC_TYPES_COMPLETE,(state, data: any)=>{
    return {
      ...state,
      ...data.payload,
      docTypesLoading: false
    }
  }),
  on(InvoiceActions.LOAD_DOC_TYPES_FAIL,(state, data: any)=>{
    return {
      ...state,
      ...data.payload,
      docTypesLoading: false
    }
  }),
  on(InvoiceActions.LOAD_ALL_DOC_TYPES,(state)=>{
    return {
      ...state,
      allDocTypesLoading: true
    }
  }),
  on(InvoiceActions.LOAD_ALL_DOC_TYPES_COMPLETE,(state, data: any)=>{
    return {
      ...state,
      ...data.payload,
      allDocTypesLoading: false
    }
  }),
  on(InvoiceActions.LOAD_ALL_DOC_TYPES_FAIL, (state,data: any)=>{
    return {
      ...state,
      ...data.payload,
      allDocTypesLoading: false
    }
  }),
  on(InvoiceActions.LOAD_SEARCH_INVOICES, (state, data: any)=>{
    return {
      ...state,
      ...data.payload,
      invoicesSearching: true
    }
  }),
  on(InvoiceActions.SEARCH_INVOICES_COMPLETE, (state, data: any)=>{
    return {
      ...state,
      ...data.payload,
      invoicesSearching: false
    }
  }),
  on(InvoiceActions.SEARCH_INVOICES_FAIL, (state, data: any)=>{
    return {
      ...state,
      ...data.payload,
      invoicesSearching: false
    }
  })
);

// export function reducer(state = initialState, action: InvoicesActions): State {
//   if (action.type === InvoicesActionTypes.SET_BRANCHES_FOR_INVOICE_SEARCH) {// TODO: we only get branch details if they have at least one doctype invoice allowed.
//     const allDocTypes = state.docTypes?.get(action.idf + '#' + action.payload.toString()) || null;
//     const branches: Branch[] = [];
//     if (allDocTypes !== null) {
//       const length = (allDocTypes.docTypes) ? allDocTypes.docTypes.length : 0;
//       for (let i = 0; i < length; i++) {
//         if ((allDocTypes.docTypes) && allDocTypes.docTypes[i]) {
//           const dcnr = (allDocTypes.docTypes[i].distributionCenters) ? allDocTypes.docTypes[i].distributionCenters.length : 0;
//           let docTypes = allDocTypes.docTypes;
//           for (let j = 0; j < dcnr; j++) {
//             let isBranchExist = false;
//
//             isBranchExist = branches.some(branch => {
//               return branch.id === docTypes[i].distributionCenters[j].id;
//             });
//
//             if (!isBranchExist && allDocTypes?.docTypes) {
//               branches.push({
//                 id: allDocTypes.docTypes[i].distributionCenters[j].id,
//                 location: allDocTypes.docTypes[i].distributionCenters[j].location,
//                 region: allDocTypes.docTypes[i].distributionCenters[j].region
//               });
//             }
//           }
//         }
//       }
//
//       if (branches.length > 1) {
//         branches.sort((a, b) =>    {
//           if(a.id && b.id){
//             return a.id - b.id
//           }
//           return 1;
//         });
//       }
//     }
//     return {
//       ...state,
//       selectedBranchesForInvoiceRequest: branches
//     };
//   } else if (action.type === InvoicesActionTypes.RESET_SEARCH_INVOICES_RESPONSE) {
//     const updatedInvoicesResponse = {...state.invoicesResponse};
//     updatedInvoicesResponse['results'] = {};
//     return <State>{
//       ...state,
//       invoicesResponse: updatedInvoicesResponse
//     };
//   } else if (action.type === InvoicesActionTypes.SHOW_INVOICE_CONTACT_FORM_VIEW) {
//     return {
//       ...state,
//       showInvoiceContactForm: true
//     };
//   } else if (action.type === InvoicesActionTypes.HIDE_INVOICE_CONTACT_FORM_VIEW) {
//     return {
//       ...state,
//       showInvoiceContactForm: false
//     };
//   } else {
//     return state;
//   }
// }

