import { Component, OnDestroy } from '@angular/core';
import {UserService} from "../../core/user/services/user.service";
import {Observable, Subject} from 'rxjs';
import {UserModel} from "../../core/user/user.model";
import {map, takeUntil} from 'rxjs/operators';
import {UserResponse} from "../../core/user/api/user.response.model";

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent implements OnDestroy{

  private user: UserModel | null = null;
  public loading = true;
  private ngUnsubscribe$ = new Subject<void>();

  constructor(private userService: UserService){
    this.userService.getUserResponseObservable().pipe(takeUntil(this.ngUnsubscribe$)).subscribe((userResponse: UserResponse) => {
      if(userResponse !== undefined){
        this.loading = false;
        this.user = userResponse.user;
      }
    });
  }

  isAdmin() {
    return this.user && (this.user.authoritiesEnum.indexOf('GROUP_MARKETING') > -1 ||
      this.user.authoritiesEnum.indexOf('GROUP_KSC') > -1 ||
      this.user.authoritiesEnum.indexOf('GROUP_SALES') > -1 ||
      this.user.authoritiesEnum.indexOf('GROUP_TECHNICAL') > -1);
  }

  ngOnDestroy(){
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
