import {CredentialsRequest} from "./credentials.request";

export class CredentialsTokenRequest extends CredentialsRequest{
  token: string;

  constructor(obj: any = {}) {
    super(obj);
    this.token = obj && obj.token || null;
  }
}
