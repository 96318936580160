import {Component, Input} from '@angular/core';
import {BlobService} from "../../core/base/blob.service";
import * as moment from 'moment';
import {NotificationsModel} from "../../core/notifications/notifications.model";
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent {
  public notices: any[] = [];
  @Input() noticeLocation: string | null = null;

  constructor(private blobService: BlobService,
              private http: HttpClient,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
    this.getNotifications();
  }

  async getNotifications() {
    let notifications = JSON.parse(<string>await this.blobService.getData('notifications.json', 'marketing-management'))
    const today = moment();

    notifications = notifications.filter((notif: any) => notif.active === true);

    notifications.forEach((notification: any) => {
      if (!notification.start && !notification.end) {
        this.notices.push(new NotificationsModel(notification));
      } else if (moment(notification.start) < today && !notification.end) {
        this.notices.push(new NotificationsModel(notification))
      } else if (moment(notification.end) > today && !notification.start) {
        this.notices.push(new NotificationsModel(notification))
      } else if (notification.end && notification.start) {
        if (moment(notification.end) > today && moment(notification.start) < today) {
          this.notices.push(new NotificationsModel(notification));
        }
      }
    });

    console.log(this.notices);
  }

  showNotice(filter: string) {
    let route;
    if (this.noticeLocation !== 'login') {
      route = this.router.url.replace(/^\/|\/$/g, '');
    } else {
      route = this.activatedRoute.snapshot.routeConfig?.path;
    }
    return !(filter !== null && filter !== route && !route?.includes(filter));
  }
}
