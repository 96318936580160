import {Inject, Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';
import {UserService} from "../../user/services/user.service";
import {LoginRequest} from "../api/login.request";
import {ResponseModel} from "../../base/response.model";

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {

  private ngUnsubscribe$ = new Subject<any>();
  private jwtHelper = new JwtHelperService();
  private adminGroups = ['GROUP_MARKETING', 'GROUP_KSC', 'GROUP_SALES', 'GROUP_TECHNICAL'
  ];

  constructor(private http: HttpClient,
              private userService: UserService,
              @Inject('AUTH_URL') private AUTH_URL: string,
              @Inject('USERMANAGEMENT') private USERMANAGEMENT: string
  ) {
  }

  // store the URL so we can redirect after logging in
  redirectUrl: string | null = null;

  /**
   * Logs the user in with the given credentials. Gets a JWT from remote
   * authentication provider.
   *
   * @returns {Observable<any>}
   */
  getUserResponseFromGateway(): Observable<any> {
    return this.http.get(`${this.AUTH_URL}/${this.USERMANAGEMENT}/user`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return new ResponseModel(data);
      })
    );
  }


  /**
   * Returns true if the user is authenticated.
   *
   * @returns {boolean}
   */
  public isAuthenticated(): boolean {
    const token = this.getTokenFromStorage();
    if (token == null) {
      return false;
    }
    try{
      if (this.jwtHelper.isTokenExpired(token)) {
        this.logout();
        return false;
      }
    } catch (e) {
      return false;
    }

    return true;
  }

  public isAdminUser(): boolean {
    let admin = false;
    this.getUserResponseFromGateway().pipe(
      takeUntil(this.ngUnsubscribe$)).subscribe((user: ResponseModel) => {
      if (this.adminGroups.indexOf(user.return_object?.user_group) > -1) {
        admin = true;
        // return true;
      }
    })

    return admin;
  }


  /**
   * Logs the user in with the given credentials. Gets a JWT from remote
   * authentication provider.
   *
   * @returns {any}
   */
  login(lr: LoginRequest) {
    if (this.jwtHelper.isTokenExpired(this.getTokenFromStorage())) {
      this.logout();
    }
    const url = `${this.AUTH_URL}/${this.USERMANAGEMENT}/auth/login`;

    return this.http.post(url, lr, {observe: 'response'}).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        this.setToken(data.headers.get('Authorization'));
        this.userService.loadUser();
        return data;
      })
    );
  }

  public logout() {
    this.userService.logout();
    localStorage.removeItem('token');
  }

  private setToken(token: string) {
    localStorage.setItem('token', token);
  }

  private getTokenFromStorage() {
    return localStorage.getItem('token');
  }

  public getToken(): string {
    return this.getTokenFromStorage() || 'none';
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
