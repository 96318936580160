import { Injectable } from '@angular/core';
import {RestServiceAbstract} from '../../base/rest.service.abstract';
import {map} from 'rxjs/operators';
import {ResponseModel} from '../../base/response.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RevenueRestService extends RestServiceAbstract {

  public requestRevenue(idf: string): Observable<ResponseModel> {
    return this.get(`/${this.USERMANAGEMENT}/revenue/${idf}`).pipe(
      map((response: Object) => {
        return new ResponseModel(response);
      })
    );
  }
}
