import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {PagesRestService} from '../../core/cms/services/pages.rest.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MatomoTracker} from 'ngx-matomo-client';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss']
})

export class CmsComponent implements OnInit, OnDestroy {
  public loading: boolean = true;
  public body: any = null;
  public footer: any = null;
  public page: any = null;
  public link: string = '';
  private readonly matomoTracker = inject(MatomoTracker);
  public ngUnsubscribe$ = new Subject<any>();

  constructor(private pageService: PagesRestService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(params => {
      this.pageService.getPage(params['url']).subscribe(page => {
        this.page = page.return_object;
        if (this.page) {
          this.body = this.page.content;
          this.matomoTracker.trackPageView(this.page.page_title);
          this.loading = false;
          this.link = params['url'];
        }
      });
    })
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
  }
}
