<h1 class="title">Umsatz <span class="text-small"> (ohne MwSt.)</span></h1>
<app-idf-select (selectedIdf)="requestRevenue($event)"></app-idf-select>
<app-notifications></app-notifications>
<mat-card class="mb-m">
  <mat-card-content>
    <span class="title">{{ revenue?.month}} {{ today |date: 'YYYY' }} </span>
    <span class="text-small"> (Stand: {{ revenue?.updated_at | date: 'dd.MM.YYYY HH:mm'}})</span>
    <div class="d-flex my-m jcsb">
      <div class="totals d-flex flex-wrap">
        <div class="mr-l">
          <p>Umsatz total:</p>
          <p class="text-bold subheader">
            {{ (revenue?.total_revenue ? revenue?.total_revenue : 0) | currency:'EUR':'symbol':'1.2-2'}}
          </p>
        </div>
        <div class="mr-l">
          <p>RX-Umsatz inkl. HP:</p>
          <p class="text-bold subheader">
            {{ (revenue?.rx_umsatz ? revenue?.rx_umsatz : 0) | currency:'EUR':'symbol':'1.2-2'}}
          </p>
        </div>
        <div class="mr-l">
          <p>HP-Umsatz:</p>
          <p class="text-bold subheader">
            {{ (revenue?.hp_umsatz ? revenue?.hp_umsatz : 0) | currency:'EUR':'symbol':'1.2-2'}}
          </p>
        </div>
        <div class="mr-l">
          <p>Non-RX-Umsatz:</p>
          <p class="text-bold subheader">
            {{ (revenue?.non_rx_umsatz ? revenue?.non_rx_umsatz : 0) | currency:'EUR':'symbol':'1.2-2'}}
          </p>
          <p class="text-small">FW Umsatz: {{ (revenue?.fw_umsatz ? revenue?.fw_umsatz
            : 0) | currency:'EUR':'symbol':'1.2-2'}}</p>
          <p class="text-small">OTC Umsatz: {{ (revenue?.otc_umsatz ? revenue?.otc_umsatz
            : 0) | currency:'EUR':'symbol':'1.2-2'}}</p>
        </div>
<!--            <div class="mr-l">-->
<!--              <p>Ist Spanne:</p>-->
<!--              <p class="text-bold subheader">-->
<!--                {{ (revenue.rx_span_percent ? revenue.rx_span_percent : 0) | number }} %-->
<!--              </p>-->
<!--              &lt;!&ndash;    <p class="text-small">Stand: {{ revenue.updated_at | date: 'dd.MM.YYYY HH:mm'}}</p>&ndash;&gt;-->
<!--            </div>-->
<!--            <div class="mr-l">-->
<!--              <p>RX Packungswert:</p>-->
<!--              <p class="text-bold subheader">-->
<!--                {{ (revenue.rx_span_percent ? ((0.73/1.0315)/((revenue.rx_span_percent/100)-1+(1/1.0315))) : 0) | number:'1.1-1' }}0 €-->
<!--              </p>-->
<!--              &lt;!&ndash;    <p class="text-small">Stand: {{ revenue.updated_at | date: 'dd.MM.YYYY HH:mm'}}</p>&ndash;&gt;-->
<!--            </div>-->
      </div>
      <div>
        <p class="text-center">Download:</p>
        <p>
          <button mat-flat-button color="primary" (click)="downloadCsv()"><span>.csv</span></button>
          <button mat-flat-button color="primary" (click)="downloadPdf()" class="ml-s"><span>.pdf</span></button>
        </p>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<mat-card>
  <mat-card-content echarts [options]="chartOptions" class="chart">

  </mat-card-content>

</mat-card>
