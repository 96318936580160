import {Component, Inject} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BlobService} from "../../../core/base/blob.service";


@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss'
})
export class FileUploadComponent {
  fileForm = this.formBuilder.group({
    filename: ['']
  })
  files: any[] = [];

  constructor(private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<FileUploadComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private blobService: BlobService) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onFileDropped(event: any) {
    this.files = event;
  }

  onFileUploaded(event: any) {
    const files = event.target.files;
    this.files = files;
  }

  uploadFile() {

    let filename = this.files[0].name;

    const formVal = this.fileForm.value;

    if(formVal && formVal.filename) {
      const type = filename.split('.');
      filename = formVal.filename + '.' + type[type.length -1];
    }

    const ret = this.blobService.uploadFile(this.files[0], (this.data.folder ? this.data.folder + filename : filename).trim(), this.data.container, {modified_by: this.getUserDetails(this.data.user)});
    ret.then(r => {
      this.dialogRef.close({error: r.error_code, filename: filename});
    });
  }

  private getUserDetails(user: any) {
    return user.name + ' (' + user.username + ')'
  }
}
