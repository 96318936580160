import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogTextModel} from "../../../core/cms/models/dialog.text.model";
import {DialogRestService} from "../../../core/cms/services/dialog.rest.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss']
})
export class LegalComponent implements OnDestroy {
  protected ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogTextModel,
              private dialogRestService: DialogRestService,
              public dialogRef: MatDialogRef<LegalComponent>) {
  }

  onAccept() {
    this.dialogRestService.subscribeToDialogOffer(this.data.id).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
    });
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe$.next();
    // This completes the subject properly.
    this.ngUnsubscribe$.complete();
  }
}
