import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {PasswordRestService} from "../../core/auth/services/password.rest.service";

@Component({
  selector: 'password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit, OnDestroy {

  public token: string = '';
  public response: any = null;
  public loading: boolean = false;
  private ngUnsubscribe$ = new Subject<void>(); // emit unsubscription
  @Output() result = new EventEmitter();
  @Input() tkn: string = '';

  constructor(private route: ActivatedRoute,
              @Inject(PasswordRestService) public passwordRestService: PasswordRestService,
              public router: Router) {
  }

  ngOnInit() {
    this.loading = true;
    this.route.params.pipe(takeUntil(this.ngUnsubscribe$))
    .subscribe((params: any) => {
      if (params.hasOwnProperty('token') || this.tkn !== '') {
        this.token = params['token'] ? params['token'] : this.tkn;

        console.log(this.token);
        this.passwordRestService.confirm(this.token).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(
          () => {
            this.loading = false;
          },
          err => {
            if (err.error && err.error.errorCode) {
              this.router.navigate(['kontakt'], {queryParams: {error: JSON.stringify(err)}});
            }
          },
          () => {
            // Do stuff after completion
          }
        );
      }
    });

  }


  credentialsSet(e: any) {
    this.response = e;
    this.router.navigateByUrl('login/passwort-erfolgreich-gesetzt');
  }

  setLoading(e:boolean) {
    this.loading = e;
  }

  /**
   * unsubcribe all subscriptions on destroy
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
