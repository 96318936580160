import {Injectable} from '@angular/core';
import {BlobService} from "../../base/blob.service";
import {NotificationsModel} from "../notifications.model";
import {ResponseModel} from "../../base/response.model";

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private container: string = 'marketing-management';
  private notifications: NotificationsModel[] = [];

  constructor(private blobService: BlobService) {

  }

  async downloadList(edit?: boolean) {
    this.notifications = JSON.parse(<string>await this.blobService.getData('notifications.json', this.container, edit));
    return this.notifications;
  }

  async addNotification(value: NotificationsModel) {
    value.index = this.notifications.length;
    this.notifications.push(value);

    return this.blobService.setData('notifications.json', JSON.stringify(this.notifications), this.container).then(response=> {
      return new ResponseModel({error_code: response.error_code, response_body: value});
    });
  }

  updateNotification(value: NotificationsModel) {
    let selected = this.notifications.find(notice => {
     return notice.index === value.index;
    });
    if(selected) {
      this.notifications[this.notifications.indexOf(selected)] = value;
    }

    return this.blobService.setData('notifications.json', JSON.stringify(this.notifications), this.container).then(response=> {
      return new ResponseModel({error_code: response.error_code, response_body: value});
    });
  }

  async deleteNotification(notification: NotificationsModel) {
    const ind = this.notifications.indexOf(notification);
    this.notifications.splice(ind,1);

    return this.blobService.setData('notifications.json', JSON.stringify(this.notifications), this.container).then(response=> {
      return new ResponseModel({error_code: response.error_code, response_body: notification});
    });
  }
}
