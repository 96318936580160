export class FormsModel {
  id: number;
  name: string;
  fields_list: FormField[];
  recipients: string;
  redirect_to: string;
  submit_label: string;
  success_text: string;
  created_at: string;
  modified_at: string;


  constructor(obj: any) {
    this.id = obj && obj.id || null;
    this.name = obj && obj.name || null;
    this.fields_list = obj && obj.fields_list?.map((field: any) => new FormField(field)) || [];
    this.recipients = obj && obj.recipients || null;
    this.redirect_to = obj && obj.redirect_to || null;
    this.submit_label = obj && obj.submit_label || 'Sende';
    this.success_text = obj && obj.success_text || null;
    this.created_at = obj && obj.created_at || null;
    this.modified_at = obj && obj.modified_at || null;
  }
}

export class FormField {
  id: number;
  label: string;
  options: any[];
  required: boolean;
  type: string;
  created_at: string;
  modified_at: string;
  sort: number;
  col: number;

  constructor(obj: any) {
    this.id = obj && obj.id || null;
    this.label = obj && obj.label || null;
    this.options = obj && JSON.parse(obj.options) || [];
    this.required = obj && obj.required || null;
    this.type = obj && obj.type || null;
    this.created_at = obj && obj.created_at || null;
    this.modified_at = obj && obj.modified_at || null;
    this.sort = obj && obj.sort || 0;
    this.col = obj && obj.col || 12;
  }
}
