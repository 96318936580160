export class RegistrationRequest {
  private agb: boolean;
  private data_protection: boolean;
  private idf: string;
  private zip_code: string;

  constructor(obj: any = {}) {
    this.agb = obj && obj.agb || false;
    this.data_protection = obj && obj.dataProtection || false;
    this.idf = obj && obj.idf || null;
    this.zip_code = obj && obj.zipCode || false;
  }
}
