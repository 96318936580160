import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminComponent} from "./admin.component";
import {authGuard} from "../core/auth/auth.guard";
import {AdminDashboardComponent} from "./admin-dashboard/admin-dashboard.component";
import {NotificationsManagementComponent} from "./notifications-management/notifications-management.component";
import {UserManagementComponent} from "./user-management/user-management.component";
import {UserFormComponent} from "./user-management/user-form/user-form.component";
import {InternalUsersComponent} from "./internal-users/internal-users.component";
import {FilesManagementComponent} from "./cms-management/files-management/files-management.component";
import {PagesManagementComponent} from "./cms-management/pages-management/pages-management.component";

const routes: Routes = [
  {
    path: '', component: AdminComponent, canActivate: [authGuard],
    children: [
      {
        path: '', children: [{
          path: '', children: [
            {path: '', redirectTo: 'admin-dashboard', pathMatch: 'full'},
            {path: 'admin-dashboard', component: AdminDashboardComponent, canActivate: [authGuard]},
            {path: 'notifications-management', component: NotificationsManagementComponent, canActivate: [authGuard]},
            {path: 'user-management', component: UserManagementComponent, canActivate: [authGuard],
              runGuardsAndResolvers: 'always'},
            {path: 'internal-management', component: InternalUsersComponent, canActivate: [authGuard],
              runGuardsAndResolvers: 'always'},
            {path: 'cms-management', loadChildren: () => import('./cms-management/cms-management.module').then(m => m.CmsManagementModule), canMatch: [authGuard],
              runGuardsAndResolvers: 'always'},
            {path: 'files-management', component: FilesManagementComponent, canActivate: [authGuard],
              runGuardsAndResolvers: 'always'},
            {path: 'pages-management', component: PagesManagementComponent, canActivate: [authGuard],
              runGuardsAndResolvers: 'always'}
          ]
        }
        ]
      }
    ],
    data: {
      type: 'admin'
    }
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule {
}
