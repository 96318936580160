import {TableElement} from './interfaces/table.element.interface';
import {Delivery} from "./models/delivery.availability.model";
import * as moment from 'moment';


export class SubsequentDeliveryModel {
  branchnumber: number;
  idf: string;
  ordernumber: string;
  orderdate: string;
  posnumber: number;
  postype: number;
  articleno: string;
  articlecode: string;
  articlename: string;
  unit: string;
  quantity: number;
  id: string;
  delivery_information: DeliveryInformation

  constructor(obj: any = {}) {
    this.branchnumber = obj.branchnumber;
    this.idf = (obj.customernumber) ? obj.customernumber : obj.idf;
    this.ordernumber = obj.ordernumber;
    this.orderdate = obj.orderdate;
    this.posnumber = obj.posnumber;
    this.postype = obj.postype;
    this.articleno = obj.articleno;
    this.articlecode = obj.articlecode;
    this.articlename = obj.articlename;
    this.unit = obj.unit;
    this.quantity = obj.quantity;
    this.delivery_information = new DeliveryInformation(obj.delivery_information);
    this.id = btoa(parseInt(this.articleno) + this.delivery_information.delivery_from + this.orderdate);
  }

  toTableData(data: any) {
    const tableData: TableElement[] = [];
    for (let line of data) {
      const delivery = new SubsequentDeliveryModel(line);
      const pos = parseInt(delivery.posnumber + delivery.ordernumber);
      if (delivery.quantity > 0) {
        tableData.push({
          position: pos,
          orderdate: delivery.orderdate,
          articlecode: delivery.articlecode,
          articlename: delivery.articlename,
          unit: delivery.unit,
          quantity: delivery.quantity,
          delivery: delivery,
          delivery_information: delivery.delivery_information
        });
      }
    }
    return tableData;
  }

  getPosition(): any {
    return this.posnumber + this.ordernumber;
  }
}

export class DeliveryInformation {
  deliverable:boolean;
  delivery_from:string;
  display_text:string;
  type:string;

  constructor(obj: any = {}) {
    this.deliverable = obj && obj.deliverable || false;
    this.delivery_from = obj && obj.delivery_from || '';
    this.display_text = obj && obj.display_text || '';
    this.type = obj && obj.type || '';
  }

  getDeliveryDate() {
    // let deliveryStatus = this.deliverable ?
    //   ((this.delivery_from && !(parseInt(moment(this.delivery_from).format('YYYY')) > 2999)) ? null : 'NO_INFO') :
    //   (this.type === 'NORMAL' || this.type === 'VERBUND' ? 'NORMAL_ORDER' : 'NOT_DELIVERABLE');

    if (this.delivery_from && !(parseInt(moment(this.delivery_from).format('YYYY')) >= 2999)) {
      const date = moment(this.delivery_from);
      return 'KW' + date.format('ww / yyyy');
    }

    return '-';
  }
}
