import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent {

  constructor(public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public router: Router) {
  }

  closeDialog($event: any) {
    this.router.navigateByUrl('login/passwort-erfolgreich-gesetzt');
    this.dialogRef.close();
  }
}
