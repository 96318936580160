<mat-dialog-content>
  <span class="subheader">Artikel zur Nachlieferung aufnehmen</span>
</mat-dialog-content>
<mat-dialog-content *ngIf="!success; else successContent">
  <form [formGroup]="productForm">
    <mat-form-field appearance="outline" class="mt-s">
      <mat-label>Artikelname oder PZN</mat-label>
      <input matInput formControlName="search" [matAutocomplete]="auto" (input)="searchProducts($event)"/>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setProduct($event.option.value)">
        <mat-option *ngFor="let product of products" [value]="product.pzn+' '+product.name">
          {{ product.pzn }} {{ product.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field appearance="outline" class="mt-s">
      <mat-label>Menge</mat-label>
      <input matInput formControlName="qty" (change)="checkDetails()"/>
    </mat-form-field>
    <div>
      <p>Information lt. Hersteller</p>
      <div *ngIf="!loadingStatus; else loader">
        <p *ngIf="deliveryStatus">{{ deliveryStatus | translate }}</p>
        <p *ngIf="deliveryDate">KW {{ getDate(deliveryDate) | date: 'w / Y' }}</p>
      </div>
      <ng-template #loader>
        <mat-spinner [diameter]="20"></mat-spinner>
      </ng-template>
    </div>
  </form>
  <div *ngIf="error">Es ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal.</div>
</mat-dialog-content>
<ng-template #successContent>
  <mat-dialog-content>
    <p>Artikel zur Nachlieferung aufgenommen.</p>
  </mat-dialog-content>
</ng-template>
<mat-dialog-content>
  <button mat-flat-button (click)="onNoClick()">Abbrechen</button>
  <button mat-flat-button color="primary" *ngIf="!success" (click)="addNachlieferung($event)" [disabled]="disabled">
    Aufnehmen
  </button>
</mat-dialog-content>
