import {map, takeUntil} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {RestServiceAbstract} from '../../base/rest.service.abstract';
import {Observable, Subject} from 'rxjs';
import {OrderRequest} from '../../sales/api/order.request.model';
import {ProductRequest} from '../api/product.request.model';
import {ResponseModel} from '../../base/response.model';
import {ProductType} from '../models/product.type.model';
import {ProductFile} from '../models/product.file.model';
import {HttpResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ProductRestService extends RestServiceAbstract implements OnDestroy {

  public ngUnsubscribe$: Subject<void> = new Subject<void>();
  public download = new Subject<String>();

  private filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;


  public searchProducts(productRequest: ProductRequest) {
    return this.get(`/${this.RESOURCE}/product/search`, {params: productRequest}).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel({...data, ...{type: ProductType.DokuLight}});
      })
    );
  }

  public loadParagraph73(productRequest: ProductRequest) {
    return this.get(`/${this.RESOURCE}/product/offers/paragraph73`, {params: productRequest}).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel({...data, ...{type: ProductType.Paragraph73, canCallAvailability: false}});
      })
    );
  }

  public loadPayback(productRequest: ProductRequest) {
    return this.get(`/${this.RESOURCE}//product/offers/payback`, {params: productRequest}).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel({...data, ...{type: ProductType.Payback, canCallAvailability: false}});
      })
    );
  }

  public loadPromo() {
    return this.get('/resource/product/offers/promo').pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel({...data, ...{type: ProductType.Promo, canCallAvailability: false}})
      })
    )
  }

  public loadUberVendors() {
    return this.get('/resource/product/offers/uber').pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel({...data, ...{type: ProductType.Uber, canCallAvailability: false}});
      })
    );
  }

  public loadUberByVendorId(vendorId: number) {
    return this.get(`/${this.RESOURCE}/product/offers/uber/${vendorId}`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel({...data, ...{type: ProductType.Uber, canCallAvailability: false}});
      })
    );
  }

  public getShippingCostParagraph73() {
    return this.get(`/${this.RESOURCE}/product/shippingcostparagraph73`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  /**
   * @param pzns
   * @return {Observable<ResponseModel>}
   */
  public loadDetailedAvailability(pzns: string[]): Observable<ResponseModel> {
    const params = {artikelMengen: pzns};
    return this.get(`/${this.RESOURCE}/order/availability`, {params: params}).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }


  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    //this.ngUnsubscribe$.next();
    // This completes the subject properlly.
    this.ngUnsubscribe$.complete();
  }
}
