import {Component, Inject, Input, OnInit, OnDestroy} from '@angular/core';
import {RegistrationRestService} from "../../core/auth/services/registration.rest.service";
import {LoginRequest} from "../../core/auth/api/login.request";
import {AuthService} from "../../core/auth/services/auth.service";
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrl: './activate.component.scss'
})
export class ActivateComponent implements OnInit, OnDestroy {

  public token: string | undefined;
  public response: any;
  public loading: boolean = true;
  public ngUnsubscribe$ = new Subject<void>(); // emit unsubscription

  constructor(public route: ActivatedRoute,
              @Inject(RegistrationRestService) public registrationRestService: RegistrationRestService,
              @Inject(AuthService) public auth: AuthService,
              public router: Router) {
  }

  ngOnInit() {
    this.loading = true;
    this.route.params.pipe(takeUntil(this.ngUnsubscribe$))
    .subscribe((params: any) => {
      if (params.hasOwnProperty('token')) {
        this.token = params.token;

        this.registrationRestService.activate(params.token).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(
          (response: any) => {
            this.loading = false;
          },
          (err: any) => {
            if (err.error && err.error.errorCode) {
              this.router.navigate(['kontakt'], {queryParams: {error: JSON.stringify(err)}});
            }
          },
          () => {
            // Do stuff after completion
          }
        );
      } else {
        this.loading = false;
      }
    });
  }

  credentialsSet(e: any) {
    this.setLoading(true);
    this.auth.login(new LoginRequest({
      username: e.response.username,
      password: e.password
    })).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.router.navigateByUrl('dashboard');
    });
  }

  setLoading(e: boolean) {
    if (e)
      this.loading = e;
  }

  /**
   * unsubcribe all subscriptions on destroy
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
