import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {PublicComponent} from "./public.component";
import {RegisterComponent} from "./register/register.component";
import {ContactComponent} from "../shared/contact/contact.component";
import {ForgottenPasswordComponent} from "./forgotten-password/forgotten-password.component";
import {PasswordResetComponent} from "./password-reset/password-reset.component";
import {ActivateComponent} from "./activate/activate.component";
import {MatomoRouteData} from "ngx-matomo-client";

const routes: Routes = [
  {
    path: '',
    component: PublicComponent,
    data: {
      type: 'public'
    },
    children: [
      {
        path: '',
        component: LoginComponent,
        data: {
          matomo: {
            title: 'Anmeldung'
          } as MatomoRouteData
        }
      },
      {
        path: 'login/:action',
        component: LoginComponent,
        data: {
          matomo: {
            title: 'Anmeldung'
          } as MatomoRouteData
        }
      },
      {
        path: 'register',
        component: RegisterComponent,
        runGuardsAndResolvers: 'always',
        data: {
          matomo: {
            title: 'Registration'
          } as MatomoRouteData
        }
      },
      {
        path: 'register/:token',
        component: RegisterComponent,
        runGuardsAndResolvers: 'always',
        data: {
          matomo: {
            title: 'Registration'
          } as MatomoRouteData
        }
      },
      {
        path: 'aktivierung/:token',
        component: ActivateComponent,
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'kontakt',
        component: ContactComponent,
        runGuardsAndResolvers: 'always',
        data: {
          matomo: {
            title: 'Kontakt'
          } as MatomoRouteData
        }
      },
      {
        path: 'forgotten-password',
        component: ForgottenPasswordComponent,
        runGuardsAndResolvers: 'always',
        data: {
          matomo: {
            title: 'Passwort vergessen'
          } as MatomoRouteData
        }
      },
      {
        path: 'neues-passwort-setzen/:token',
        component: PasswordResetComponent,
        runGuardsAndResolvers: 'always',
        data: {
          matomo: {
            title: 'Neues Passwort setzen'
          } as MatomoRouteData
        }
      },
      // {
      //   path: 'aktivierung',
      //   loadChildren: () => import('./../modules/authentication/account-activation/account-activation.module').then(m => m.AccountActivationModule)
      // },
      {
        path: 'open/page/:url',
        loadChildren: () => import('../shared/cms/cms.module').then(m => m.CmsModule),
        runGuardsAndResolvers: 'always'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicRoutingModule {
}
