import {createAction, props} from '@ngrx/store';

export const LOAD_USER = createAction('[USER] Load User');
export const LOAD_USER_COMPLETE = createAction('[USER] Load User Complete');
export const LOAD_USER_FAIL = createAction('[USER] Load User FAIL');
export const LOAD_NAV = createAction('[USER] Load Nav');
export const LOAD_NAV_COMPLETE = createAction('[USER] Load Nav Complete');
export const LOAD_NAV_FAIL = createAction('[USER] Load Nav FAIL');
export const LOAD_USER_IDFS = createAction('[USER] Load User IDFS', props<{ payload: string }>());
export const LOAD_USER_IDFS_COMPLETE = createAction('[USER] Load User IDFS complete');
export const LOAD_USER_IDFS_FAIL = createAction('[USER] Load User IDFS FAIL');
export const LOAD_USER_MAIN_IDF = createAction('[USER] Load User Main IDF');
export const LOAD_USER_MAIN_IDF_COMPLETE = createAction('[USER] Load User Main IDF complete');
export const LOAD_USER_MAIN_IDF_FAIL = createAction('[USER] Load User Main IDF FAIL');
export const LOAD_USER_VZS = createAction('[USER] Load User VZS');
export const LOAD_USER_VZS_COMPLETE = createAction('[USER] Load User VZS complete');
export const LOAD_USER_VZS_FAIL = createAction('[USER] Load User VZS FAIL');
export const LOAD_USER_SA = createAction('[USER] Load User Shipping Addresses');
export const LOAD_USER_SA_COMPLETE = createAction('[USER] Load User Shipping Addresses complete');
export const LOAD_USER_SA_FAIL = createAction('[USER] Load User Shipping Addresses FAIL');
export const LOAD_USER_DISTRIBUTION_CENTER_ADDRESS = createAction('[USER] Load User Distribution Center Address');
export const LOAD_USER_DISTRIBUTION_CENTER_ADDRESS_COMPLETE = createAction('[USER] Load User Distribution Center Address complete');
export const LOAD_USER_DISTRIBUTION_CENTER_ADDRESS_FAIL = createAction('[USER] Load User Distribution Center Address FAIL');
export const LOAD_ALL_USER_DISTRIBUTION_CENTER_ADDRESS = createAction('[USER] Load all User Distribution Center Address');
export const LOAD_ALL_USER_DISTRIBUTION_CENTER_ADDRESS_COMPLETE = createAction('[USER] Load all User Distribution Center Address complete');
export const LOAD_ALL_USER_DISTRIBUTION_CENTER_ADDRESS_FAIL = createAction('[USER] Load all User Distribution Center Address FAIL');
export const LOAD_AUTH_TOKEN = createAction('[USER] Load User auth token');
export const LOAD_AUTH_TOKEN_COMPLETE = createAction('[USER] Load User auth token complete');
export const LOAD_AUTH_TOKEN_FAIL = createAction('[USER] Load User auth token FAIL');
export const LOAD_AUTH_TOKENS = createAction('[USER] Load User auth tokens');
export const LOAD_AUTH_TOKENS_COMPLETE = createAction('[USER] Load User auth tokens complete');
export const LOAD_AUTH_TOKENS_FAIL = createAction('[USER] Load User auth tokens FAIL');
export const LOGOUT = createAction('[USER] Logout');
