import { Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, Validators} from "@angular/forms";
import {NotificationsType} from "../../../core/notifications/enums/notifications.type.enum";
import {NotificationsService} from "../../../core/notifications/services/notifications.service";
import {NotificationsModel} from "../../../core/notifications/notifications.model";
import {Router} from '@angular/router';

@Component({
  selector: 'app-notifications-form',
  templateUrl: './notifications-form.component.html',
  styleUrls: ['./notifications-form.component.scss']
})
export class NotificationsFormComponent implements OnInit
{
  public notifiationForm = this.fb.group({
   title: ['', Validators.required],
   active: [false],
   type: ['', Validators.required],
   start: [''],
   end: [''],
   filter: [''],
   text: ['', Validators.required]
  });
  notificationType = NotificationsType;
  paths : any[] = [{path:'', name:'Login'}];

  constructor(@Inject(MAT_DIALOG_DATA) public data: NotificationsModel,
              private fb: FormBuilder,
              private notificationsService: NotificationsService,
              public dialogRef: MatDialogRef<NotificationsFormComponent>,
              private router: Router) {
  }

  ngOnInit() {
    this.router.config.filter((path: any) => {
      if(path.data?.type ==='private') {
        path.children[0].children.forEach((child :any) => {
          if(child.path != 'subusers' && child.path.indexOf('page')<0 && child.data && child.path!==''){
            console.log(child);
            this.paths.push({path: child.path, name: child.data.name})
          }
        })
    }});

    if(this.data){
      this.notifiationForm.patchValue(this.data);
    }
  }

  submit() {
    if(this.data){
      const notice = new NotificationsModel(this.notifiationForm.value);
      notice.index = this.data.index;
      this.notificationsService.updateNotification(notice).then(status => {
        this.dialogRef.close(true);
      });
    } else {
      this.notificationsService.addNotification(new NotificationsModel(this.notifiationForm.value)).then(status => {
          this.dialogRef.close(true);
      });
    }
  }
}
