import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class CsvService implements OnDestroy {
  CSV_TYPE = 'csv';
  CSV_EXTENSION = '.csv';
  protected ngUnsubscribe$: Subject<void> = new Subject<void>();


  /**
   * Export data to csv file and download to user machine
   * @param columns
   * @param rows
   * @param filename
   */
  exportToCsv(columns: string[], rows: object[], filename: string) {

    if (!rows || !rows.length) {
      return;
    }

    const separator = ';';
    const date = new Date();

    const keys = Object.keys(rows[0]).filter(k => {
      if (columns.length) {
        return columns.includes(k);
      } else {
        return true;
      }
    });

    const content = keys.join(separator) + '\n'
      + rows.map((row: any) => {
        return keys.map(k => {
          const cell = row[k] === null || row[k] === undefined ? '' : row[k];
          return cell;
        }).join(separator);
      }).join('\n');

    this.saveAsFile(content, `${filename}${this.CSV_EXTENSION}`, this.CSV_TYPE);
  }

  /**
   * Saves the file on the client's machine via FileSaver library.
   *
   * @param buffer The data that need to be saved. Concatenated UTF16-LE BOM '\ufeff' for special character decoding
   * @param fileName File name to save as.
   * @param fileType File type to save as.
   */
  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob(['\ufeff' + buffer], {type: fileType});
    FileSaver.saveAs(data, fileName);
  }

  /**
   * destroy
   */
  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe$.next();
    // This completes the subject properlly.
    this.ngUnsubscribe$.complete();
  }

}
