import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {FormsRestService} from '../../../core/cms/services/forms.rest.service';
import {Router} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';
import {FormField, FormsModel} from '../../../core/cms/forms.model';
import {Answer, FormAnswers} from '../../../core/cms/form.answers.model';
// import {MatomoService} from '../../../../core/services/matomo.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-forms',
    templateUrl: './forms.component.html',
    styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {

    @Input() form: number = 0;
    @Input() public: boolean = false;
    public loading = true;
    public dynamicForm: any;
    public fieldsList: FormsModel = new FormsModel({});
    public successText = false;
    public errorText = false;
    private submitted = false;
    public ngUnsubscribe$ = new Subject<any>();


    constructor(private formsRestService: FormsRestService,
                private fb: FormBuilder,
                private router: Router) {
    }

    ngOnInit(): void {
        this.formsRestService.getForm(this.form).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(f => {
            this.fieldsList = new FormsModel(f.return_object);
            this.dynamicForm = this.fb.group({});
            this.addFields(this.fieldsList.fields_list);
            this.loading = false;
        });
    }

    submit() {
        this.submitted = true;
        if (!this.dynamicForm.valid) {
            return;
        }

        const formValue = this.dynamicForm.value;
        let answrs = [];
        let fields = this.fieldsList.fields_list.sort((a: FormField, b:FormField) => (a.sort - b.sort));
        for (const field of fields) {
            const quest = field.id;
            const answer = formValue['field' + field.id];
            answrs.push(new Answer(quest, answer));
        }

        const request = new FormAnswers({form: this.fieldsList.id, answers: answrs});
        this.formsRestService.submitForm(request, this.public).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(res => {

            if (res.return_object === true) {
                // this.matomoService.trackEvent('Klick auf', this.fieldsList.submit_label, this.fieldsList.name);

                if (this.fieldsList.redirect_to !== null) {
                    this.router.navigateByUrl(this.fieldsList.redirect_to);
                }
                if (this.fieldsList.success_text !== null) {
                    this.successText = true;
                }
            } else {
                this.errorText = true;
            }
        });
    }

    addFields(fields: any) {
        let formFields = [];
        fields.sort((a: any, b: any) => (a.sort - b.sort));
        for (const field of fields) {
            let control;
            if (field.required) {
                control = this.fb.control('', Validators.required);
            } else {
                control = this.fb.control('');
            }
            this.dynamicForm.addControl('field' + field.id, control);
        }
    }

    getFormName(name: string) {
        return name.replace(' ', '-').toLowerCase();
    }

    getFormError(fieldName: string) {
        return this.submitted && this.dynamicForm.hasError('required', fieldName);
    }


    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next(true);
        this.ngUnsubscribe$.complete();
    }
}
