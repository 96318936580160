import {RestServiceAbstract} from '../../base/rest.service.abstract';
import {ResponseModel} from '../../base/response.model';
import {Injectable} from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FormsRestService extends RestServiceAbstract {

  getForm(form: any) {
    return this.get(`/${this.CMS_URL}/forms/` + form).pipe(map(data => {
      return new ResponseModel(data);
    }));
  }

  submitForm(request: any, isPublic: boolean) {
    if (isPublic) {
      return this.post(`/${this.CMS_URL}/forms/open`, request).pipe(map(data => {
        return new ResponseModel(data);
      }));
    } else {
      return this.post(`/${this.CMS_URL}/forms/`, request).pipe(map(data => {
        return new ResponseModel(data);
      }));
    }
  }
}
