import {RestServiceAbstract} from '../../base/rest.service.abstract';
import {ResponseModel} from '../../base/response.model';
import {Injectable} from '@angular/core';
import {map,takeUntil} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PagesRestService extends RestServiceAbstract{

  getPage(page: any) {
    return this.get(`/${this.CMS_URL}/pages/` + page).pipe(map((data: any) => {
      return new ResponseModel(data);
    }));
  }

  listPages(search: string) {
    let url = `/${this.CMS_URL}/admin/pages`;
    if(search) url = `${url}/${search}`;

    return this.get(url).pipe(map((data: any) => {
      return new ResponseModel(data);
    }));
  }

  pageDetails(page: any) {
    return this.get(`/${this.CMS_URL}/admin/pages/details/` + page).pipe(map((data: any) => {
      return new ResponseModel(data);
    }));
  }

  savePage(data: any) {
    return this.post(`/${this.CMS_URL}/admin/pages`, data).pipe(map((data: any) => {
      return new ResponseModel(data);
    }));
  }

  deletePage(url: string) {
    return this.delete(`/${this.CMS_URL}/admin/pages/${url}`, {}).pipe(map((data: any) => {
      return new ResponseModel(data);
    }));
  }
}
