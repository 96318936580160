import {CredentialsRequest} from "./credentials.request";

export class RenewCredentialsRequest extends CredentialsRequest{
  old_password: string;

  constructor(obj: any = {}) {
    super(obj);
    this.old_password = obj && obj.old_password || null;
  }
}
