import {HttpErrorResponse} from '@angular/common/http';
import {SubsequentDeliveryModel} from '../../deliveries/subsequest.delivery.model';
import {SubsequentDeliveriesResponse} from "../../deliveries/api/subsequent.deliveries.response";
import {createReducer, on} from '@ngrx/store';
import {phx} from "../@types/models";
import SubsequentDeliveries = phx.portal.models.SubsequentDeliveries;
import * as SubsequentDeliveriesActions from "../actions/subsequent.deliveries.actions";


export const initState: SubsequentDeliveries = {} as SubsequentDeliveries;

export const subsequentDeliveriesReducer = createReducer(
  initState,
  on(SubsequentDeliveriesActions.INIT_SUBSEQUENT_DELIVERIES,(state,data: any)=>{
    return {
      ...state,
      ...data.payload,
      loading: true
    }
  }),
  on(SubsequentDeliveriesActions.LOAD_SUBSEQUENT_DELIVERIES, (state, data: any)=>{
    return {
      ...state,
      ...data.payload,
      loading: true
    }
  }),
  on(SubsequentDeliveriesActions.LOAD_SUBSEQUENT_DELIVERIES_COMPLETED, (state, data: any)=>{
    return {
      ...state,
      ...data.payload,
      loading: false
    }
  }),
  on(SubsequentDeliveriesActions.UPDATE_SUBSEQUENT_DELIVERIES, (state, data:any)=>{
    return {
      ...state,
      ...data.payload,
      loading: false
    }
  }),
  on(SubsequentDeliveriesActions.DELETE_SUBSEQUENT_DELIVERIES, (state, data:any)=>{
    return {
      ...state,
      ...data.payload,
      loading: false
    }
  }),
  on(SubsequentDeliveriesActions.FAIL_SUBSEQUENT_DELIVERIES, (state, data: any)=>{
    return {
      ...state,
      ...data,
      loading: false
    }
  })
);
