<div *ngIf="!loading" class="d-flex jce my-m">
  <form [formGroup]="idfForm">
    <mat-form-field *ngIf="!showInput" appearance="outline">
      <mat-label>IDF-Nummer</mat-label>
      <mat-select formControlName="idfInput" (selectionChange)="setIdf($event)">
        <mat-option *ngIf="idfs?.length === 0">
          <i class="fal fa-spinner-third fa-spin"></i> IDFs werden geladen.
        </mat-option>
        <mat-option *ngFor="let idf of idfs" [value]="idf.idf">{{ idf.idf }}
          ({{idf.name}})
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="showInput"  appearance="outline">
      <input matInput formControlName="idfInput" (change)="inputIdf($event)" />
    </mat-form-field>
  </form>
</div>
