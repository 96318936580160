
<table mat-table [dataSource]="documents" class="shadow" #table *ngIf="documents.length > 0">
  <!-- Position Column -->
  <ng-container matColumnDef="invNr">
    <th mat-header-cell *matHeaderCellDef> Belegnummer</th>
    <td mat-cell *matCellDef="let document"> {{document.inv_nr}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef> Belegeart</th>
    <td mat-cell *matCellDef="let document"> {{document.type?.value}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="idf">
    <th mat-header-cell *matHeaderCellDef> IDF Nummer</th>
    <td mat-cell *matCellDef="let document"> {{document.idf}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="invDate">
    <th mat-header-cell *matHeaderCellDef> Datum</th>
    <td mat-cell *matCellDef="let document"> {{document.inv_date | date: 'dd.MM.YYYY'}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="download">
    <th mat-header-cell *matHeaderCellDef> Download</th>
    <td mat-cell *matCellDef="let document">
      <button mat-mini-fab color="primary"
              (click)="downloadDocument(document, document.type, $event);"> <mat-icon fontSet="material-symbols-outlined" aria-hidden="false">download</mat-icon></button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell p-m" colspan="4"><p *ngIf="!loading">Leider konnten zu Ihrer Auswahl keine Belege gefunden werden.</p></td>
  </tr>
</table>
