import {Inject, Injectable} from '@angular/core';
import {PagedAsyncIterableIterator} from '@azure/core-paging';
import {BlobServiceClient, BlobBatchClient} from '@azure/storage-blob';
import {Observable} from 'rxjs/internal/Observable';
import {scan, startWith} from 'rxjs/operators';
import {BlobRestService} from "./blob.rest.service";
import {DataLakeServiceClient, DataLakeFileSystemClient, DataLakePathClient, Pipeline} from '@azure/storage-file-datalake';

@Injectable({
  providedIn: 'root'
})
export class BlobService {
  containerName = 'marketing-management';

  constructor(private blobRestService: BlobRestService,
              @Inject('STORAGE') private STORAGE: string) {
  }

  private async getSasToken(edit?: boolean) {
    if (edit) {
      return await this.blobRestService.getSasToken().toPromise();
    }
    return await this.blobRestService.getReaderToken().toPromise();
  }

  async getData(blobname: string, container?: string, edit?: boolean) {
    const response = await this.getSasToken(edit);
    const blobServiceClient = new BlobServiceClient(`${this.STORAGE}${response?.return_object}`);
    const client = blobServiceClient.getContainerClient(container ? container: this.containerName);
    const blobClient = client.getBlobClient(blobname);
    const downloadBlockBlobResponse = await blobClient.download();
    return await this.blobToString(await downloadBlockBlobResponse.blobBody);
  }

  async setData(blobname: string, content: string, container?: string) {
    const response = await this.blobRestService.getSasToken().toPromise();
    const blobServiceClient = new BlobServiceClient(`${this.STORAGE}${response?.return_object}`);
    const client = blobServiceClient.getContainerClient(container ? container: this.containerName);
    const blobClient = client.getBlockBlobClient(blobname);
    const uploadBlobResponse = await blobClient.upload(content, content.length);
    return {error_code: uploadBlobResponse.errorCode, requestId: uploadBlobResponse.requestId};
  }

  async uploadFile(file: any, blobname: string, container?: string, metaData?: any) {
    const response = await this.blobRestService.getSasToken().toPromise();
    const blobServiceClient = new BlobServiceClient(`${this.STORAGE}${response?.return_object}`);
    const client = blobServiceClient.getContainerClient(container ? container: this.containerName);
    const blobClient = client.getBlockBlobClient(blobname);
    const uploadImageResponse = await blobClient.uploadData(file, {blobHTTPHeaders: {blobContentType: file.type}, metadata: metaData ? metaData : {}});
    return {error_code: uploadImageResponse.errorCode, requestId: uploadImageResponse.requestId};
  }

  async deleteFile(blobname: string, container?: string) {
    const response = await this.blobRestService.getSasToken().toPromise();
    const blobServiceClient = new BlobServiceClient(`${this.STORAGE}${response?.return_object}`);
    const client = blobServiceClient.getContainerClient(container ? container: this.containerName);
    const blobClient = client.getBlockBlobClient(blobname);
    const deleteImageResponse = await blobClient.deleteIfExists({deleteSnapshots: 'include'});
    return {error_code: deleteImageResponse.errorCode, requestId: deleteImageResponse.requestId};
  }

  async deleteFolder(foldername: string, container?: string) {
    const response = await this.blobRestService.getSasToken().toPromise();

    const dataLakeClient = new DataLakeServiceClient(`${this.STORAGE}${response?.return_object}`);
    const fileSystemClient = dataLakeClient.getFileSystemClient(container ? container: this.containerName);
    const directoryClient = fileSystemClient.getDirectoryClient(foldername);

    const deleteResponse = await directoryClient.delete(true);
    console.log(deleteResponse);
    return {error_code: deleteResponse.errorCode, requestId: deleteResponse.requestId};
  }



  async folderList(container: string) {
    return this.listFoldersHierarchy(container);
  }

  async folderSublist(container: string, prefix: string) {
    return this.listFoldersHierarchy(container,prefix);
  }

  async fileListInFolder(container: string, prefix: string) {
    return this.listFilesHierarchy(container, prefix);
  }


  async listContainers(){
    const response = await this.blobRestService.getSasToken().toPromise();
    const blobServiceClient = new BlobServiceClient(`${this.STORAGE}${response?.return_object}`);
    return blobServiceClient.listContainers({includeMetadata: true});
  }

  async createContainer(container: string, metaData: {}) {
    const response = await this.blobRestService.getSasToken().toPromise();
    const blobServiceClient = new BlobServiceClient(`${this.STORAGE}${response?.return_object}`);
    return blobServiceClient.createContainer(container, metaData);
  }

  async deleteContainer(container: string, metaData: {}) {
    const response = await this.blobRestService.getSasToken().toPromise();
    const blobServiceClient = new BlobServiceClient(`${this.STORAGE}${response?.return_object}`);
    return blobServiceClient.deleteContainer(container, metaData);
  }

  async listFilesHierarchy(container: string, prefix?: string){
    const response = await this.blobRestService.getSasToken().toPromise();
    const blobServiceClient = new BlobServiceClient(`${this.STORAGE}${response?.return_object}`);
    const containerClient = blobServiceClient.getContainerClient(container);

    if(prefix){
      return containerClient.listBlobsByHierarchy("/",{ prefix: prefix, includeMetadata: true })
    }
    return containerClient.listBlobsByHierarchy("/", {includeMetadata: true});
  }

  async listFoldersHierarchy(container: string, prefix?: string){
    const response = await this.blobRestService.getSasToken().toPromise();
    const blobServiceClient = new BlobServiceClient(`${this.STORAGE}${response?.return_object}`);
    const containerClient = blobServiceClient.getContainerClient(container);

    if(prefix){
      return containerClient.listBlobsByHierarchy("/",{ prefix: prefix, includeMetadata: true })
    }
    return containerClient.listBlobsByHierarchy("/", { includeMetadata: true});
  }

  async blobToString(blob: any) {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onloadend = (ev) => {
        resolve(ev.target?.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsText(blob);
    });
  }

  private asyncToObservable<T, TService>(
    iterable: PagedAsyncIterableIterator<T, TService>
  ) {
    return new Observable<T>(
      (observer: any) =>
        void (async () => {
          try {
            for await (const item of iterable as AsyncIterable<T>) {
              if (observer.closed) {
                return;
              }
              observer.next(item);
            }
            observer.complete();
          } catch (e) {
            observer.error(e);
          }
        })()
    ).pipe(
      scan<T, T[]>((items: any, item: any) => [...items, item], []),
      startWith([] as T[])
    );
  }
}
