import {UserModel} from "../user.model";

export class UserResponse {
  name: string;
  groups: string[];
  user: UserModel | null;

  constructor(obj: any) {
    this.name = obj && obj.name || null;
    this.groups = obj && obj.groups || [];

    //TODO: map actual personal data
    this.user = obj && new UserModel(obj) || null;
  }
}
