import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {DialogRestService} from "../../core/cms/services/dialog.rest.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {LegalComponent} from "./legal/legal.component";

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnDestroy, OnInit{
  private filter: string = '';
  protected ngUnsubscribe$: Subject<void> = new Subject<void>();
  constructor(
    public dialog: MatDialog,
    public router: Router,
    private dialogRestService: DialogRestService
  ) {
    this.router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
        this.filter = this.getFilter();
      }
    });
  }

  ngOnInit(): void {
      this.filter = this.getFilter();
      this.getDialog(this.filter);
  }

  private getFilter(): string {
    return this.router.url;
  }

  private getDialog(filter: any) {
      this.dialogRestService.getDialogs(filter).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(popups => {
          const selectedPopup = popups.return_object[0];
          if(selectedPopup) {
            switch (selectedPopup.dialog_popup_type) {
              case 'LEGAL':
                const dialogRef = this.dialog.open(
                  LegalComponent,
                  {
                    width: '800px',
                    height: '700px',
                    disableClose: true,
                    autoFocus: false,
                    data: selectedPopup}
                )
                break;
              default:
            }
          }
      })
  }


  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe$.next();
    // This completes the subject properly.
    this.ngUnsubscribe$.complete();
  }
}
