import {Injectable, OnDestroy} from '@angular/core';
import {SubsequentDeliveryModel} from "../subsequest.delivery.model";
import {SubsequentDeliveriesUpdateRequest} from "../api/subsequent.deliveries.update.request";
import {SubsequentDeliveriesRestService} from "./subsequent.deliveries.rest.service";
import {DeliveryAvailabilityModel} from "../models/delivery.availability.model";

@Injectable({
  providedIn: 'root'
})
export class SubsequentDeliveriesService {

  constructor(public subsequentDeliveriesRestService: SubsequentDeliveriesRestService) {
  }

    delete(delivery: SubsequentDeliveryModel) {
      let forDelete = delivery;
      forDelete.quantity = 0;
      const deleted = new SubsequentDeliveriesUpdateRequest(forDelete);

      return this.subsequentDeliveriesRestService.updateSubsequentDelivery(deleted);
    }

  checkAvailability(request: DeliveryAvailabilityModel) {
    return this.subsequentDeliveriesRestService.checkAvailability(request);

  }

  addSubsequentDelivery(request: DeliveryAvailabilityModel) {
    return this.subsequentDeliveriesRestService.addSubsequentDelivery(request);
  }
}
