<h1 mat-dialog-title>Edit Notification</h1>
<mat-dialog-content>
  <form [formGroup]="notifiationForm" class="d-grid notice-form">
    <mat-form-field  appearance="outline">
      <mat-label>Title</mat-label>
      <input matInput formControlName="title"/>
    </mat-form-field>
    <div class = "py-m">
      <mat-slide-toggle formControlName="active" color=primary>Active</mat-slide-toggle>
    </div>
    <mat-form-field  appearance="outline">
      <mat-label>Type</mat-label>
      <mat-select formControlName="type">
        <mat-option value="{{notificationType.INFO}}">Info</mat-option>
        <mat-option value="{{notificationType.WARN}}">Warn</mat-option>
        <mat-option value="{{notificationType.XMASS}}">Weihnachten</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field  appearance="outline">
      Date Range
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate placeholder="Start date" formControlName="start" >
        <input matEndDate placeholder="End date" formControlName="end" >
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <mat-form-field  appearance="outline">
      <mat-label>Filter</mat-label>
      <mat-select formControlName="filter">
        <mat-option value="{{path.path}}" *ngFor="let path of paths">{{path.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="editor">
      <mat-label>Text</mat-label>
      <editor apiKey="8rzuewmpt7g2ex8geevsbvwoosfby3wnmsm3nm7u09p1doro" [init]="{ menubar: false, toolbar: 'formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | image link | code '}"
              formControlName="text" plugins="link code">
      </editor>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="p-m">
    <button mat-flat-button mat-dialog-close>Close</button>
    <button mat-flat-button color="primary" (click)="submit()">Save</button>
</mat-dialog-actions>
