<mat-card *ngIf="error !== '' || success !== ''" class="mb-m p-m">
  <p *ngIf="success !== '' ">
    {{success}}
  </p>
  <p *ngIf="error !== '' ">
    {{error}}
  </p>
</mat-card>

<div class="d-flex jcsb">
  <h2>{{folder ? folder : container}}</h2>
  <div class="w-10">
    <button mat-icon-button (click)="addFolder()">
      <mat-icon class="material-symbols-outlined">create_new_folder</mat-icon>
    </button>
    <button mat-icon-button (click)="addFile()">
      <mat-icon class="material-symbols-outlined">upload_file</mat-icon>
    </button>
  </div>
</div>
<div *ngIf="dataSource.data.length > 0">
  <mat-table #files [dataSource]="dataSource">
    <ng-container matColumnDef="filename">
      <mat-header-cell *matHeaderCellDef> Dateiname</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ getName(element.name) }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef> Typ</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{element.kind === 'prefix' ? ('folder' | translate): findType(element)}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="modified_by">
      <mat-header-cell *matHeaderCellDef> Modified By</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ getUser(element) }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef> Datum</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ (element.kind === 'prefix' ? getDate(element.Properties["Creation-Time"]) : getDate(
        element.properties["createdOn"])) | date: 'dd.MM.yyyy' }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef> Aktionen</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-icon-button color="primary"
                (click)="element.kind === 'prefix' ? deleteFolder(element): remove(element)">
          <mat-icon>{{element.kind === 'prefix' ? 'delete_sweep' : 'delete'}}</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
