import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {SubsequentDeliveriesRestService} from '../../deliveries/services/subsequent.deliveries.rest.service';
import {of} from "rxjs/internal/observable/of";
import { SubsequentDeliveriesRequest } from '../../deliveries/api/subsequent.deliveries.request';

@Injectable()
export class SubsequentDeliveriesEffects {

  constructor(private actions$: Actions,
              private subsequentDeliveriesRestService: SubsequentDeliveriesRestService) {
  }

  loadSubsequentDeliveries$: any = createEffect(() => {
    return this.actions$
    .pipe(ofType('[SUBSEQUENT DELIVERIES] Load'))
    .pipe(map((action: any) => action.payload),
      switchMap((subsequentDeliveriesRequest: SubsequentDeliveriesRequest) =>
        this.subsequentDeliveriesRestService.loadSubsequentDeliveries(subsequentDeliveriesRequest)
        .pipe(
          map((data: { return_object: any; }) => ({type: '[SUBSEQUENT DELIVERIES] Load Completed', payload: data.return_object})),
          catchError((error: any) =>  of({type: '[SUBSEQUENT DELIVERIES] Failed', payload: error})
          )
        )
      )
    )
  });
}
