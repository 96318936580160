import {Component, OnInit, OnDestroy} from '@angular/core';
import {AuthService} from "../../core/auth/services/auth.service";
import {NavigationExtras, Router} from '@angular/router';
import {tap, takeUntil, distinctUntilChanged} from 'rxjs/operators';
import {Subject} from "rxjs";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {LoginRequest} from "../../core/auth/api/login.request";
import {noEmailValidator} from "../../core/validators/noEmail.validator";
import {noIdfValidator} from "../../core/validators/noIdf.validator";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DialogComponent} from "../../shared/dialog/dialog.component";
import {ChangePasswordDialogComponent} from "../change-password-dialog/change-password-dialog.component";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy, OnInit {
  hide = true;
  public ngUnsubscribe$ = new Subject<void>(); // emit unsubscription
  public loginForm: FormGroup;
  public loginError = false;
  public username = new FormControl('', [
    Validators.required,
    Validators.pattern('^([a-z0-9-]){1,}[.]([a-z0-9-.]){1,}$'),
    noEmailValidator(),
    noIdfValidator()
  ]);
  public password = new FormControl('', [
    Validators.required
  ]);
  public action: string = '';
  public columns: number = 2;
  Breakpoints = Breakpoints;
  currentBreakpoint:string = '';

  readonly breakpoint$ = this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small]).pipe(tap(value => console.log(value)), distinctUntilChanged()
  );

  constructor(public authService: AuthService,
              private formBuilder: FormBuilder,
              public dialog: MatDialog,
              private breakpointObserver: BreakpointObserver,
              public router: Router) {
    this.breakpointChanged();
    this.loginForm = formBuilder.group({
      username: this.username,
      password: this.password,
    });
  }

  ngOnInit (){
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/dashboard']);
    }
    this.breakpoint$.subscribe((br) => {
        console.log(br);
        this.breakpointChanged()
    }
    );
  }

  login() {
    this.loginError = false;
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/dashboard']);
    }


    // Usually you would use the redirect URL from the auth service.
    // However, to keep the example simple, we will always redirect to `/admin`.
    const redirectUrl = '/dashboard';

    // Set our navigation extras object
    // that passes on our global query params and fragment
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      preserveFragment: true
    };

    const loginRequest = new LoginRequest(this.loginForm.value);
    this.authService.login(loginRequest).pipe(takeUntil(this.ngUnsubscribe$))
    .subscribe(() => {
      if (this.authService.isAuthenticated()) {
        this.authService.getUserResponseFromGateway().pipe(takeUntil(this.ngUnsubscribe$))
          .subscribe((user:any) => {
          if(user.return_object.passwordIdle){
            const changePasswordConfig = new MatDialogConfig();
            changePasswordConfig.autoFocus = true;
            changePasswordConfig.width = '50vw';
            changePasswordConfig.disableClose = true;
            changePasswordConfig.data = {
              oldPassword: this.password.value,
              showBackButton: false,
              token: this.authService.getToken()
            };
            this.dialog.open(ChangePasswordDialogComponent, changePasswordConfig).afterClosed()
            .pipe(takeUntil(this.ngUnsubscribe$)).subscribe((result: any) => {
              if (result.data === 'success') {
                const alertDialogConfig = new MatDialogConfig();
                alertDialogConfig.width = '50vw';
                alertDialogConfig.data = {
                  content: 'Ihr Passwort konnte erfolgreich geändert werden.',
                  ok: 'weiter zum Apothekenportal',
                  title: 'Passwortänderung',
                  type: 'alert'
                };
                this.dialog.open(DialogComponent, alertDialogConfig).afterClosed()
                .pipe(takeUntil(this.ngUnsubscribe$))
                .subscribe((res: any) => {
                  this.router.navigate([redirectUrl], navigationExtras);
                });
              } else if (result.data === 'cancel') {
                // pass
              } else if (result.data) {
                this.router.navigate(['fehler'], {queryParams: {error: JSON.stringify(result.data)}});
              }
            });
          } else {
            // Redirect the user
            this.router.navigate([redirectUrl], navigationExtras);
          }
        });
      }
    },
      (error: any)=>{
        this.loginError = true;
      })
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  private breakpointChanged() {
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.columns = 2;
    } else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 2;
    } else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 1;
    }
  }
}
