import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {Salutation} from "../../../core/user/models/salutation.enum";
import {Status} from "../../../core/user/models/status.enum";
import {UserAdminRestService} from "../../../core/user/services/user.admin.rest.service";
import {Admingroup} from "../../../core/user/models/admingroup.enum";

@Component({
  selector: 'app-internal-form',
  templateUrl: './internal-form.component.html',
  styleUrl: './internal-form.component.scss'
})
export class InternalFormComponent implements OnInit {
  @Input() user: any = {};

  public userForm = this.formBuilder.group({
    id: [''],
    salutation: ['', Validators.required],
    title: [''],
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    group: ['', Validators.required],
    username: [''],
    email: ['', Validators.required],
    user_group: ['']
  });
  public salutations = Salutation;
  public userGroups = Admingroup;
  public userStatus = Status;
  public showNotification = false;
  public editUsername = false;
  notification: { html: string, type: string } = {html: '', type: ''};

  constructor(private userService: UserAdminRestService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    if (this.user.username) {
      this.userForm.patchValue(this.user.user);
    }
  }


  getSalutationValues() {
    return Object.values(this.salutations);
  }

  getStatus(status: any) {
    // @ts-ignore
    return this.userStatus[status];
  }

  submit() {
    if (!this.userForm.valid) {
      return;
    }
    this.showNotification = false;
    let values = this.userForm.value;

    values.user_group = values['group'];
    Object.entries(this.salutations).forEach((entry) => {
      if (entry[1] === values.salutation) {
        values.salutation = entry[0];
      }
    });

    if (this.user.username) {
      this.userService.update(values).subscribe((result: any) => {
        if (!result.error_code) {
          this.notification.html = 'Der Benutzer <b>' + values.first_name + ' ' + values.last_name + '</b> konnte erfolgreich gespeichert werden.'
          this.notification.type = 'success';
        } else {
          this.notification.html = 'Der Benutzer <b>' + values.first_name + ' ' + values.last_name + '</b> konnte nicht gespeichert werden.'
          this.notification.type = 'error';
        }
        this.showNotification = true;
        this.notify();
      })
    } else {
      this.userService.create(values).subscribe((result: any) => {
        if (!result.error_code) {
          this.notification.html = 'Der Benutzer <b>' + values.first_name + ' ' + values.last_name + '</b> konnte erfolgreich gespeichert werden.'
          this.notification.type = 'success';
        } else {
          this.notification.html = 'Der Benutzer <b>' + values.first_name + ' ' + values.last_name + '</b> konnte nicht gespeichert werden.'
          this.notification.type = 'error';
        }
        this.showNotification = true;
        this.notify();
      })
    }
  }

  notify() {
    setTimeout(() => {
      this.showNotification = true;
      this.notification = {html: '', type: ''}
    }, 5000)
  }
}
