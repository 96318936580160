<!-- Loading -->
<ng-container *ngIf="loading">
  <div class="row panel-body">
    <div class="col-md-2"></div>
    <div class="col-md-8">
      <div class="text-center py-l">
        <i class="fal fa-spinner-third fa-spin"></i>&nbsp;Ihre Daten werden geladen.
      </div>
    </div>
    <div class="col-md-2"></div>
  </div>
</ng-container>

<!-- Loading has finished and there is a token. -->
<div *ngIf="token" [class.hidden]="loading">
  <div class="row panel-header">
    <div class="col-md-2"></div>
    <div class="col-md-8">
      <h1>Legen Sie ein neues Passwort fest.</h1>
    </div>
    <div class="col-md-2"></div>
  </div>
  <div class="row panel-body">
    <div class="col-md-2"></div>
    <div class="col-md-8">
      <app-password [token]="token" (success)="credentialsSet($event)"></app-password>
    </div>
    <div class="col-md-2"></div>
  </div>
</div>

<!-- Loading has finished and there is NO TOKEN. -->
<ng-container *ngIf="!loading && !token">
  <div class="row panel-header">
    <div class="col-md-2"></div>
    <div class="col-md-8">
      <h1>Ihr Token ist ungültig.</h1>
    </div>
    <div class="col-md-2"></div>
  </div>
  <div class="row panel-body">
    <div class="col-md-2"></div>
    <div class="col-md-8">
      Bitte wenden sie sich an einen Mitarbeiter der Marketingabteilung.
    </div>
    <div class="col-md-2"></div>
  </div>
</ng-container>
