<ng-container *ngIf="loading">
  <p>Ihre Daten werden geladen.</p>
</ng-container>
<ng-container *ngIf="!loading">
  <app-password (success)="credentialsSet($event)"
                 [token]="token"
                 [credentialsService]="passwordRestService"
                [type] = "'reset'"
  >
  </app-password>
</ng-container>
