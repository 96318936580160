import { Injectable, OnDestroy } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {phx} from "../../store/@types/models";
import Users = phx.portal.models.Users;
import {DELETE_INTERNAL_USER, LOAD_INTERNAL_USERS, LOAD_USERS} from "../../store/actions/users.actions";

@Injectable({
  providedIn: 'root'
})
export class UserAdminService implements OnDestroy{
  private ngUnsubscribe$ = new Subject<any>();


  constructor(public store: Store<{ users: Users }>) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  getUsersLoading() {
    return this.store.select('users').pipe(map((users: any) => users.userLoading));
  }

  loadUsers(p: any) {
    this.store.dispatch(LOAD_USERS({payload: p}));
    return this.store.select('users').pipe(map((users: any) => users));
  }

  loadInternalUsers(p: any) {
    this.store.dispatch(LOAD_INTERNAL_USERS({payload: p}));
    return this.store.select('users').pipe(map((users: any) => users));
  }

  remove(id: any) {
    this.store.dispatch(DELETE_INTERNAL_USER({payload: id}));
    return this.store.select('users').pipe(map((users: any) => users));
  }
}
