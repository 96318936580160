import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {DeliveryAvailabilityModel} from "../../deliveries/models/delivery.availability.model";

@Injectable({
  providedIn: 'root',
})
export class OrderService implements OnDestroy{
  private ngUnsubscribe$ = new Subject<any>();

  constructor(){

  }

  newOrder(request: DeliveryAvailabilityModel) {

  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    //this.ngUnsubscribe$.next();
    // This completes the subject properlly.
    this.ngUnsubscribe$.complete();
  }
}
