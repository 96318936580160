<form [formGroup]="dataCheck"
      (submit)="onSubmit()"
      *ngIf="!showErrorMessage && !showSuccessMessage">
  <div class="d-flex jcsb">
    <div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Inhaber*in</mat-label>
          <input tabindex="8" matInput autocomplete="off" name="first_name"
                 formControlName="first_name">
          <mat-error *ngIf="dataCheck.get('first_name')?.hasError('required')">
            Bitte geben Sie Vorname und Nachname des Inhabers / der Inhaberin ein.
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Inhaber*in</mat-label>
          <input tabindex="8" matInput autocomplete="off" name="last_name"
                 formControlName="last_name">
          <mat-error *ngIf="dataCheck.get('last_name')?.hasError('required')">
            Bitte geben Sie Vorname und Nachname des Inhabers / der Inhaberin ein.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>IDF-Nummer</mat-label>
          <input tabindex="4" matInput autocomplete="off" name="idf"
                 placeholder="IDF-Nummer z.B. 0123456"
                 formControlName="idf">
          <mat-error *ngIf="dataCheck.get('idf')?.hasError('required')">Bitte geben Sie Ihre
            IDF-Nummer ein.
          </mat-error>
          <mat-error *ngIf="dataCheck.get('idf')?.hasError('pattern')">Geben Sie eine 7-stellige
            Zahlenfolge ein.
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Apothekenname</mat-label>
          <input tabindex="5" matInput autocomplete="off" name="name"
                 formControlName="name">
          <mat-error *ngIf="dataCheck.get('name')?.hasError('required')">
            Bitte geben Sie Ihren Apothekename ein.
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Ort</mat-label>
          <input tabindex="6" matInput autocomplete="off" name="city"
                 formControlName="city">
          <mat-error *ngIf="dataCheck.get('city')?.hasError('required')">
            Bitte geben Sie den Ort Ihrer Haupt-Apotheke ein.
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>PLZ</mat-label>
          <input tabindex="7" matInput autocomplete="off" name="zip_code"
                 formControlName="zip_code">
          <mat-error *ngIf="dataCheck.get('zip_code')?.hasError('required')">
            Bitte geben Sie die PLZ Ihrer Haupt-Apotheke ein.
          </mat-error>
          <mat-error *ngIf="dataCheck.get('zip_code')?.hasError('pattern')">
            Bitte geben Sie eine gültige Postleitzahl ein.
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Telefonnummer</mat-label>
          <input tabindex="9" matInput autocomplete="off" name="phone"
                 formControlName="phone">
          <mat-error *ngIf="dataCheck.get('phone')?.hasError('required')">
            Bitte geben Sie eine gültige Telefonnummer ein.
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>E-Mail-Adresse</mat-label>
          <input tabindex="10" matInput autocomplete="off" name="email"
                 formControlName="email">
          <mat-error *ngIf="dataCheck.get('email')?.hasError('required')">
            Bitte geben Sie Ihre E-Mail Adresse ein.
          </mat-error>
          <mat-error *ngIf="dataCheck.get('email')?.hasError('email')">
            Bitte geben Sie eine gültige E-Mail-Adresse ein.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="d-flex jcsb">
    <button tabindex="13" mat-button (click)="continue.next('back')">
      Zurück zur Registrierung
    </button>

    <button tabindex="14" mat-button color="primary" type="submit" [disabled]="!dataCheck.valid">
      Anfrage senden
    </button>
  </div>
</form>
