import {map, takeUntil} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {RestServiceAbstract} from '../../base/rest.service.abstract';
import {Observable, Subject} from 'rxjs';
import {ResponseModel} from '../../base/response.model';
import {SubUserModel} from '../subuser.model';

@Injectable({
  providedIn: 'root',
})
export class SubUserRestService extends RestServiceAbstract implements OnDestroy {

  public ngUnsubscribe$: Subject<void> = new Subject<void>();

  public loadSubUsers(): Observable<ResponseModel> {
    return this.get(`/${this.USERMANAGEMENT}/user/subusers`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  public create(sum: SubUserModel): Observable<ResponseModel> {
    return this.post(`/${this.USERMANAGEMENT}/user/subusers/create`, sum).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  public update(sum: SubUserModel): Observable<ResponseModel> {
    return this.patch(`/${this.USERMANAGEMENT}/user/subusers/update/${sum.id}`, sum).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  public remove(sum: SubUserModel): Observable<ResponseModel> {
    return this.delete(`/${this.USERMANAGEMENT}/user/subusers/delete/${sum.id}`, sum).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  public requestAuthTree(): Observable<ResponseModel> {
    return this.get(`/${this.USERMANAGEMENT}/user/subusers/authtree`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe$.next();
    // This completes the subject properly.
    this.ngUnsubscribe$.complete();
  }
}
