export class TrackTraceRequest {

  branch_no: number | null;
  idf: any;
  request_date: string | null;
  details_desired: boolean;
  tour_id: number | null;

  constructor(branch: number, idf: any, date: string, details: boolean, tourID?: number) {
    this.idf = idf || null;
    this.request_date = date || null;
    this.branch_no = branch || null;
    this.details_desired = details || false;
    this.tour_id = tourID || null;
  }
}
