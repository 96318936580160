import {RestServiceAbstract} from "../../../core/base/rest.service.abstract";
import {catchError} from 'rxjs/operators';
import {Observable, Subject, throwError} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';
import {ResponseModel} from "../../../core/base/response.model";

@Injectable({
  providedIn: 'root',
})
export class PreorderArchiveService extends RestServiceAbstract implements OnDestroy{
  protected ngUnsubscribe$: Subject<void> = new Subject<void>();

  loadPreordersArchive(idf: string) {
    return this.get(`/${this.RESOURCE}/preorders/archive/${idf}`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return new ResponseModel(data);
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  loadPreorderItems(idf: string, id: number) {
    return this.get(`/${this.RESOURCE}/preorders/archive/${idf}/${id}`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return new ResponseModel(data);
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    //this.ngUnsubscribe$.next();
    // This completes the subject properlly.
    this.ngUnsubscribe$.complete();
  }

}
