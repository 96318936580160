import {Component} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Hageda Stumpf';

  constructor(public http: HttpClient,
              public translate: TranslateService) {
    translate.addLangs(['de']);
    translate.setDefaultLang('de');
  }
}
