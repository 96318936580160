import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from "rxjs/internal/observable/of";
import {ProductRestService} from "../../products/services/product.rest.service";
import {SearchRestService} from "../../products/services/search.rest.service";
import {
  LOAD_PRODUCT_SEARCH,
  LOAD_PRODUCT_SEARCH_COMPLETED,
  LOAD_PRODUCT_SEARCH_FAILED,
  LOAD_PRODUCT_SHOP,
  LOAD_PRODUCT_SHOP_COMPLETED,
  LOAD_PRODUCT_SHOP_FAILED,
  SEARCH_PRODUCT_SHOP
} from "../actions/product.actions";
import {ProductRequest} from "../../products/api/product.request.model";


@Injectable()
export class ProductsEffects {

  constructor(private actions$: Actions,
              private productRestService: ProductRestService,
              private searchRestService: SearchRestService) {
  }

  searchProducts$: any = createEffect(() => {
    return this.actions$
    .pipe(ofType(LOAD_PRODUCT_SEARCH.type))
    .pipe(map((action: any) => action.payload),
      switchMap((productSearchRequest) =>
        this.searchRestService.simpleSearchProducts(productSearchRequest)
        .pipe(
          map(data => ({type: LOAD_PRODUCT_SEARCH_COMPLETED.type, payload: data.return_object})),
          catchError((error) =>  of({type: LOAD_PRODUCT_SEARCH_FAILED.type, payload: error})
          )
        )
      )
    )
  });

  shopProducts$: any = createEffect(() => {
    return this.actions$
    .pipe(ofType(LOAD_PRODUCT_SHOP.type))
    .pipe(map((action: any) => action.payload),
      switchMap((productSearchRequest: any) =>
        this.searchRestService.searchProducts(productSearchRequest)
        .pipe(
          map(data => ({type: LOAD_PRODUCT_SHOP_COMPLETED.type, payload: data.return_object})),
          catchError((error) =>  of({type: LOAD_PRODUCT_SHOP_FAILED.type, payload: error})
          )
        )
      )
    )
  });

  // loadShop$: any = createEffect(()=> {
  //   return this.actions$
  //   .pipe(ofType(SEARCH_PRODUCT_SHOP.type));
  // })
}
