import {catchError, map, takeUntil} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {RestServiceAbstract} from '../../base/rest.service.abstract';
import {Subject} from 'rxjs';
import {ResponseModel} from '../../base/response.model';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UserRestService extends RestServiceAbstract implements OnDestroy {

  public ngUnsubscribe$: Subject<void> = new Subject<void>();

  /**
   * Required by search form to request selectable branch numbers
   *
   * @returns {Observable<any>}
   */
  public getVZs(idf: string) {
    return this.get(`/${this.USERMANAGEMENT}/distributionCenter?idf=${idf}`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return new ResponseModel(data);
      })
    );
  }

  public getStaticNav(cmsUrl: string) {
    const params = new HttpParams()
    .set('partial', 'navigation-main')
    .set('format', 'json');
    return this.getFromCms(``, {observe: 'response', params, withCredentials: true}).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return data.body;
      })
    );
  }

  /**
   * Required by search form to request selectable IDFs
   *
   * @returns {Observable<any>}
   */
  // FIXME If we go for different contexts, then we basically kill us here with switchmap
  public getIDFs(context: any = 'all') {
    const obj: any[] = [];
    obj[context] = true;
    const params = (context === 'all' ? null : obj);
    return this.get(`/${this.USERMANAGEMENT}/idf/details`, {params: params}).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  public getAllIDFs() {
    return this.get(`/${this.USERMANAGEMENT}/idf/details`, {params: null}).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }


  public getDetailsForIdf(idf: string) {
    return this.get(`/${this.USERMANAGEMENT}/idf/${idf}/idfDetails`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  public getMainIdf() {
    return this.get(`/${this.USERMANAGEMENT}/idf/main`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  getShippingAdresses() {
    return this.get(`/${this.USERMANAGEMENT}/idf/shippingAddresses`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  // PXPPO-1813 - Call the backend token generator w/o arguments - session is suffiecient
  getAuthToken() {
    return this.get(`/${this.USERMANAGEMENT}/idf/authToken`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  // PXPPO-1885 - Generate tokens for sub IDFs, too
  getAuthTokens() {
    return this.get(`/${this.USERMANAGEMENT}/idf/authTokens`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  getMainDistributionCentersAdress() {
    return this.get(`/${this.USERMANAGEMENT}/distributionCenter/main`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  getAllDistributionCentersAdress() {
    return this.get(`/${this.USERMANAGEMENT}/distributionCenter/all`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }


  getPaybackCurrentPoints(idf: string) {
    return this.get(`/${this.RESOURCE}/payback/my-points`, {params: {idf: idf}}).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }


  getPaybackKpis(idf: string) {
    return this.get(`/${this.RESOURCE}/payback/kpi`, {params: {idf: idf}}).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe$.next();
    // This completes the subject properlly.
    this.ngUnsubscribe$.complete();
  }
}
