import {Component, OnInit, OnDestroy} from '@angular/core';
import {PagesRestService} from "../../../core/cms/services/pages.rest.service";
import {MatTableDataSource, MatTable} from "@angular/material/table";
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {shareReplay, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ConfirmationDialogModel} from "../../../core/deliveries/models/confirmation.dialog.model";
import {ConfirmationDialogComponent} from "../../../shared/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: 'app-pages-management',
  templateUrl: './pages-management.component.html',
  styleUrl: './pages-management.component.scss'
})
export class PagesManagementComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$ = new Subject<void>();
  pagesList = [];
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['active', 'tile', 'page_title', 'relative_url', 'public', 'actions'];
  success: string = '';
  error: string = '';
  selectedPage = '';
  editing = false;

  constructor(private pagesRestService: PagesRestService,
              private dialog: MatDialog) {

  }

  ngOnInit() {
    this.listPages('');
  }

  listPages(search: string) {
    this.pagesRestService.listPages(search).pipe(takeUntil(this.ngUnsubscribe$), shareReplay(1)).subscribe((result: any) => {
      this.pagesList = result.return_object;
      this.dataSource.data = [];
      this.dataSource.data = this.pagesList;
    })
  }

  edit(element: any) {
    this.selectedPage = element;
    this.editing = true;
  }

  deletePage(url: string, title: string) {
    const config = new ConfirmationDialogModel('Delete Page?', 'Are you sure you want to delete '+title+'?');

    const deletePage = this.dialog.open(ConfirmationDialogComponent, {
      width: '35rem',
      autoFocus: true,
      data: config
    });

    deletePage.afterClosed().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(async (result: any) => {
      if (result) {
        this.pagesRestService.deletePage(url).pipe(takeUntil(this.ngUnsubscribe$), shareReplay(1)).subscribe((result: any) => {
          if (result) {
            this.success = 'Page ' + title + ' was succesfully deleted.'
          } else {
            this.error = 'Something has gone wrong, please try again later.'
          }
          setTimeout(()=>{
            this.error = '';
            this.success = '';
          }, 10000)
        })
      }
    })


  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
