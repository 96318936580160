import {RestServiceAbstract} from "../../base/rest.service.abstract";
import {map} from 'rxjs/operators';
import {ResponseModel} from '../../base/response.model';
import {Observable} from 'rxjs';
import {ProductRequest} from "../api/product.request.model";

export class SearchRestService extends RestServiceAbstract {

  searchProducts(search: ProductRequest): Observable<ResponseModel> {
    return this.get(`/${this.RESOURCE}/product/v2/search?search=${search.search}&idf=${search.idf}&size=${search.size}&page=${search.page}&availableOnly=${search.availableOnly}`).pipe(
      map((response: Object) => {
        return new ResponseModel(response);
      })
    );
  }

  simpleSearchProducts(search: ProductRequest): Observable<ResponseModel> {
    return this.post(`/${this.RESOURCE}/product/v2/search/simple`, search).pipe(
      map((response: Object) => {
        return new ResponseModel(response);
      })
    );
  }

}
