export class Branch {
  id: number |null;
  location: string|null;
  region: string|null;

  constructor(obj: any) {
    this.id = obj && obj.id || null;
    this.location = obj && obj.location || null;
    this.region = obj && obj.region || null;
  }
}
