import {Injectable, OnDestroy} from '@angular/core';
import {RestServiceAbstract} from '../../base/rest.service.abstract';
import {map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ResponseModel} from '../../base/response.model';
import {SubsequentDeliveriesRequest} from '../api/subsequent.deliveries.request';
import {SubsequentDeliveriesUpdateRequest} from '../api/subsequent.deliveries.update.request';
import {DeliveryAvailabilityModel} from "../models/delivery.availability.model";


@Injectable({
  providedIn: 'root'
})
export class SubsequentDeliveriesRestService extends RestServiceAbstract implements OnDestroy {
  public ngUnsubscribe$: Subject<boolean> = new Subject<boolean>();

  public loadSubsequentDeliveries(subsequentDeliveriesRequest: SubsequentDeliveriesRequest) {
    return this.get(`/${this.RESOURCE}/subsequent-delivery/${subsequentDeliveriesRequest.getIdf()}`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((response: Object) => {
        return new ResponseModel(response);
      })
    );
  }

  public updateSubsequentDelivery(subsequentDeliveryUpdate: SubsequentDeliveriesUpdateRequest) {
    return this.put(`/${this.RESOURCE}/subsequent-delivery`, subsequentDeliveryUpdate).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((response: Object) => {
        return new ResponseModel(response);
      })
    )
  }

  checkAvailability(request: DeliveryAvailabilityModel) {
    return this.post(`/${this.RESOURCE}/subsequent-delivery/availability`, request).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((response: Object) => {
        return new ResponseModel(response);
      })
    );
  }

  addSubsequentDelivery(request: DeliveryAvailabilityModel) {
    return this.post(`/${this.RESOURCE}/subsequent-delivery`, request).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((response: Object) => {
        return new ResponseModel(response);
      })
    );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.unsubscribe();
  }
}
