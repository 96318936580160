export class PageRequest {
  id: number;
  page_title: string;
  nav_title: string;
  relative_url: string;
  content: string;
  end_date: string;
  start_date: string;
  banner_image: string;
  tile: string;
  is_public: boolean;
  back_link: string;
  show_title: boolean;
  published: boolean;
  authorities: any[];

  constructor(obj: any) {
    this.id = obj && obj.id || null;
    this.page_title = obj && obj.page_title || null;
    this.nav_title = obj && obj.nav_title || null;
    this.relative_url = obj && obj.relative_url || null;
    this.content = obj && obj.content || null;
    this.end_date = obj && obj.end_date || null;
    this.start_date = obj && obj.start_date || null;
    this.banner_image = obj && obj.banner_image || null;
    this.tile = obj && obj.tile || null;
    this.is_public = obj && obj.public || false;
    this.back_link = obj && obj.back_link || null;
    this.show_title = obj && obj.show_title || false;
    this.published = obj && obj.published || false;
    this.authorities = obj && obj.authorities || [];
  }
}
