import {Injectable} from '@angular/core';
import {map, takeUntil} from "rxjs/operators";
import {Observable, Subject} from "rxjs";
import {RestServiceAbstract} from "../../base/rest.service.abstract";
import {PublicContactRequest} from "../api/public.contact.request";
import {ResponseModel} from "../../base/response.model";

@Injectable({
  providedIn: 'root'
})
export class PublicContactRestService extends RestServiceAbstract {

  public ngUnsubscribe$: Subject<void> = new Subject<void>();

  public makeContact(contact: PublicContactRequest): Observable<ResponseModel> {
    return this.post(`/${this.USERMANAGEMENT}/publicservice/contact`, contact).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe$.next();
    // This completes the subject properly.
    this.ngUnsubscribe$.complete();
  }
}
