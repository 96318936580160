import {Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {UserService} from '../../core/user/services/user.service';
import {SubsequentDeliveriesRestService} from "../../core/deliveries/services/subsequent.deliveries.rest.service";
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {SubsequentDeliveriesRequest} from "../../core/deliveries/api/subsequent.deliveries.request";
import {UserRestService} from "../../core/user/services/user.rest.service";
import {SubsequentDeliveriesService} from "../../core/deliveries/services/subsequent.deliveries.service";
import {SubsequentDeliveryModel} from "../../core/deliveries/subsequest.delivery.model";
import {TableElement} from "../../core/deliveries/interfaces/table.element.interface";
import {FormBuilder, Validators} from "@angular/forms";
import {ConfirmationDialogModel} from "../../core/deliveries/models/confirmation.dialog.model";
import {ConfirmationDialogComponent} from "../../shared/confirmation-dialog/confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {SubsequentDeliveriesUpdateRequest} from "../../core/deliveries/api/subsequent.deliveries.update.request";
import {MatSort} from "@angular/material/sort";
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {CsvService} from "../../core/export/csv.service";
import {PdfService} from "../../core/export/pdf.service";
import {DatePipe} from '@angular/common';
import {Address} from "../../core/account/address.model";
import {AccountService} from "../../core/account/services/account.rest.service";
import {AddFormComponent} from "./add-form/add-form.component";
import {MatRipple} from '@angular/material/core';
import {Admingroup} from "../../core/user/models/admingroup.enum";

@Component({
  selector: 'app-subsequent-deliveries',
  templateUrl: './subsequent-deliveries.component.html',
  styleUrls: ['./subsequent-deliveries.component.scss']
})
export class SubsequentDeliveriesComponent implements OnDestroy, OnInit {
  displayedColumns: string[] = ['orderdate', 'articlecode', 'articlename', 'unit', 'quantity', 'delivery_info', 'actions'];
  displayData: TableElement[] = [];
  private ngUnsubscribe$ = new Subject<boolean>();
  showEdit: any[] = [];
  maxQuantityForm: any;
  loading = false;
  type = 'warn';
  content = 'Nachlieferungen werden nach 6 Monaten automatisch von uns gelöscht. Bitte bestellen Sie bei Bedarf erneut.';
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatTable) dataTable: MatTable<any> | undefined;
  @ViewChildren(MatRipple) matRipple :QueryList<MatRipple> | undefined;
  dataSource = new MatTableDataSource();
  selectedIdf: any = null;
  header = {
    orderdate: "Datum",
    articlecode: "PZN",
    articlename: "Artikelname",
    unit: "Einheit",
    quantity: "Offene Bestellmenge",
    delivery_information: "Info lt. Hersteller"
  };
  private addresses: Address[] = [];
  public canEdit = false;


  constructor(private fb: FormBuilder,
              private userService: UserService,
              private userRestService: UserRestService,
              private accountRestService: AccountService,
              private subsequentDeliveriesRestService: SubsequentDeliveriesRestService,
              private subsequentDeliveriesService: SubsequentDeliveriesService,
              private dialog: MatDialog,
              private _liveAnnouncer: LiveAnnouncer,
              private exportCsvService: CsvService,
              private exportPdfService: PdfService,
              private datePipe: DatePipe
  ) {
  }

  ngOnInit() {
    this.userService.getMainIdfObservable()?.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((response: any) => {
      if (response.return_object != undefined) {
        this.dataSource.sort = this.sort;
        this.loadDeliveries(response.return_object, true);
      }
    });

    this.userService.getUserResponseObservable().pipe(takeUntil(this.ngUnsubscribe$)).subscribe((response: any) => {
      const auths = response?.user.authoritiesEnum;
      this.canEdit = auths.indexOf("GROUP_ACCOUNT_OWNER") !== false || auths.indexOf("GROUP_SUBUSER") !== false;
    });
  }

  loadDeliveries(idf: string, reload: boolean = false, first?: string) {
    this.selectedIdf = idf;
    this.loading = !reload;

    this.subsequentDeliveriesRestService.loadSubsequentDeliveries(new SubsequentDeliveriesRequest({idf: idf})).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((data: any) => {
        const table = data.return_object;
        this.dataSource = new MatTableDataSource();
        if (table && table.length > 0) {
          const subsequent = new SubsequentDeliveryModel();
          this.displayData = subsequent.toTableData(data.return_object);
          let chosen: TableElement | undefined;
          if (first) {
            chosen = this.displayData.filter((delivery: TableElement) => {
              return delivery.delivery.id === first;
            })[0];
            this.displayData.splice(this.displayData.indexOf(<TableElement>chosen), 1);
          }

          this.dataSource.data = this.displayData;
          this.dataSource.sort = this.sort;
          if (chosen) {
            this.dataSource.data.unshift(chosen);
            this.loading = false;
              setTimeout(()=>{
                if(this.matRipple){
                  this.matRipple.get(0)?.launch({centered: true});
                }
              },1000);
          }
          this.loading = false;
        }
        this.loading = false;
      },
      (error: any) => {
        this.loading = false;
      });
  }

  delete(delivery: any) {
    const dialogData = new ConfirmationDialogModel('Produkt entfernen', 'Möchten Sie '
      + delivery.quantity + ' x '
      + delivery.articlename + ' wirklich von der Nachlieferungsliste löschen?');

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      closeOnNavigation: true,
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((dialogResult: number) => {
      if (dialogResult === 1) {
        this.subsequentDeliveriesService.delete(delivery).pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((s: any) => {
          if(s){
            this.loadDeliveries(delivery.idf, true);
          }
        });
      }
    });
  }

  showForm(qty: any) {
    this.maxQuantityForm = this.fb.group({
      maxQty: [qty, [Validators.required, Validators.min(0), Validators.max(qty), Validators.pattern('^[0-9]*$')]]
    });
  }

  update(delivery: SubsequentDeliveryModel) {
    const qty: any = this.maxQuantityForm.value.maxQty;
    if (qty !== '') {
      delivery.quantity = qty;
      const subsequentDeliveryRequest = new SubsequentDeliveriesUpdateRequest(delivery);
      this.subsequentDeliveriesRestService.updateSubsequentDelivery(subsequentDeliveryRequest)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((s: any) => {
        if (s.return_object) {
          delivery.quantity = qty;
          this.loadDeliveries(delivery.idf, true);
          this.showEdit[delivery.getPosition()] = false;
        }
        return s.return_object;
      });
    }
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(false);
    this.ngUnsubscribe$.complete();
  }

  downloadCsv() {
    if (this.dataSource.sort) {
      const sort = this.dataSource.sort;
      this.dataSource.sortData(this.dataSource.data, sort);
    }

    var values = this.prepareCsvData(this.header, this.dataSource.data);
    this.exportCsvService.exportToCsv(Object.values(this.header), values, 'Nachlieferungen_' + this.selectedIdf);

  }

  downloadPdf() {
    if (this.dataSource.sort) {
      const sort = this.dataSource.sort;
      this.dataSource.sortData(this.dataSource.data, sort);
    }
    const footer = {footerDesc: "Hinweis: Ihre Mengen kürzen oder Nachlieferungen löschen können Sie eigenständig über das Kundenportal. Nachlieferungen die älter sind als drei Monate werden automatisch vom System gelöscht. Bitte bestellen Sie bei Bedarf neu. \r\nFür Rückfragen stehen wir gerne zur Verfügung. \r\nHageda-Stumpf GmbH & Co. KG Kundenservicecenter"}

    var values = this.preparePdfData(this.header, this.dataSource.data);
    if (this.selectedIdf !== 9999910) {
      this.accountRestService.getShippingAddress(this.selectedIdf).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((response: any) => {
        this.exportPdfService.exportToPdf(Object.values(this.header), values, 'Nachlieferungen_' + this.selectedIdf, 'Nachlieferungen', Object.values(response.return_object), footer);
      });
    } else {
      this.exportPdfService.exportToPdf(Object.values(this.header), values, 'Nachlieferungen_' + this.selectedIdf, 'Nachlieferungen', null, footer);
    }

  }

  private preparePdfData(header: { [index: string]: any }, displayData: any[]) {
    var rows: any = [];
    var keys = Object.keys(header);

    for (var data of displayData) {
      var row = [];
      for (const key of keys) {
        let dt: { [index: string]: any } = data.delivery;
        if (key !== "orderdate" && key !== "delivery_information") {
          row.push(dt[key]);
        } else if (key === "orderdate"){
          row.push(this.datePipe.transform(new Date(dt[key]), "dd.MM.YYYY"));
        } else{
          row.push(dt[key].getDeliveryDate())
        }
      }
      rows.push(row);
    }
    return rows;
  }

  private prepareCsvData(header: { [index: string]: any }, displayData: any[]) {
    var rows: any = [];
    var keys = Object.keys(header);

    for (var data of displayData) {
      var row: { [index: string]: any } = {};
      for (const key of keys) {
        let dt: { [index: string]: any } = data.delivery;
        if (key !== "orderdate" && key !== "delivery_information") {
          row[header[key]] = dt[key];
        } else if (key === "orderdate"){
          row[header[key]] = this.datePipe.transform(new Date(dt[key]), "dd.MM.YYYY");
        } else  {
          row[header[key]] = dt[key].getDeliveryDate()
        }
      }
      rows.push(row);
    }
    return rows;
  }

  addNachlieferung() {
    const dialogData = {idf: this.selectedIdf};
    const dialogRef = this.dialog.open(AddFormComponent, {
      width: '600px',
      closeOnNavigation: true,
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      this.loadDeliveries(this.selectedIdf, false, result);
    });
  }
}
