<div class="p-l">
  <h4 mat-dialog-title class="pt-0 mt-0">
    Create Folder
  </h4>
  <div mat-dialog-content class="mb-l">
 <form [formGroup]="folderForm">
   <mat-form-field appearance="outline">
     <mat-label>New Folder</mat-label>
     <input matInput formControlName="folderName"/>
   </mat-form-field>
 </form>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-between">
    <button (click)="onNoClick()" mat-flat-button >Nein</button>
    <button mat-flat-button  color="primary" (click)="create()">Create Folder</button>
  </div>
</div>
