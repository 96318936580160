<form [formGroup]="dynamicForm" *ngIf="!loading" onsubmit="return false" class="mb-m">
    <div class="row">
        <ng-container *ngFor="let field of fieldsList?.fields_list;">
            <ng-container [ngSwitch]="field.type">
                <div *ngSwitchCase="'TEXTAREA'" class="col-md-{{field.col}}">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{field.label}}</mat-label>
                        <textarea matInput
                                  [formControlName]="'field'+field.id"></textarea>
                    </mat-form-field>
                </div>
                <div *ngSwitchCase="'RADIO'" class="col-md-{{field.col}} mb-m">
                    <h4 class="pb-0 mt-m">{{field.label}}</h4>
                    <mat-radio-group [formControlName]="'field'+field.id" [required]="field.required">
                        <mat-radio-button *ngFor="let option of field.options"
                                          [value]="option.value">{{option.text}}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngSwitchCase="'SELECT'" class="col-md-{{field.col}}">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{field.label}}</mat-label>
                        <mat-select tabindex="1"
                                    [formControlName]="'field'+field.id">
                            <mat-option *ngFor="let option of field.options"
                                        [value]="option.value">
                                {{option.text}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div appearance="outline" *ngSwitchCase="'CHECKBOX'" class="col-md-{{field.col}} my-0">
                    <mat-checkbox type="checkbox"
                                  [formControlName]="'field'+field.id"
                                  [required]="(field.required) ? 'required': false">{{field.label}}</mat-checkbox>
                    <mat-error *ngIf="getFormError('field'+field.id)">* Pflichtfeld</mat-error>
                </div>
                <div *ngSwitchCase="'INPUT'" class="col-md-{{field.col}}">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{field.label}}</mat-label>
                        <input matInput [formControlName]="'field'+field.id">
                    </mat-form-field>
                </div>
                <div *ngSwitchCase="'TEXT'" class="col-md-{{field.col}} mb-m">
                    <p [innerHTML]="field.label"></p>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="row">
        <div class="col-md-12">
            <button mat-flat-button color="primary" class="btn btn-primary mt-0" type="submit"
                    (click)="submit(); $event.stopPropagation()">{{fieldsList.submit_label}}</button>
        </div>
    </div>
</form>

<!--<notification *ngIf="successText" type="success" html="{{fieldsList.success_text}}" class="mt-m"></notification>-->
<!--<notification *ngIf="errorText" type="success" message="Ihre Anfrage konnte nicht gesendet werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut." class="mt-m"></notification>-->
