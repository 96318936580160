<a (click)="editing=false; selectedPage=''" *ngIf="editing" class="mt-l">< Züruck</a>
<mat-card *ngIf="error !== '' || success !== ''" class="mb-m p-m">
  <p *ngIf="success !== '' ">
    {{ success }}
  </p>
  <p *ngIf="error !== '' ">
    {{ error }}
  </p>
</mat-card>
<h1>Page Management</h1>
<button mat-flat-button color="primary" (click)="editing=true" *ngIf="!editing">New Page</button>
<div class="d-flex jcsb" *ngIf="!editing">

  <div *ngIf="dataSource.data.length > 0" class="w-100">
    <mat-table #files [dataSource]="dataSource">
      <ng-container matColumnDef="active">
        <mat-header-cell *matHeaderCellDef>Is Active</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.active }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="tile">
        <mat-header-cell *matHeaderCellDef>Tile</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.tile }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="page_title">
        <mat-header-cell *matHeaderCellDef>Page Title</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.page_title }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="relative_url">
        <mat-header-cell *matHeaderCellDef>Url</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.relative_url }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="public">
        <mat-header-cell *matHeaderCellDef> Is Public</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.public }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef class="text-center"> Aktionen</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button color="primary"
                  (click)="edit(element.relative_url)">
            <mat-icon fontSet="material-symbols-outlined" aria-hidden="false">edit</mat-icon>
          </button>
          <button mat-icon-button (click)="deletePage(element.relative_url, element.pageTitle)">
            <mat-icon fontSet="material-symbols-outlined" aria-hidden="false">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
</div>
<div *ngIf="editing">
  <app-page-form [page]="selectedPage"></app-page-form>
</div>

