import {Component} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {PublicContactRequest} from '../../core/contact/api/public.contact.request';
import {PublicContactRestService} from "../../core/contact/services/public.contact.rest.service";
import {ResponseModel} from "../../core/base/response.model";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  public contactForm = this.formBuilder.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    text: ['', Validators.required],
    subject: ['', Validators.required],
    idf: ['', Validators.required]
  });
  public success = false;

  constructor(
    private formBuilder: FormBuilder,
    private publicContactRestService: PublicContactRestService) {
  }

  submitMessage() {
    const request = new PublicContactRequest({...this.contactForm.value});
    this.publicContactRestService.makeContact(request).subscribe(
      (response:ResponseModel) => {
        if(!response.return_object){
          this.success = true;
        }
      },
      (err: any) => {

      }
    )
  }
}
