import { Inject, Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
// import {LogService} from './log.service';

@Injectable()
export abstract class RestServiceAbstract {

  constructor(private http: HttpClient,
              @Inject('API_URL') private API_URL: string,
              @Inject('CMS_URL') protected CMS_URL: string,
              @Inject('USERMANAGEMENT') protected USERMANAGEMENT: string,
              @Inject('RESOURCE') protected RESOURCE: string,
              @Inject('AZURE') protected AZURE: string
  ) {
  }

  /**
   * @param {string} relativeUrl
   * @param {any} params
   */
  public get(relativeUrl: string, params: any = {}): Observable<Object> {
    return this.http.get(this.API_URL + relativeUrl, params);
  }

  /**
   * @param {string} relativeUrl
   * @param {any} params
   */
  public getFromCms(relativeUrl: string, params: any = {}): Observable<Object> {
    return this.http.get(this.CMS_URL + relativeUrl, params);
  }

  /**
   * @param {string} relativeUrl
   * @param {Object} data
   * @return {Observable<Object>}
   */
  public post(relativeUrl: string, data: Object): Observable<Object>  {
    return this.http.post(this.API_URL + relativeUrl, data);
  }
  /**
   * @param {string} relativeUrl
   * @param {Object} data
   * @return {Observable<Blob>}
   */
  public postAndGetBlob(relativeUrl: string, data: Object): Observable<Blob> {
    return this.http.post<Blob>(this.API_URL + relativeUrl,
      data, { responseType: 'blob' as 'json' });
  }
  /**
   * @param {string} relativeUrl
   * @param {Object} data
   * @return {Observable<Object>}
   */
  public patch(relativeUrl: string, data: Object): Observable<Object>  {
    return this.http.patch(this.API_URL + relativeUrl, data);
  }
  /**
   * @param {string} relativeUrl
   * @param {Object} data
   * @return {Observable<Object>}
   */
  public delete(relativeUrl: string, data: Object): Observable<Object>  {
    return this.http.delete(this.API_URL + relativeUrl, data);
  }

  /**
   * @param {string} relativeUrl
   * @param {Object} data
   * @return {Observable<Object>}
   */
  public put(relativeUrl: string, data: Object): Observable<Object>  {
    return this.http.put(this.API_URL + relativeUrl, data);
  }
}
