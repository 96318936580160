import {Injectable} from '@angular/core';
import {RestServiceAbstract} from "../../base/rest.service.abstract";
import {map, takeUntil} from "rxjs/operators";
import {Observable, Subject} from "rxjs";
import {RegistrationRequest} from "../api/registration.request";
import {CredentialsTokenRequest} from "../api/credentials.token.request";
import {ResponseModel} from "../../base/response.model";

@Injectable({
  providedIn: 'root'
})
export class RegistrationRestService extends RestServiceAbstract {

  public ngUnsubscribe$: Subject<void> = new Subject<void>();

  public initRegistration(registrationRequest: RegistrationRequest): Observable<ResponseModel> {
    return this.post(`/${this.USERMANAGEMENT}/registration/init`, registrationRequest).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  public setCredentials(scr: CredentialsTokenRequest): Observable<ResponseModel> {
    return this.post(`/${this.USERMANAGEMENT}/registration/setCredentials`, scr).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  public confirm(token: string): Observable<ResponseModel> {
    return this.get(`/${this.USERMANAGEMENT}/registration/confirm`, {params: {token: token}}).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  public activate(token: string): Observable<ResponseModel> {
    return this.get(`/${this.USERMANAGEMENT}/registration/activationconfirm`, {params: {token: token}}).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  public changeData(data: any): Observable<ResponseModel> {
    return this.post(`/${this.USERMANAGEMENT}/registration/changeData`, data).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  public signUp(data: any): Observable<ResponseModel> {
    return this.post(`/${this.USERMANAGEMENT}/registration/signup`, data).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe$.next();
    // This completes the subject properly.
    this.ngUnsubscribe$.complete();
  }
}
