import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {SubsequentDeliveriesService} from "../../../core/deliveries/services/subsequent.deliveries.service";
import {ProductSearchService} from "../../../core/products/services/product.search.service";
import {FormBuilder, Validators} from "@angular/forms";
import {ProductBaseModel} from "../../../core/products/product.base.model";
import {OrderService} from "../../../core/sales/services/order.service";
import {DeliveryAvailabilityModel} from "../../../core/deliveries/models/delivery.availability.model";
import {UserService} from "../../../core/user/services/user.service";
import * as moment from 'moment';

@Component({
  selector: 'app-add-form',
  templateUrl: './add-form.component.html',
  styleUrls: ['./add-form.component.scss']
})
export class AddFormComponent implements OnDestroy, OnInit {
  private ngUnsubscribe$ = new Subject<any>();
  productForm = this.fb.group({
    search: ['', [Validators.required]],
    qty: ['', [Validators.required, Validators.pattern('^\\d+$')]]
  });
  products: ProductBaseModel[] = [];
  deliveryStatus: any = null;
  disabled: boolean = true;
  deliveryDate: any = null;
  loadingStatus: boolean = false;
  success: boolean = false;
  error: boolean = false;

  constructor(public dialogRef: MatDialogRef<AddFormComponent>,
              private fb: FormBuilder,
              public subsequentDeliveriesService: SubsequentDeliveriesService,
              public productSearchService: ProductSearchService,
              public userService: UserService,
              private orderService: OrderService,
              @Inject(MAT_DIALOG_DATA) public data: { idf: string },) {
  }

  ngOnInit() {
  }

  searchProducts($event: Event) {
    const search = this.productForm.get('search')?.value;

    if (search && search.length >= 3) {
      this.productSearchService.search(search, this.data.idf, 10).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((result: any) => {
        this.products = result;
      });
    }
  }

  checkDetails() {
    if (this.productForm.valid) {
      this.loadingStatus = true;
      const values = this.productForm.value;
      const pzn = values.search?.trim().split(' ')[0];
      if (pzn) {
        let request = new DeliveryAvailabilityModel({
          distribution_center_id: 21,
          idf: this.data.idf,
          deliveries: [{pzn: pzn, quantity: values.qty}]
        });
        this.subsequentDeliveriesService.checkAvailability(request).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((result: any) => {
          console.log(('00000000'+pzn).slice(-8));
          const deliveryInfo = result.return_object.delivery_information[('00000000'+pzn).slice(-8)];
          this.deliveryDate = null;
          this.deliveryStatus = deliveryInfo.deliverable ?
            ((deliveryInfo.deliveryFrom && !(parseInt(moment(deliveryInfo.deliveryFrom).format('YYYY')) > 2999)) ? null : 'NO_INFO') :
            (deliveryInfo.type === 'NORMAL' || deliveryInfo.type === 'VERBUND' ? 'NORMAL_ORDER' : 'NOT_DELIVERABLE');
          this.disabled = !deliveryInfo.deliverable;

          if (deliveryInfo.deliverable && !this.deliveryStatus) {
            this.deliveryDate = deliveryInfo.deliveryFrom;
          }
          this.loadingStatus = false;
        });
      }
    }
  }

  addNachlieferung($event: Event) {
    if (this.productForm.valid) {
      const values = this.productForm.value;
      const pzn = values.search?.trim().split(' ')[0];
      this.success = false;
      this.error = false;

      let request = new DeliveryAvailabilityModel({
        distribution_center_id: 21,
        idf: this.data.idf,
        deliveries: [{pzn: pzn, quantity: values.qty}]
      });
      this.subsequentDeliveriesService.addSubsequentDelivery(request).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((result: any) => {
        if (!result.error_code) {
          this.success = true;
          setTimeout(() => {
            this.success = false;
            const deliveryInfo = result.return_object.delivery_information;
            const msv3 = result.return_object.msv3Response;
            if (pzn) {
              const uuid = btoa(parseInt(pzn) + deliveryInfo[pzn].deliveryFrom + moment().format('YYYY-MM-DDT00:00:00'));
              this.dialogRef.close(uuid);
            }

          }, 3000);
        } else {
          this.error = true;
        }
      });
    }
  }

  setProduct(value: string) {
    this.productForm.patchValue({search: value});

    if (this.productForm.valid) {
      this.checkDetails();
    }
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(false);
    this.ngUnsubscribe$.complete();
  }

  onNoClick() {
    this.dialogRef.close();
  }

  getDate(deliveryDate: any) {
    const last = moment().endOf('year').format('YYYY-MM-DD');

    if (last === deliveryDate && moment(deliveryDate).day() === 7) {
      return moment(deliveryDate).add(1, 'day');
    }

    return deliveryDate;
  }
}
