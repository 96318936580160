import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpErrorResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {tap} from 'rxjs/operators';
import {AuthService} from "../auth/services/auth.service";
import {ActivatedRoute, Router} from '@angular/router';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService,
              private route:ActivatedRoute,
              private router: Router) {
  }

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {
    let token: string = this.authService.getToken();
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Access-Control-Allow-Headers', 'origin, content-type, accept, x-wsse, set-cookie, x-sessid');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Expose-Headers', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');

    if (token && token !== 'none') {
      headers = headers.append('Authorization', 'Bearer ' + token);
      const clonedRequest = req.clone({
        headers: headers
      });
      return next.handle(clonedRequest)
      .pipe(tap((event: HttpEvent<any>) => {
        /*if (event instanceof HttpResponse) {
          // do stuff with response if you want
        }*/
      }
      // ,
      //   (err: any) => {
      //   if (err instanceof HttpErrorResponse) {
      //     if (err.status === 401) {
      //       console.log('Unauthorized. Forward to login');
      //       this.authService.logout();
      //       // Redirect to the login route
      //       this.router.navigate(['/']);
      //     }
      //   }
      // }
      ));
    } else {
    }

    return next.handle(req);
  }


}
