import {Injectable, OnDestroy} from '@angular/core';
import {RestServiceAbstract} from "../../base/rest.service.abstract";
import {map, takeUntil} from "rxjs/operators";
import {Observable, Subject} from "rxjs";
import {ResponseModel} from "../../base/response.model";
import {CredentialsRequest} from "../api/credentials.request";
import {RenewCredentialsRequest} from "../api/renew.credentials.request";

@Injectable({
  providedIn: 'root'
})
export class PasswordRestService extends RestServiceAbstract implements OnDestroy{
  protected ngUnsubscribe$: Subject<void> = new Subject<void>();

  /**
   * @param {Object} r
   * @returns {Observable<ResponseModel>}
   */
  public initPasswordReset(r: Object): Observable<ResponseModel> {
    return this.post(`/${this.USERMANAGEMENT}/passwordReset/init`, r).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return new ResponseModel(data);
      })
    );
  }

  /**
   * @returns {Observable<ResponseModel>}
   */
  public setCredentials(scr: CredentialsRequest): Observable<ResponseModel> {
    return this.post(`/${this.USERMANAGEMENT}/passwordReset/setCredentials`, scr).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return new ResponseModel(data);
      })
    );
  }


  public setNewCredentials(scr: RenewCredentialsRequest): Observable<ResponseModel> {
    return this.post(`/${this.USERMANAGEMENT}/user/setCredentials`, scr).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return new ResponseModel(data);
      })
    );
  }


  /**
   * @returns{
   * @param {string} token
   * @returns {Observable<ResponseModel>}
   */
  public confirm(token: string): Observable<ResponseModel> {
    return this.get(`/${this.USERMANAGEMENT}/passwordReset/confirm?`, {params: {token: token}}).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return new ResponseModel(data);
      })
    );
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe$.next();
    // This completes the subject properly.
    this.ngUnsubscribe$.complete();
  }
}
