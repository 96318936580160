import {IdfBranches} from '../../account/models/idf.branches.model';
import {phx} from "../@types/models";
import {createReducer, on} from '@ngrx/store';
import * as UserActions from "../actions/user.actions";
import User = phx.portal.models.User;


export const initialState: User = {} as User;

export const userReducer = createReducer(
  initialState,
  on(UserActions.LOAD_USER, (state) => {
    return {
      ...state,
      userLoading: true
    }
  }),
  on(UserActions.LOAD_USER_COMPLETE, (state, data: any) => {
      return {
        ...state,
        ...data.payload,
        userLoading: false
      }
    }
  ),
  on(UserActions.LOAD_USER_FAIL, (state, data: any) => {
    return {
      ...state,
      ...data.payload,
      userLoading: false
    }
  }),
  on(UserActions.LOAD_NAV, (state) => {
    return {
      ...state,
      navLoading: false
    }
  }),
  on(UserActions.LOAD_NAV_COMPLETE, (state, data: any) => {
    return {
      ...state,
      ...data.payload,
      navLoading: false
    }
  }),
  on(UserActions.LOAD_NAV_FAIL, (state, data: any) => {
    return {
      ...state,
      ...data.payload,
      navLoading: false
    }
  }),
  on(UserActions.LOAD_USER_IDFS, (state) => {
    return {
      ...state,
      userIDFsLoading: true
    }
  }),
  on(UserActions.LOAD_USER_IDFS_COMPLETE, (state, data: any) => {
    return {
      ...state,
      ...data.payload,
      // mainIdf: mainIdf,
      userIDFsLoading: false
    }
  }),
  on(UserActions.LOAD_USER_IDFS_FAIL, (state, data: any) => {
    return {
      ...state,
      ...data.payload,
      userIDFsLoading: false
    }
  }),
  on(UserActions.LOAD_USER_MAIN_IDF, (state) => {
    return {
      ...state,
      mainIdfLoading: true
    }
  }),
  on(UserActions.LOAD_USER_MAIN_IDF_COMPLETE, (state, data: any) => {
    return {
      ...state,
      ...data.payload,
      mainIdfLoading: false
    }
  }),
  on(UserActions.LOAD_USER_MAIN_IDF_FAIL, (state, data: any) => {
    return {
      ...state,
      ...data.payload,
      mainIdfLoading: false
    }
  }),
  on(UserActions.LOAD_USER_VZS, (state) => {
    return {
      ...state,
      userVZsLoading: false
    }
  }),
  on(UserActions.LOAD_USER_VZS_COMPLETE, (state, data: any) => {
    const vz = new IdfBranches({idf: data.idf, branches: data.payload});
    if (!state.vzs) {
      const emptyVzs: any[] = [];
      return {
        ...state,
        userVZsLoading: false,
        vzs: [...emptyVzs, vz]
      };
    } else {
      return {
        ...state,
        userVZsLoading: false,
        vzs: [...state.vzs, vz]
      };
    }
  }),
  on(UserActions.LOAD_USER_VZS_FAIL, (state, data: any) => {
    return {
      ...state,
      ...data.payload,
      userIDFsLoading: false
    }
  }),
  on(UserActions.LOAD_USER_SA, (state) => {
    return {
      ...state,
      shippingAdressesLoading: true
    }
  }),
  on(UserActions.LOAD_USER_SA_COMPLETE, (state, data: any) => {
    return {
      ...state,
      ...data.payload,
      shippingAdressesLoading: false
    }
  }),
  on(UserActions.LOAD_USER_SA_FAIL, (state, data: any) => {
    return {
      ...state,
      ...data.payload,
      shippingAdressesLoading: false
    }
  }),
  on(UserActions.LOAD_USER_DISTRIBUTION_CENTER_ADDRESS, (state: any) => {
    return {
      ...state,
      mainDistributionCenterAdressLoading: true
    }
  }),
  on(UserActions.LOAD_USER_DISTRIBUTION_CENTER_ADDRESS_COMPLETE, (state: any, data: any) => {
    return {
      ...state,
      ...data.payload,
      mainDistributionCenterAdressLoading: false
    }
  }),
  on(UserActions.LOAD_USER_DISTRIBUTION_CENTER_ADDRESS_FAIL, (state: any, data: any) => {
    return {
      ...state,
      ...data.payload,
      mainDistributionCenterAdressLoading: false
    }
  }),
  on(UserActions.LOAD_ALL_USER_DISTRIBUTION_CENTER_ADDRESS, (state: any) => {
    return {
      ...state,
      allDistributionCenterAdressLoading: true
    }
  }),
  on(UserActions.LOAD_ALL_USER_DISTRIBUTION_CENTER_ADDRESS_COMPLETE, (state: any, data: any) => {
    return {
      ...state,
      ...data.payload,
      allDistributionCenterAdressLoading: false
    }
  }),
  on(UserActions.LOAD_ALL_USER_DISTRIBUTION_CENTER_ADDRESS_FAIL, (state: any, data: any) => {
    return {
      ...state,
      ...data.payload,
      allDistributionCenterAdressLoading: false
    }
  }),
  on(UserActions.LOAD_AUTH_TOKEN, (state) => {
    return {
      ...state,
      authTokenLoading: true
    }
  }),
  on(UserActions.LOAD_AUTH_TOKEN_COMPLETE, (state, data: any) => {
    return {
      ...state,
      ...data.payload,
      authTokenLoading: false
    }
  }),
  on(UserActions.LOAD_AUTH_TOKEN_FAIL, (state, data: any) => {
    return {
      ...state,
      ...data.payload,
      authTokenLoading: false
    }
  }),
  on(UserActions.LOAD_AUTH_TOKENS, (state) => {
    return {
      ...state,
      authTokensLoading: true
    }
  }),
  on(UserActions.LOAD_AUTH_TOKENS_COMPLETE, (state, data: any) => {
    return {
      ...state,
      ...data.payload,
      authTokensLoading: false
    }
  }),
  on(UserActions.LOAD_AUTH_TOKENS_FAIL, (state, data: any) => {
    return {
      ...state,
      ...data.payload,
      authTokensLoading: false
    }
  }),
  on(UserActions.LOGOUT, (state) => {
    state = initialState;
    return {
      ...state
    }
  }),
);

