<div  *ngIf="!showErrorMessage && !showSuccessMessage">
<h3>Fehlende Stammdaten</h3>
<p>
  Leider ist die Registrierung für diese IDF aktuell nicht möglich, da nicht alle notwendigen Stammdaten im System
  hinterlegt sind. Bitte füllen Sie das nachfolgende Formular aus. Wir prüfen Ihre Daten und setzen uns schnellstmöglich
  mit Ihnen in Verbindung.
</p>
</div>
<div>
  <form [formGroup]="missingDataForm"
        (submit)="onSubmit()"
        *ngIf="!showErrorMessage && !showSuccessMessage">
    <div class="row">
      <div class="col-md-6">
        <div>
          <mat-form-field appearance="outline">
            <mat-label>IDF-Nummer</mat-label>
            <input tabindex="4" matInput autocomplete="off" name="idf"
                   placeholder="IDF-Nummer z.B. 0123456"
                   formControlName="idf">
            <mat-error *ngIf="missingDataForm.get('idf')?.hasError('required')">Bitte geben Sie Ihre
              IDF-Nummer ein.
            </mat-error>
            <mat-error *ngIf="missingDataForm.get('idf')?.hasError('pattern')">Geben Sie eine 7-stellige
              Zahlenfolge ein.
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline">
            <mat-label>Apothekenname</mat-label>
            <input tabindex="5" matInput autocomplete="off" name="pharmacyName"
                   formControlName="pharmacyName">
            <mat-error *ngIf="missingDataForm.get('pharmacyName')?.hasError('required')">
              Bitte geben Sie Ihren Apothekename ein.
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline">
            <mat-label>Ort</mat-label>
            <input tabindex="6" matInput autocomplete="off" name="city"
                   formControlName="city">
            <mat-error *ngIf="missingDataForm.get('city')?.hasError('required')">
              Bitte geben Sie den Ort Ihrer Haupt-Apotheke ein.
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline">
            <mat-label>PLZ</mat-label>
            <input tabindex="7" matInput autocomplete="off" name="zip"
                   formControlName="zip">
            <mat-error *ngIf="missingDataForm.get('zip')?.hasError('required')">
              Bitte geben Sie die PLZ Ihrer Haupt-Apotheke ein.
            </mat-error>
            <mat-error *ngIf="missingDataForm.get('zip')?.hasError('pattern')">
              Bitte geben Sie eine gültige Postleitzahl ein.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Inhaber*in</mat-label>
          <input tabindex="8" matInput autocomplete="off" name="ownerName"
                 formControlName="ownerName">
          <mat-error *ngIf="missingDataForm.get('ownerName')?.hasError('required')">
            Bitte geben Sie Vorname und Nachname des Inhabers / der Inhaberin ein.
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Telefonnummer</mat-label>
          <input tabindex="9" matInput autocomplete="off" name="phone"
                 formControlName="phone">
          <mat-error *ngIf="missingDataForm.get('phone')?.hasError('required')">
            Bitte geben Sie eine gültige Telefonnummer ein.
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>E-Mail-Adresse</mat-label>
          <input tabindex="10" matInput autocomplete="off" name="email"
                 formControlName="email">
          <mat-error *ngIf="missingDataForm.get('email')?.hasError('required')">
            Bitte geben Sie Ihre E-Mail Adresse ein.
          </mat-error>
          <mat-error *ngIf="missingDataForm.get('email')?.hasError('email')">
            Bitte geben Sie eine gültige E-Mail-Adresse ein.
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Alternative Kontaktperson (optional)</mat-label>
          <input tabindex="11" matInput autocomplete="off" name="contactPerson"
                 formControlName="contactPerson">
        </mat-form-field>
      </div>
    </div>
    <div class="my-m">
      <button tabindex="13" mat-button type="button" routerLink="/register">
        <mat-icon fontSet="material-symbols-outlined" aria-hidden="false">arrow_back</mat-icon> Zurück zur Registrierung
      </button>

      <button tabindex="14" mat-button color="primary" type="button" [disabled]="!missingDataForm.valid" (click)="onSubmit()">
        Anfrage senden  <mat-icon fontSet="material-symbols-outlined" aria-hidden="false">arrow_forward</mat-icon>
      </button>
    </div>
  </form>
  <ng-container *ngIf="submitted && missingDataForm.valid">
    <div class="panel">
      <div class="text-center py-l">
        Ihre Eingabe wird verarbeitet.
      </div>

    </div>
  </ng-container>
</div>
<div *ngIf="showSuccessMessage">
  <h2>Vielen Dank für die Datenübermittlung.</h2>
      <p>
        Wir prüfen Ihre Angaben und setzen uns schnellstmöglich mit Ihnen in Verbindung. Ihr Hageda-Stumpf Apothekenportal
        Team!
      </p>
      <a href="/" class="link">Zurück zur Login</a>
</div>
<div *ngIf="showErrorMessage">
  Ihre Anfrage konnte nicht entgegengenommen werden.
  <h3>Das liegt möglicherweise an:</h3>
  <ul>
    <li>Einem Schreibfehler bei Eingabe der Haupt-IDF oder Postleitzahl</li>
    <li>Sie haben versucht, eine Filiale zu registrieren. Die Registrierung erfolgt über die Haupt-IDF,
      bitte wenden Sie sich an den/die Inhaber/in.
    </li>
    <li>Die Haupt-IDF ist eventuell bereits registriert. Bitte in diesem Fall die Funktion
      <a href='/#/zugangsdaten-vergessen' class="link">Zugangsdaten vergessen</a> nutzen.
    </li>
  </ul>
  <a href="/kontakt" class="link">Kontakt</a>
  <button class="btn btn-primary" routerLink="/registrierung">Registration</button>
</div>

