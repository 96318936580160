import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {UserAdminRestService} from '../../user/services/user.admin.rest.service';
import {
  DELETE_INTERNAL_USER, DELETE_INTERNAL_USER_COMPLETE, DELETE_INTERNAL_USER_FAIL,
  LOAD_INTERNAL_USERS,
  LOAD_INTERNAL_USERS_COMPLETE, LOAD_INTERNAL_USERS_FAIL,
  LOAD_USERS,
  LOAD_USERS_COMPLETE,
  LOAD_USERS_FAIL
} from '../actions/users.actions';
import {ResponseModel} from '../../base/response.model';
import {UsersReponseModel} from '../../user/api/users.reponse.model';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';

@Injectable()
export class UsersEffects {

  constructor(private actions: Actions,
              private userAdminRestService: UserAdminRestService) {
  }

  loadUsers$: Observable<Action> = createEffect(() => {
    return this.actions
    .pipe(ofType(LOAD_USERS))
    .pipe(map((action: any) => action),
      switchMap((action: any) =>
        this.userAdminRestService.loadUsers(action.payload)
        .pipe(map((data: ResponseModel) => ({type: LOAD_USERS_COMPLETE.type, payload: data.return_object})),
          catchError((error: any) => of({type: LOAD_USERS_FAIL.type, payload: error}))
        )
      )
    )
  });

  // loadUserDetails = createEffect(() => {
  //
  // });


  loadInternalUsers$: Observable<Action> = createEffect(() => {
    return this.actions
    .pipe(ofType(LOAD_INTERNAL_USERS))
    .pipe(map((action: any) => action),
      switchMap((action: any) =>
        this.userAdminRestService.listInternals(action.payload)
        .pipe(map((data: ResponseModel) => ({type: LOAD_INTERNAL_USERS_COMPLETE.type, payload: data.return_object})),
          catchError((error: any) => of({type: LOAD_INTERNAL_USERS_FAIL.type, payload: error}))
        )
      )
    )
  });

  deleteInternalUsers$: Observable<Action> = createEffect(() => {
    return this.actions
    .pipe(ofType(DELETE_INTERNAL_USER))
    .pipe(map((action: any) => action),
      switchMap((action: any) =>
        this.userAdminRestService.remove(action.payload)
        .pipe(map((data: ResponseModel) => ({type: DELETE_INTERNAL_USER_COMPLETE.type, payload: data.return_object})),
          catchError((error: any) => of({type: DELETE_INTERNAL_USER_FAIL.type, payload: error}))
        )
      )
    )
  });
}
