import * as fromSubUserActions from '../actions/subuser.actions';
import {Inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AuthService} from '../../auth/services/auth.service';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs';
import {UserRestService} from '../../user/services/user.rest.service';
import {SubUsersResponseModel} from '../../user/api/subusers.response.model';
import {SubUserRestService} from '../../user/services/subuser.rest.service';
import {ResponseModel} from "../../base/response.model";
import {
  INIT_AUTH_TREE,
  INIT_AUTH_TREE_COMPLETE,
  INIT_AUTH_TREE_FAILED,
  LOAD_SUB_USERS,
  LOAD_SUB_USERS_COMPLETE,
  LOAD_SUB_USERS_FAILED
} from "../actions/subuser.actions";

@Injectable()
export class SubUserEffects {

    /**
     * @type {Observable<any[]>}
     */
    loadSubUsers$: Observable<Action> = createEffect(()=> {return this.actions$
        .pipe(ofType(LOAD_SUB_USERS.type))
        .pipe(map(action => action), switchMap(() =>
                this.subUserRestService.loadSubUsers().pipe(
                    switchMap((data: ResponseModel) => [
                      {type: LOAD_SUB_USERS_COMPLETE.type, payload: new SubUsersResponseModel(data.return_object)}
                    ]),
                    catchError((error) => of({type: LOAD_SUB_USERS_FAILED.type, payload: error}))
                )
            )
        );
    });

    /**
     * @type {Observable<any[]>}
     */
    initAuthTree$: Observable<Action> = createEffect(()=> {
      return this.actions$
      .pipe(ofType(INIT_AUTH_TREE.type))
      .pipe(map(action => action), switchMap(() =>
          this.subUserRestService.requestAuthTree().pipe(
            switchMap((data: ResponseModel) => [
              {type: INIT_AUTH_TREE_COMPLETE.type, payload: data.return_object},
            ]),
            catchError((error) => of({type: INIT_AUTH_TREE_FAILED.type, payload: error}))
          )
        )
      );
    });

    /**
     * @param {Actions} actions$
     * @param subUserRestService
     * @param CMS_URL
     */
    constructor(
        private actions$: Actions,
        private subUserRestService: SubUserRestService,
        @Inject('CMS_URL') private CMS_URL: String
    ) {
    }
}
