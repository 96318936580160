import {RestServiceAbstract} from "../../base/rest.service.abstract";
import {map, takeUntil} from "rxjs/operators";
import {ResponseModel} from "../../base/response.model";
import {Subject} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class DialogRestService extends RestServiceAbstract {
  protected ngUnsubscribe$: Subject<void> = new Subject<void>();

  getDialogs(filter: string) {
    return this.get(`/${this.CMS_URL}/dialogs${filter}`)
    .pipe(takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  public subscribeToDialogOffer(id: string) {
    return this.post(`/${this.CMS_URL}/dialogs/${id}`, {subscribe: true}).pipe(map(data => {
      return new ResponseModel(data);
    }));
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe$.next();
    // This completes the subject properly.
    this.ngUnsubscribe$.complete();
  }
}
