import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {NotificationsTableElement} from "../../core/notifications/interfaces/notifications.table.element";
import {NotificationsService} from "../../core/notifications/services/notifications.service";
import {NotificationsModel} from "../../core/notifications/notifications.model";
import {MatDialog} from '@angular/material/dialog';
import {NotificationsFormComponent} from "./notifications-form/notifications-form.component";
import {MatRipple} from '@angular/material/core';
import {ConfirmationDialogModel} from "../../core/deliveries/models/confirmation.dialog.model";
import {ConfirmationDialogComponent} from "../../shared/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: 'app-notifications-management',
  templateUrl: './notifications-management.component.html',
  styleUrls: ['./notifications-management.component.scss']
})
export class NotificationsManagementComponent implements OnInit {
  displayedColumns: string[] = ['orderdate', 'articleno', 'articlename', 'unit', 'quantity', 'actions'];
  displayData: NotificationsTableElement[] = [];
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatTable) dataTable: MatTable<any> | undefined;
  dataSource = new MatTableDataSource();
  loading = true;
  @ViewChildren(MatRipple) matRipple :QueryList<MatRipple> | undefined;

  constructor(private notificationsService: NotificationsService,
              private dialog: MatDialog) {

  }

  ngOnInit() {
    this.downloadNotifications();
  }

  downloadNotifications() {
    this.notificationsService.downloadList(true).then(value => {
      if(value) {
        const notification = new NotificationsModel();
        this.dataSource.data = notification.toTableData(value);
        this.dataSource.sort = this.sort;

        this.loading = false;
      }
    });
  }

  delete(element: NotificationsModel) {
    const dialogData = new ConfirmationDialogModel('Notification entfernen', 'Möchten Sie '
      + element.title + ' wirklich von der Nachlieferungsliste löschen?');

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      closeOnNavigation: true,
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((result:number) => {
      if(result) {
        this.notificationsService.deleteNotification(element).then((res: any) => {
          console.log(res);
          this.downloadNotifications()
        });
      }
    })
  }

  edit(element: NotificationsModel) {
    const dialogRef = this.dialog.open(NotificationsFormComponent, {
      width: '50vw',
      closeOnNavigation: true,
      data: element
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      this.downloadNotifications();
    });
  }

  addNotification() {
    const dialogRef = this.dialog.open(NotificationsFormComponent, {
      width: '50vw',
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      this.downloadNotifications();
    });
  }
}
