import {Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {UserAdminService} from "../../core/user/services/user.admin.service";
import {MatRipple} from '@angular/material/core';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {UserModel} from "../../core/user/user.model";
import {MatPaginator} from '@angular/material/paginator';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';
import {AccountService} from "../../core/account/services/account.rest.service";
import {CsvService} from "../../core/export/csv.service";
import {Usergroup} from "../../core/user/models/usergroup.enum";
import {shareReplay, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-management',
  styleUrls: ['./user-management.component.scss'],
  templateUrl: './user-management.component.html'
})
export class UserManagementComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['status', 'name', 'username', 'email', 'group', 'idfs', 'actions'];
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatTable) dataTable: MatTable<any> | undefined;
  @ViewChildren(MatRipple) matRipple: QueryList<MatRipple> | undefined;
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;


  users: any [] = [];
  dataSource = new MatTableDataSource();
  page = 0;
  size = 25;
  filter = '';
  total = 0;
  notification = {html: '', type: ''};
  private ngUnsubscribe$ = new Subject<void>();
  public showNotification = false;
  editUser = false;
  selectedUser: any = {};
  accounts: any[] = [];
  public userGroups = Usergroup;


  constructor(public userService: UserAdminService,
              private accountService: AccountService,
              private csvService: CsvService,
              private router: Router,
              private datePipe: DatePipe,
              private translate: TranslateService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  ngOnInit() {
    this.editUser = false;
    this.loadUsersList(this.page, this.size);
    this.accountService.getAllAccounts().pipe(takeUntil(this.ngUnsubscribe$)).subscribe((accounts: any)=> {
      this.accounts = accounts.return_object.content;
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  applyFilter($event: any) {
    this.filter = $event.currentTarget.value;
    this.loadUsersList(this.page, this.size, $event.currentTarget.value);
  }


  loadUsersList(page: number, size: number, search?: string) {
    let request: any = {page: page, size: size};
    if (search) {
      request['s'] = search;
    }

    this.userService.loadUsers(request).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((usersResponse: any) => {
      this.users = usersResponse.result;
      this.total = usersResponse.total;
      if (this.users) {
        const user = new UserModel({});
        this.dataSource.data = user.toTableData(this.users);
      }
    })
  }

  getUsers(page: any) {
    this.page = page.pageIndex;
    this.size = page.pageSize;

    this.loadUsersList(this.page, this.size, this.filter);
  }

  notify() {
    this.showNotification = true;
    setTimeout(() => {
      this.showNotification = false;
    }, 4000);
  }

  edit(element: any) {
    this.editUser = true;
    this.selectedUser = element;
  }

  export($event: any) {
    $event.preventDefault();
    $event.stopPropagation();

    let exported = false;

    this.userService.loadUsers({page: 0, size: 10000}).pipe(takeUntil(this.ngUnsubscribe$), shareReplay(1)).subscribe((usersResponse: any) => {
      const users = usersResponse.result;
      if (users && !exported) {
        const user = new UserModel({});
        let data: any = [];

        for(let u of users) {
          const usr = new UserModel(u);
          const accounts: any[] = Object.assign([],this.accounts);
          usr['accounts'] = accounts.filter(account => u.idfs.indexOf(account.idf) > -1);

          const exportData: any[] = user.toExportData(usr);

          for (const exp of exportData) {
            data.push(exp);
          }
        }
        this.downloadCsv(data);
        exported = true;
      }
    })
  }

  downloadCsv(data: any) {
    const today = new Date();
    const header = Object.keys(data[0]);
    var values = this.prepareCsvData(data);

    this.csvService.exportToCsv(header, values, 'hageda-benutzer-' + this.datePipe.transform(today, "YYYY-MM-dd"));
  }

  private prepareCsvData(displayData: any[]) {
    var rows: any = [];

    for (var data of displayData) {
      // @ts-ignore
      data.group = this.userGroups[data.group];
      data.activated = data.activated.length > 0 ? this.datePipe.transform(new Date(data.activated), "dd.MM.YYYY"): "";
      data.created = data.created.length > 0 ? this.datePipe.transform(new Date(data.created), "dd.MM.YYYY"): "";
      data.status = this.translate.instant('USER.'+data.status);
      rows.push(data);
    }
    return rows;
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
