import {Component, Inject, Input} from '@angular/core';
import {RegistrationRestService} from "../../../core/auth/services/registration.rest.service";
import {Router} from "@angular/router";
import {AccountInfoModel} from "../../../core/account/models/account.info.model";
import {LoginRequest} from "../../../core/auth/api/login.request";
import {AuthService} from "../../../core/auth/services/auth.service";
import {FirstLoginService} from "../../../core/auth/services/first.login.service";
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent {

  loading = true;
  @Input() token: string = '';
  accountInfo: AccountInfoModel = new AccountInfoModel();
  finish = false;
  private username: string = '';
  private loginRequest: LoginRequest = new LoginRequest();
  error = false;
  correction = false;
  corrected = false;

  constructor(@Inject(RegistrationRestService) public registrationRestService: RegistrationRestService,
              private authService: AuthService,
              private firstLogin: FirstLoginService,
              public router: Router) {
  }

  ngOnInit() {
    this.loading = true;
    this.registrationRestService.confirm(this.token).subscribe(
      response => {
        this.accountInfo = new AccountInfoModel(response.return_object);
        this.loading = false;
      },
      err => {
        // Do stuff whith your error
        if (err.error && err.error.errorCode) {
          this.router.navigate(['kontakt'], {queryParams: {error: JSON.stringify(err)}});
        }
      },
      () => {
        // Do stuff after completion
      },
    );
  }

  correct() {
    this.correction = true;
  }

  onSuccess(success: any) {
    if(success.hasOwnProperty('response')){
      this.loginRequest = new LoginRequest({username: success.response.username, password: success.password});
    }
    else{
      this.loginRequest = new LoginRequest({username: success.username, password: success.confirmPassword});
    }
    this.finish = true;
  }

  login() {
    this.authService.login(this.loginRequest).subscribe(
      data => {
        this.firstLogin.showDialog = true;
        this.router.navigateByUrl('dashboard');
      },
      error => {
        this.error = error;
        this.loading = false;
      });
  }

  checked($event: any, stepper: MatStepper) {
    if ($event === 'back') {
      this.correction = false;
      this.corrected = false;
    } else {
      this.corrected = true;
      this.correction = false;
    }
    stepper.next();
  }
}
