<h1>Vorbestellung Archiv</h1>
<app-idf-select (selectedIdf)="getPreordersArchive($event)"></app-idf-select>
<p>
  Hier können Sie die Bestelleingangsbestätigungen Ihrer Grippeimpfstoff Bestellungen einsehen.
</p>

<mat-accordion>
  <mat-expansion-panel *ngFor="let archive of preordersArchive">
    <mat-expansion-panel-header>
      <mat-panel-title class="title red">
        {{ archive.title }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <table mat-table [dataSource]="archive.items">
      <ng-container matColumnDef="vendor">
        <th mat-header-cell *matHeaderCellDef>Hersteller</th>
        <td mat-cell *matCellDef="let element"> {{ element.vendor }}</td>
      </ng-container>
      <ng-container matColumnDef="idf">
        <th mat-header-cell *matHeaderCellDef>IDF</th>
        <td mat-cell *matCellDef="let element"> {{ element.idf }}</td>
      </ng-container>
      <ng-container matColumnDef="period_start">
        <th mat-header-cell *matHeaderCellDef>Vorbestellstart</th>
        <td mat-cell
            *matCellDef="let element"> {{ element.pre_order_period_start | date: 'shortDate' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="period_end">
        <th mat-header-cell *matHeaderCellDef>Vorbestellende</th>
        <td mat-cell
            *matCellDef="let element"> {{ element.pre_order_period_end | date: 'shortDate' }}
        </td>
      </ng-container>
<!--      <ng-container matColumnDef="delivery_from">-->
<!--        <th mat-header-cell *matHeaderCellDef>Lieferstatus</th>-->
<!--        <td mat-cell *matCellDef="let element"> {{ element.delivered_from }}</td>-->
<!--      </ng-container>-->
      <ng-container matColumnDef="download">
        <th mat-header-cell *matHeaderCellDef>Download</th>
        <td mat-cell *matCellDef="let element">
          <button mat-mini-fab color="primary"
                  (click)="getPreorder(element)">
            <mat-icon fontSet="material-symbols-outlined"
                      aria-hidden="false">download
            </mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-expansion-panel>
</mat-accordion>
