import {Injectable} from '@angular/core';
import {RestServiceAbstract} from '../../base/rest.service.abstract';
import {Subject, throwError} from 'rxjs';
import {catchError, map, takeUntil} from 'rxjs/operators';
import {ResponseModel} from '../../base/response.model';
import {Observable} from 'rxjs';
import {OwnerModel} from "../owner.model";

@Injectable({
  providedIn: 'root',
})
export class AccountService extends RestServiceAbstract {

  public ngUnsubscribe$: Subject<void> = new Subject<void>();

  public loadAllAccountdata() {
    return this.get(`/${this.USERMANAGEMENT}/idf/allAccounts`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  public getShippingAddress(idf: string) {
    return this.get(`/${this.USERMANAGEMENT}/idf/${idf}/shipping-address`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  changeData(scr: any): Observable<ResponseModel> {
    return this.post(`/${this.USERMANAGEMENT}/idf/changeData`, scr).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    );
  }

  changeOwner(owner: any): Observable<ResponseModel> {
    return this.post(`/${this.USERMANAGEMENT}/idf/changeOwner`, owner).pipe(
      takeUntil(this.ngUnsubscribe$),
      map(data => {
        return new ResponseModel(data);
      })
    )
  }

  getAccountsData(idfs: string): Observable<ResponseModel> {
    return this.get(`/${this.USERMANAGEMENT}/admin/accounts/account-data`, {idfs: idfs}).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return new ResponseModel(data);
      })
    )
  }

  getAllAccounts(): Observable<ResponseModel> {
    return this.get(`/${this.USERMANAGEMENT}/admin/accounts`, {params: {size: 10000}}).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return new ResponseModel(data);
      })
    )
  }
}
