import {Component, Inject, OnInit, OnDestroy} from '@angular/core';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {NestedTreeControl} from '@angular/cdk/tree';
import {FormBuilder, Validators} from '@angular/forms';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {FileNode} from "../../../core/cms/interfaces/file.node.interface";
import {BlobService} from "../../../core/base/blob.service";
import {UserService} from "../../../core/user/services/user.service";
import {UserResponse} from "../../../core/user/api/user.response.model";
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {NewContainerComponent} from "./new-container/new-container.component";

@Component({
  selector: 'app-files-management',
  templateUrl: './files-management.component.html',
  styleUrl: './files-management.component.scss'
})
export class FilesManagementComponent implements OnInit, OnDestroy {
  filesForm = this.formBuilder.group({
    file: ['']
  });
  private ngUnsubscribe$ = new Subject<void>();

  treeControl = new NestedTreeControl<FileNode>((node: FileNode) => node.children);
  dataSource = new MatTreeNestedDataSource<FileNode>();
  folderStructure: FileNode[] = [];
  selectedFolder: any;
  selectedContainer: any;
  user: any;
  selectedNode: any;

  constructor(private formBuilder: FormBuilder,
              private dialog: MatDialog,
              @Inject('STORAGE') private STORAGE: string,
              private userService: UserService,
              private blobService: BlobService) {

    this.userService.getUserResponseObservable().subscribe((userResponse: UserResponse) => {
      this.user = userResponse?.user;
    })
  }

  ngOnInit() {
    this.listContainers();
  }

  async listContainers() {
    const containers = await this.blobService.listContainers();
    this.folderStructure = [];

    for await (const item of containers) {
      if (item.name !== '$web' && item.name !== '$logs') {
        const children = this.listFolders(item.name);
        this.folderStructure.push({
          name: item.name,
          properties: {...{container: item.name, folder: null}, ...item.properties, ...item.metadata},
          children: await children
        });
      }
    }
    this.dataSource.data = [];
    this.dataSource.data = this.folderStructure;
  }

  async listFolders(container: string) {
    const foldersList = await this.blobService.folderList(container);
    const folders: any[] = [];
    for await (const item of foldersList) {
      if (item.kind === 'prefix') {
        folders.push({
          name: item.name.replace('/', '').trim(),
          properties: {...{container: container, folder: item.name}},
          children: await this.listSubFolders(container, item.name)
        });
      }
    }
    return folders;
  }

  async listSubFolders(container: string, prefix: string) {

    const foldersList = await this.blobService.folderSublist(container, prefix);
    const folders: any[] = [];
    for await (const item of foldersList) {
      if (item.kind === 'prefix') {
        folders.push({
          name: item.name.replace('/', '').trim(),
          properties: {...{container: container, folder: item.name}},
          children: await this.listSubFolders(container, item.name)
        });
      }
    }
    return folders;
  }

  hasNestedChild = (_: number, node: FileNode) => !!node.children && node.children.length > 0;


  addContainer() {

  }

  expandContainer() {
    setTimeout(() => {
      if (!this.treeControl.isExpanded(this.selectedNode)) this.treeControl.expand(this.selectedNode);
    }, 1000)
  }


  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
