import {Component, OnDestroy, OnInit} from '@angular/core';
import {SubUserService} from "../../../core/user/services/subuser.service";
import {takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {SubUserModel} from "../../../core/user/subuser.model";
import {AuthorizationNodeModel} from "../../../core/user/models/authorization.node.model";
import {SubUserModelRepository} from "../../../core/user/models/subuser.repository";
import {UserService} from "../../../core/user/services/user.service";
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DialogComponent} from "../../../shared/dialog/dialog.component";
import {DialogTextModel} from "../../../core/cms/models/dialog.text.model";
import {SubUserRestService} from "../../../core/user/services/subuser.rest.service";
import {MatTableDataSource, MatTable} from "@angular/material/table";
import {SubuserTableElementInterface} from "../../../core/user/interfaces/subuser.table.element.interface";
import {Router} from '@angular/router';


@Component({
  selector: 'app-subusers',
  templateUrl: './subusers.component.html',
  styleUrls: ['./subusers.component.scss']
})
export class SubusersComponent implements OnInit,OnDestroy {
  public subusers: any[] = [];
  private ngUnsubscribe$ = new Subject<void>();
  public showForm = false;
  public subuser: SubUserModel = new SubUserModel({});
  public subUser$: any;
  public authTree: AuthorizationNodeModel[] = [];
  public subUsers$: Observable<SubUserModelRepository[]>;
  public authTreeLoading$: Observable<boolean>;
  public subUsersLoading$: Observable<boolean>;
  public idfs$: any;
  public allIdfs: any[] = [];
  public notification: any;
  public showNotification = false;
  displayedColumns: string[] = ['subuser', 'authorities', 'actions'];
  dataSource = new MatTableDataSource();
  subs: SubuserTableElementInterface[] = [];


  constructor(private subUserService: SubUserService,
              private subUserRestService: SubUserRestService,
              public userService: UserService,
              private router: Router,
              public dialog: MatDialog) {

    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };

    subUserService.selectSubUsersObservable().pipe(takeUntil(this.ngUnsubscribe$)).subscribe((subs: any) => {
      if (subs) {
        this.subusers = subs;
      }
    })

    this.subUsers$ = subUserService.selectSubUsersObservable();
    this.subUserService.selectInitAuthTree().subscribe((authTree: any) => this.setAuthTree(authTree));
    this.idfs$ = userService.loadAllIdfs();
    this.idfs$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((result: any) => {
      if (result !== null) {
        this.allIdfs = result;
      }
    });
    this.authTreeLoading$ = subUserService.selectAuthTreeLoading();
    this.subUsersLoading$ = subUserService.selectSubUsersLoadingObservable();
  }

  ngOnInit(){
    this.subUserService.selectSubUsersObservable().pipe(takeUntil(this.ngUnsubscribe$)).subscribe((subs: any) => {
      if (subs) {
        this.subusers = subs;
        this.subs = subs.map((sub: SubUserModel) => {
          const details = [];
          if(sub.salutation) details.push(sub.salutation);
          if(sub.title) details.push(sub.title);
          if(sub.first_name) details.push(sub.first_name);
          if(sub.last_name) details.push(sub.last_name);
          const name: string = details.join(' ');
          const idfs = this.getAuthorizedIdfs(sub);
          let authorized: string[] = [];
          if(idfs.length > 0) {
            idfs.map((idf: any) => {
              if(idf) {
                authorized.push(idf.idf + ' ' + idf.name);
              }
            })
          }

          return {name: name, idfs: authorized, actions: sub }
        });
        this.dataSource.data = this.subs;
        console.log(this.subs);
      }
    })
  }

  public setAuthTree(authTree: AuthorizationNodeModel[]): void {
    this.authTree = authTree;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  cancelSubUserForm() {
    this.subUsers$ = this.subUserService.selectSubUsersObservable();

    this.subUser$ = new SubUserModel({});
    this.showForm = false;
  }

  notify() {
    this.showNotification = true;
    setTimeout(() => {
      this.showNotification = false;
    }, 4000);
  }

  edit(subuser: any) {
    this.subUser$ = new SubUserModel(subuser);
    this.showForm = true;
  }

  remove(subuser: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = new DialogTextModel({
      title: 'Mitbenutzer löschen',
      content: 'Möchten Sie <b>' + subuser.username + '</b> als Mitbenutzer löschen?',
      type: 'confirm'
    })

    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
      if (result) {
        this.notification = {
          html: 'Der Benutzer <b>' + subuser.first_name + ' ' + subuser.last_name + '</b> wird gelöscht.',
          type: 'info'
        };
        this.notify();
        this.subUserRestService.remove(subuser).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(res => {
          this.subUserService.dispatchLoadSubUsersAction();
          this.notification = {
            html: 'Der Benutzer <b>' + subuser.first_name + ' ' + subuser.last_name + '</b> konnte erfolgreich gelöscht werden.',
            type: 'success'
          };
          this.notify();
        });
      }
    });
  }

  getAuthorizedIdfs(subuser: any) {
    let idfsArray = [];
    const subUserIdfs = Object.keys(subuser.flat_idf_authorities);
    if (this.allIdfs) {
      idfsArray = this.allIdfs.map(item => {
        if (subUserIdfs.includes(item.idf)) {
          return item;
        }
      });
    }
    return idfsArray;
  }

  getFormattedIdfs(authorizedIdfs: any) {
    return authorizedIdfs.map((idf: any) => (idf !== undefined) ? idf.idf + ' (' + idf.name + ')' : null).join(', ');
  }
}
