import {catchError, map, takeUntil} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {RestServiceAbstract} from "../../base/rest.service.abstract";
import {ResponseModel} from "../../base/response.model";
import {Observable, Subject} from 'rxjs';
import {UserModel} from "../user.model";

@Injectable({
  providedIn: 'root'
})
export class UserAdminRestService extends RestServiceAbstract implements OnDestroy {
  public ngUnsubscribe$: Subject<void> = new Subject<void>();

  loadUsers (rq: any) {
    return this.get(`/${this.USERMANAGEMENT}/admin/user/search`, {params: rq}).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return new ResponseModel(data);
      })
    );
  }


  public remove(sum: UserModel): Observable<ResponseModel> {
    return this.delete(`/${this.USERMANAGEMENT}/admin/user/${sum.id}`, sum).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return new ResponseModel(data);
      })
    );
  }

  public update(user: any): Observable<ResponseModel> {
    return this.patch(`/${this.USERMANAGEMENT}/admin/user/${user.id}`, user).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return new ResponseModel(data);
      })
    )
  }

  getAuthorities(id: any) {
    return this.get(`/${this.USERMANAGEMENT}/admin/user/${id}/authorities`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return new ResponseModel(data);
      })
    )
  }

  getAllCmsAuthorities(){
    return this.get(`/${this.USERMANAGEMENT}/authority`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return new ResponseModel(data);
      })
    );
  }
  getAllAuthorities(){
    return this.get(`/${this.USERMANAGEMENT}/authority/cms`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return new ResponseModel(data);
      })
    );
  }


  listInternals(request: any) {
    return this.get(`/${this.USERMANAGEMENT}/admin/user/listInternalUsers`, {params: request}).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return new ResponseModel(data);
      })
    )
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  create(values: any) {
    return this.post(`/${this.USERMANAGEMENT}/admin/user/create`, values).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return new ResponseModel(data);
      })
    )
  }


}
