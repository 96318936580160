import {createAction, props} from '@ngrx/store';
import {ProductRequest} from "../../products/api/product.request.model";

export const INIT_PRODUCT_SEARCH = createAction('[PRODUCT SEARCH] Init Search');
export const LOAD_PRODUCT_SEARCH = createAction('[PRODUCT SEARCH] Search', props<{ payload: ProductRequest }>());
export const LOAD_PRODUCT_SEARCH_COMPLETED = createAction('[PRODUCT SEARCH] Search Completed');
export const LOAD_PRODUCT_SEARCH_FAILED = createAction('[PRODUCT SEARCH] Search Failed');
export const LOAD_PRODUCT_SHOP = createAction('[PRODUCT SHOP] Search', props<{ payload: ProductRequest }>());
export const LOAD_PRODUCT_SHOP_COMPLETED = createAction('[PRODUCT SHOP] Search Completed');
export const LOAD_PRODUCT_SHOP_FAILED = createAction('[PRODUCT SHOP] Search Failed');
export const LOAD_PRODUCT = createAction('[PRODUCT] Load', props<{ payload: string }>());
export const LOAD_PRODUCT_COMPLETED = createAction('[PRODUCT] Load Completed');
export const LOAD_PRODUCT_FAILED = createAction('[PRODUCT] Load Failed');
