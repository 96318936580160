import { Component , OnInit} from '@angular/core';
import {UserService} from "../core/user/services/user.service";
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss']
})
export class PrivateComponent implements OnInit {
  constructor(private userService: UserService,
              public translate: TranslateService) {
  }

  ngOnInit(){
    this.userService.loadUser();

  }
}
