<h1 class="title">Admin Dashboard</h1>
<div class="tile-grid mt-m">
  <mat-card class="dashboard-card tile" matRipple routerLink="/admin/notifications-management" >
    <mat-card-header class="jcc">
      <mat-card-title class="text-uppercase subheader">Notifications</mat-card-title>
    </mat-card-header>
    <mat-card-content class="d-flex text-large jcc my-m">
      Create and edit notifications on the site
    </mat-card-content>
  </mat-card>
  <mat-card class="dashboard-card tile" matRipple routerLink="/admin/user-management" >
    <mat-card-header class="jcc">
      <mat-card-title class="text-uppercase subheader">Benutzer</mat-card-title>
    </mat-card-header>
    <mat-card-content class="d-flex text-large jcc my-m">
      Pharmacy user management
    </mat-card-content>
  </mat-card>
  <mat-card class="dashboard-card tile" matRipple routerLink="/admin/internal-management" >
    <mat-card-header class="jcc">
      <mat-card-title class="text-uppercase subheader">Mitarbeitern</mat-card-title>
    </mat-card-header>
    <mat-card-content class="d-flex text-large jcc my-m">
      HAGEDA user management
    </mat-card-content>
  </mat-card>
  <mat-card class="dashboard-card tile" matRipple routerLink="/admin/cms-management" >
    <mat-card-header class="jcc">
      <mat-card-title class="text-uppercase subheader">CMS Management</mat-card-title>
    </mat-card-header>
    <mat-card-content class="d-flex text-large jcc my-m">
      Edit content from pages, popups, forms and upload files
    </mat-card-content>
  </mat-card>
</div>
