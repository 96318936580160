import {Manufacturer} from "./models/manufacturer.model";

export class ProductBaseModel {
  displayName: string;
  aep: string;
  vat: number;
  content: string;
  rx = false;
  otc = false;
  btm = false;
  form: string;
  minOrder: number;
  chargenartikel = false;
  country: string;
  manufacturer: Manufacturer;
  name: string;
  pzn: string;
  delivery_from: string;

  constructor(obj: any = {}) {
    this.name = obj && obj.name || null;
    this.manufacturer = obj && obj.manufacturer || null;
    this.pzn = obj && obj.pzn || obj && obj.id || null;
    this.delivery_from = obj && obj.pzn || null;
    this.country = obj && obj.country || null;
    this.chargenartikel = obj && obj.chargenartikel || false;
    this.otc = obj && obj.otc || false;
    this.rx = obj && obj.rx || false;
    this.btm = obj && obj.btm || false;
    this.minOrder = obj && obj.minOrder || 0;
    this.form = obj && obj.form || null;
    this.content = obj && obj.content || null;
    this.aep = obj && obj.aep || 0;
    this.vat = obj && obj.vat || 19;
    this.displayName = obj && obj.displayName || null;
  }
}
