import {Component, Inject} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BlobService} from "../../../../core/base/blob.service";

@Component({
  selector: 'app-new-folder',
  templateUrl: './new-folder.component.html',
  styleUrl: './new-folder.component.scss'
})
export class NewFolderComponent {

  folderForm = this.formBuilder.group({
    folderName: ['', Validators.required]
  })
  constructor(private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<NewFolderComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private blobService: BlobService) {}


  onNoClick(): void {
    this.dialogRef.close();
  }

  create() {
    const folderName = this.folderForm.value;

    let newFolder = [folderName.folderName, 'empty.txt'].join('/');
    if(this.data.folder) {
      newFolder = this.data.folder + newFolder;
    }

    const blob = new File([''], 'empty.txt',{type: 'text/plain'});
    const ret = this.blobService.uploadFile(blob, newFolder, this.data.container, {modified_by: this.getUserDetails(this.data.user)});

    ret.then(r => {
      this.dialogRef.close({error: r.error_code, filename: newFolder.replace('/empty.txt','').trim()});
    });
  }

  private getUserDetails(user: any) {
    return user.name + ' (' + user.username + ')'
  }
}
