export class DialogTextModel{
  public content: string;
  public title: string;
  public type: string;
  public ok : string
  public body : string
  id: string;
  icon: string;

  constructor(obj:any) {
    this.content = obj && obj.content || null;
    this.title = obj && obj.title || null;
    this.type = obj && obj.type || null;
    this.ok = obj && obj.ok || 'Ja';
    this.body = obj && obj.body || null;
    this.id = obj && obj.id || null;
    this.icon = obj && obj.icon || null;
  }
}
