import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from "./shared/page-not-found/page-not-found.component";
import {authGuard} from "./core/auth/auth.guard";

const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule),
  },
  {
    path: 'app',
    loadChildren: () => import('./private/private.module').then(m => m.PrivateModule), canMatch: [authGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.section.module').then(m => m.AdminSectionModule), canMatch: [authGuard]
  },
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
