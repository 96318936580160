import { Component, Input, OnInit } from '@angular/core';
import {UserService} from "../../../core/user/services/user.service";
import {FormBuilder, Validators} from "@angular/forms";
import {Salutation} from "../../../core/user/models/salutation.enum";
import {KeyValue} from '@angular/common';
import {Usergroup} from "../../../core/user/models/usergroup.enum";
import {Status} from "../../../core/user/models/status.enum";
import {UserAdminRestService} from "../../../core/user/services/user.admin.rest.service";
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {FlatTreeControl} from '@angular/cdk/tree';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit{
  @Input() user: any = {};
  public userForm = this.formBuilder.group({
    id: [''],
    salutation: ['', Validators.required],
    title: [''],
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    group: ['', Validators.required],
    username: ['', Validators.required]
  });
  public salutations = Salutation;
  public userGroups = Usergroup;
  public userStatus = Status;
  public showNotification = false;
  notification: {html: string, type: string } = {html: '', type: '' };
  authorities: any[] = [];


  private _transformer = (node: any, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<any>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(private userService: UserAdminRestService,
  private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.userService.getAuthorities(this.user.user.id).subscribe((auths: any) => {
      this.authorities = auths;
      // this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
      // this.dataSource.data = auths;
    });

    this.userForm.patchValue(this.user.user);
  }

  hasChild = (_: number, node: any) => node.expandable;

  getSalutationValues() {
    return Object.values(this.salutations);
  }

  getStatus(status: any) {
    // @ts-ignore
    return this.userStatus[status];
  }

  submit() {
    if(!this.userForm.valid) {
      return;
    }
    this.showNotification = false;
    const values = this.userForm.value;
    Object.entries(this.salutations).forEach((entry)=>{
      if(entry[1] === this.user.user.salutation){
        values.salutation = entry[0];
      }
    })

    this.userService.update(values).subscribe((result:any) => {
      if(!result.error_code){
        this.notification.html = 'Der Benutzer <b>' + values.first_name + ' ' + values.last_name + '</b> konnte erfolgreich gespeichert werden.'
        this.notification.type = 'success';
      } else{
        this.notification.html = 'Der Benutzer <b>' + values.first_name + ' ' + values.last_name + '</b> konnte nicht gespeichert werden.'
        this.notification.type = 'error';
      }
      this.showNotification = true;
      this.notify();
    })
  }

  notify(){
    setTimeout(() => {
      this.showNotification = true;
      this.notification = {html: '', type: ''}
    },5000)
  }
}
