import {Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {UserAdminService} from "../../core/user/services/user.admin.service";
import {CsvService} from "../../core/export/csv.service";
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';
import {MatRipple} from '@angular/material/core';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {UserModel} from "../../core/user/user.model";
import {MatPaginator} from '@angular/material/paginator';
import {Usergroup} from "../../core/user/models/usergroup.enum";
import {shareReplay, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {DialogTextModel} from "../../core/cms/models/dialog.text.model";
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DialogComponent} from "../../shared/dialog/dialog.component";

@Component({
  selector: 'app-internal-users',
  templateUrl: './internal-users.component.html',
  styleUrl: './internal-users.component.scss'
})
export class InternalUsersComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['status', 'name', 'username', 'email', 'group', 'actions'];
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatTable) dataTable: MatTable<any> | undefined;
  @ViewChildren(MatRipple) matRipple: QueryList<MatRipple> | undefined;
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;

  users: any [] = [];
  dataSource = new MatTableDataSource();
  page = 0;
  size = 25;
  filter = '';
  total = 0;
  notification = {html: '', type: ''};
  private ngUnsubscribe$ = new Subject<void>();
  public showNotification = false;
  editUser = false;
  selectedUser: any = {};
  public userGroups = Usergroup;

  constructor(public userService: UserAdminService,
              private csvService: CsvService,
              private router: Router,
              private datePipe: DatePipe,
              public dialog: MatDialog) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  ngOnInit() {
    this.editUser = false;
    this.loadUsersList(this.page, this.size, this.filter);
  }

  loadUsersList(page: number, size: number, search?: string) {
    let request: any = {page: page, size: size};
    request['search'] = search;


    this.userService.loadInternalUsers(request).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((usersResponse: any) => {
      this.users = usersResponse.result;
      this.total = usersResponse.total;
      if (this.users) {
        const user = new UserModel({});
        this.dataSource.data = user.toTableData(this.users);
      }
    })
  }

  applyFilter($event: any) {
    this.filter = $event.currentTarget.value;
    this.loadUsersList(this.page, this.size, this.filter);
  }

  getUsers(page: any) {
    this.page = page.pageIndex;
    this.size = page.pageSize;

    this.loadUsersList(this.page, this.size, this.filter);
  }

  edit(element: any) {
    this.editUser = true;
    this.selectedUser = element;
  }

  export($event: MouseEvent) {

  }

  remove(user: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = new DialogTextModel({
      title: 'Mitarbeiter löschen',
      content: 'Möchten Sie <b>' + user.username + '</b> wirklich löschen?',
      type: 'confirm'
    });

    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe$)).subscribe((result: any) => {
      if (result) {
        this.notification = {
          html: 'Der Benutzer <b>' + user.first_name + ' ' + user.last_name + '</b> wird gelöscht.',
          type: 'info'
        };
        this.notify();
        this.userService.remove(user).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res: any) => {
          this.notification = {
            html: 'Der Benutzer <b>' + user.first_name + ' ' + user.last_name + '</b> konnte erfolgreich gelöscht werden.',
            type: 'success'
          };
          this.notify();
        });
      }
    });
  }

  notify() {
    this.showNotification = true;
    setTimeout(() => {
      this.showNotification = false;
    }, 4000);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
