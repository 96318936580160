<h1>Files management</h1>
<button mat-icon-button (click)="addContainer()" color="accent"><mat-icon class="material-symbols-outlined">create_new_folder</mat-icon></button>
<div class="d-flex jcsb">
  <div class="tree w-30">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="files-tree">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <div class="mat-tree-node d-flex jcsb">
          <button mat-icon-button class="w-20"
                  matTreeNodeToggle
                  [attr.aria-label]="'Toggle ' + node.name"
                  (click)="selectedContainer = node.properties.container; selectedFolder = node.properties.folder; selectedNode = node">
            <mat-icon class="material-symbols-outlined">{{selectedNode === node ? 'folder_open' : 'folder'}}</mat-icon>
          </button>
          <div class="w-80">{{node.name}}</div>
        </div>
      </mat-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild">
        <div class="mat-tree-node d-flex jcsb">
          <button mat-icon-button matTreeNodeToggle
                  class="w-20"
                  [attr.aria-label]="'Toggle ' + node.name"
                  (click)="selectedContainer = node.properties.container; selectedFolder = node.properties.folder;  selectedNode = node">
            <mat-icon class="material-symbols-outlined">
              {{selectedNode === node ? 'folder_open' : 'folder'}}
            </mat-icon>
          </button>
          <div class="w-80">{{node.name}}</div>
        </div>

        <!-- There is inline padding applied to this div using styles.
            This padding value depends on the mat-icon-button width.  -->
        <div [class.files-tree-invisible]="!treeControl.isExpanded(node)"
             role="group">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
  <div class="files w-70">
    <app-file-list [container]="selectedContainer" [folder]="selectedFolder" [user]="user" (newFolder)="listContainers(); expandContainer()"></app-file-list>
  </div>
</div>
