import {Component, Input, Output, OnDestroy, EventEmitter} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {UserService} from "../../core/user/services/user.service";
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-idf-select',
  templateUrl: './idf-select.component.html',
  styleUrls: ['./idf-select.component.scss']
})
export class IdfSelectComponent implements OnDestroy {
  @Input() context: string = 'all';
  @Output() changeIdf = new EventEmitter<string>()
  public mainIdf: string = '';
  private ngUnsubscribe$ = new Subject<boolean>();
  public idfs: any[] = [];
  public loading: boolean = true;
  public showInput = false;
  idfForm = this.fb.group({
    idfInput: ['', [Validators.required, Validators.pattern(/^\d{2,7}$/)]]
  });
  @Output() selectedIdf: EventEmitter<any> = new EventEmitter<any>();

  constructor(private fb: FormBuilder,
              private userService: UserService) {
    this.userService.getMainIdfObservable()?.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((response:any) => {
      if (response.return_object != undefined) {
        this.mainIdf = response.return_object;
          this.idfForm.get('idfInput')?.setValue(this.mainIdf)
          this.showInput = this.userService.isAdmin() || this.mainIdf === "9999910";
      }
    });



    this.userService.getIdfsObservable(this.context)?.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((response: any) => {
      if (response != undefined && response.hasOwnProperty('idfs')) {
        this.idfs = response.idfs;
        this.loading = false;
      }
    });
  }

  setIdf($event: MatSelectChange) {
    this.selectedIdf.emit($event.value)
  }

  inputIdf($event: any){
    this.selectedIdf.emit($event.target.value);
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(false);
    this.ngUnsubscribe$.complete();
  }
}
