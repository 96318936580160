import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {phx} from "../../store/@types/models";
import Product = phx.portal.models.Product;
import {LOAD_PRODUCT_SEARCH, LOAD_PRODUCT_SHOP, SEARCH_PRODUCT_SHOP} from "../../store/actions/product.actions";
import {ProductRequest} from "../api/product.request.model";
import {ProductBaseModel} from "../product.base.model";
import { ProductModel } from '../product.model';

@Injectable({
  providedIn: 'root',
})
export class ProductSearchService implements OnDestroy{
  private ngUnsubscribe$ = new Subject<any>();
  products$: Observable<any> | null = null;

  constructor(public store: Store<{ product: Product }>) {
  }

  search(search: any, idf: string, size?: number, page?: number) {
    this.store.dispatch(LOAD_PRODUCT_SEARCH({payload: new ProductRequest({search: search, idf: idf, size: size, page: page})}));
    return this.store.select('product').pipe(takeUntil(this.ngUnsubscribe$), map((response: any) => {
      return response.list?.map((product: any) => new ProductBaseModel(product));
    }));
  }

  searchShop(search: any, idf: string, size?: number, page?: number) {
    this.store.dispatch(SEARCH_PRODUCT_SHOP());
    this.store.dispatch(LOAD_PRODUCT_SHOP({payload: new ProductRequest({search: search, idf: idf, size: size, page: page})}));
    return this.store.select('product').pipe(takeUntil(this.ngUnsubscribe$), map((response: any) => {
      return {page:response.page, end: response.end, count: response.count, size: response.size, products: response.productCache?.map((product: any) => new ProductModel(product))};
    }));
  }

  loadShop(search: any, idf: string, size?: number, page?: number) {
    this.store.dispatch(LOAD_PRODUCT_SHOP({payload: new ProductRequest({search: search, idf: idf, size: size, page: page})}));
    return this.store.select('product').pipe(takeUntil(this.ngUnsubscribe$), map((response: any) => {
      return {page:response.page, end: response.end, count: response.count, size: response.size, products: response.productCache?.map((product: any) => new ProductModel(product))};
    }));
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    //this.ngUnsubscribe$.next();
    // This completes the subject properlly.
    this.ngUnsubscribe$.complete();
  }
}
