export class DocRequest {

  docDate: string;
  docNr: number;
  docType: string;
  idf: number;
  vz: number;

  constructor(obj: any) {
    /* /documents2Idf?idf=1436&branch=5&startDate=2010-10-1&endDate=2021-10-1&docNr=403746 */
    this.docDate = obj && obj.docDate;
    this.docNr = obj && obj.docNr;
    this.docType = obj && obj.docType;
    this.idf = obj && obj.idf;
    this.vz = obj && obj.vz;
  }
}
