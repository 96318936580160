import * as fromUserActions from '../actions/user.actions';
import {Inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AuthService} from '../../auth/services/auth.service';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs';
import {UserRestService} from '../../user/services/user.rest.service';
import {Address} from '../../account/address.model';
import {VaccineRestService} from '../../vaccines/services/vaccination.rest.service';
import {ResponseModel} from "../../base/response.model";
import {UserResponse} from "../../user/api/user.response.model";

@Injectable({
  providedIn: 'root',
})
export class UserEffects {

  /**
   * On LoadUserAction request User from gateway and store it via LoadUserCompleteAction
   * or catch the error via LoadUserFailAction
   *
   * @type {Observable<any[]>}
   */
  loadUser$: Observable<Action> = createEffect(() => {
    return this.actions$
    .pipe(ofType(fromUserActions.LOAD_USER.type))
    .pipe(map((action: any) => action),
      switchMap((action) => {
          return this.authService.getUserResponseFromGateway().pipe(
            map((data: ResponseModel) => {
              return {
                type: fromUserActions.LOAD_USER_COMPLETE.type,
                payload: {userResponse: new UserResponse(data.return_object)}
              }
            }),
            catchError((error) => of({type: fromUserActions.LOAD_USER_FAIL.type, payload: error}))
          );
        }
      )
    );
  });

  loadNav$: Observable<Action> = createEffect(() => {
    return this.actions$
    .pipe(ofType(fromUserActions.LOAD_NAV.type))
    .pipe(map((action: any) => action), switchMap(() =>
        this.vaccineRestService.getVaccineShopConfig().pipe(
          switchMap(conf =>
            this.userRestService.getStaticNav(this.CMS_URL).pipe(
              map(data => {
                  return ({
                    type: fromUserActions.LOAD_NAV_COMPLETE.type,
                    payload: data
                  })
                }
              ),
              catchError((error) =>
                of({
                    type: fromUserActions.LOAD_NAV_FAIL.type,
                    payload: error
                  }
                )
              )
            )
          )
        )
      )
    )
  });

  /**
   * On LoadUserIDFsAction request idfs from gateway and store them via LoadUserIDFsCompleteAction
   * or catch the error via LoadUserIDFsFailAction
   *
   * @type {Observable<any>}
   */
  loadIDFs$: Observable<Action> = createEffect(() => {
    return this.actions$
    .pipe(ofType(fromUserActions.LOAD_USER_IDFS.type),
      switchMap((action: any) => {
          return this.userRestService.getIDFs(action.payload).pipe(
            map(data => ({
              type: fromUserActions.LOAD_USER_IDFS_COMPLETE.type,
              payload: {idfs: data.return_object, context: action.payload}
            })),
            catchError((error) => of({type: fromUserActions.LOAD_USER_IDFS_FAIL.type, payload: error}))
          )
        }
      ))
  });

  /**
   * On LoadUserMainIdfAction request the user's main IDF from gateway.
   *
   * @type {Observable<any>}
   */
  loadMainIdf$: Observable<Action> = createEffect(() => {
    return this.actions$
    .pipe(ofType(fromUserActions.LOAD_USER_MAIN_IDF.type),
      switchMap(() =>
        this.userRestService.getMainIdf().pipe(
          map(data => ({type: fromUserActions.LOAD_USER_MAIN_IDF_COMPLETE.type, payload: data})),
          catchError(error => of({type: fromUserActions.LOAD_USER_MAIN_IDF_FAIL.type, payload: error})
          )
        ))
    );
  });

  /**
   * On LoadUserVZsAction request idfs from gateway and store them via LoadUserVZsCompleteAction
   * or catch the error via LoadUserVZsFailAction
   *
   * @type {Observable<any>}
   */
  loadVZs$: Observable<Action> = createEffect(() => {
    return this.actions$
    .pipe(ofType(fromUserActions.LOAD_USER_VZS.type))
    .pipe(map((action: any) => action.payload), switchMap(idf =>
        this.userRestService.getVZs(idf).pipe(
          map(data => ({type: fromUserActions.LOAD_USER_VZS_COMPLETE.type, payload: data.return_object, idf: idf})),
          catchError((error) => of({type: fromUserActions.LOAD_USER_VZS_FAIL.type, payload: error}))
        )
      )
    );
  });

  /**
   * On LoadUserVZsAction request idfs from gateway and store them via LoadUserVZsCompleteAction
   * or catch the error via LoadUserVZsFailAction
   *
   * @type {Observable<any>}
   */
  loadShippingAdresses$: Observable<Action> = createEffect(() => {
    return this.actions$
    .pipe(ofType(fromUserActions.LOAD_USER_SA.type))
    .pipe(map((action: any) => action), switchMap(() =>
        this.userRestService.getShippingAdresses().pipe(
          map(data => {
            const adresses = data.return_object as Object[];
            return {
              type: fromUserActions.LOAD_USER_SA_COMPLETE.type,
              payload: (adresses.map((adress) => new Address(adress)))
            };
          }),
          catchError((error) => of({type: fromUserActions.LOAD_USER_SA_FAIL.type, payload: error}))
        )
      )
    );
  });

  loadMainDistributionCenterAdress$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromUserActions.LOAD_USER_DISTRIBUTION_CENTER_ADDRESS.type))
    .pipe(map((action: any) => action), switchMap(() =>
        this.userRestService.getMainDistributionCentersAdress().pipe(
          map(data => ({
            type: fromUserActions.LOAD_USER_DISTRIBUTION_CENTER_ADDRESS_COMPLETE.type,
            payload: data.return_object
          })),
          catchError((error) => of({
            type: fromUserActions.LOAD_USER_DISTRIBUTION_CENTER_ADDRESS_FAIL.type,
            payload: error
          }))
        )
      )
    );
  });

  loadAllDistributionCenterAdress$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        fromUserActions.LOAD_ALL_USER_DISTRIBUTION_CENTER_ADDRESS.type
      )).pipe(map((action: any) => action), switchMap(() =>
        this.userRestService.getAllDistributionCentersAdress().pipe(
          map(data => ({
            type: fromUserActions.LOAD_ALL_USER_DISTRIBUTION_CENTER_ADDRESS_COMPLETE.type,
            payload: data.return_object
          })),
          catchError((error) => of({
            type: fromUserActions.LOAD_ALL_USER_DISTRIBUTION_CENTER_ADDRESS_FAIL.type,
            payload: error
          }))
        )
      )
    );
  });

  loadAuthToken$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromUserActions.LOAD_AUTH_TOKEN.type))
    .pipe(map((action: any) => action), switchMap(() =>
        this.userRestService.getAuthToken().pipe(
          map(data => ({type: fromUserActions.LOAD_AUTH_TOKEN_COMPLETE.type, payload: data.return_object})),
          catchError(error => of({type: fromUserActions.LOAD_AUTH_TOKEN_FAIL.type, payload: error})
          )
        )
      )
    );
  });

  loadAuthTokens$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromUserActions.LOAD_AUTH_TOKENS.type))
    .pipe(map((action: any) => action), switchMap(() =>
        this.userRestService.getAuthTokens().pipe(
          map(data => ({type: fromUserActions.LOAD_AUTH_TOKENS_COMPLETE.type, payload: data.return_object})),
          catchError(error => of({type: fromUserActions.LOAD_AUTH_TOKENS_FAIL.type, payload: error})
          )
        )
      )
    );
  });

  /**
   * @param {AuthService} authService
   * @param {UserRestService} userRestService
   * @param {Actions} actions$
   * @param vaccineRestService
   * @param CMS_URL
   */
  constructor(
    private authService: AuthService,
    private userRestService: UserRestService,
    private actions$: Actions,
    private vaccineRestService: VaccineRestService,
    @Inject('CMS_URL') private CMS_URL: string
  ) {
  }


  getObjectValues(obj: any) {
    const values = Object.keys(obj).map(function (e) {
      return obj[e];
    });
    return values;
  }
}
