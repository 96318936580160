/**
 * represent the basic navigation model
 */
import {NavItem, NavType} from './models/nav.item.model';
import {ProductType} from '../products/models/product.type.model';

export class NavModel {
  headline = '';
  component = '';
  tree: NavItem[];


  constructor(obj: any = {}, cmsHost: string = '', vaccineConfig: any = {}) {
    this.headline = '';
    this.component = 'dashboard';
    this.tree = [];
    // this.tree = obj && obj.tree || [];
    // this.tree = [];

    this.tree.push(new NavItem({navLinkName: 'NAVIGATION.DASHBOARD.LINK', path: 'dashboard'}));
    this.tree.push(new NavItem({
      navLinkName: 'NAVIGATION.DATA.LINK',
      requiredAuthorities: [
        'MY_SALES',
        'BULK_BILL_RECEIPT',
        'BULK_BILL',
        'CREDIT_NOTE',
        'VETERINARY_DRUG',
        'TRANSFUSION',
        'REIMPORT',
        'EXTENDED_BENEFIT',
        'PAYBACK'
      ],
      tree: [
        new NavItem({
          heading: 'NAVIGATION.DATA.INVOICES.HEADING',
          navLinkName: 'NAVIGATION.DATA.INVOICES.LINK',
          component: 'belegsuche',
          path: 'belegsuche',
          requiredAuthorities: [
            'BULK_BILL_RECEIPT',
            'BULK_BILL',
            'CREDIT_NOTE',
            'VETERINARY_DRUG',
            'TRANSFUSION',
            'REIMPORT',
            'EXTENDED_BENEFIT'
          ],
          isTile: true,
          tileTitle: 'NAVIGATION.DATA.INVOICES.TILE',
          tileImageFileName: 'assets/img/tiles/svg/rechnung-abfragen-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/rechnung-abfragen-hover-kachel.svg',
          tilePosition: 10
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.DATA.REVENUE.LINK',
          isTile: true,
          tileTitle: 'NAVIGATION.DATA.REVENUE.TILE',
          heading: 'NAVIGATION.DATA.REVENUE.HEADING',
          component: 'mein-umsatz',
          path: 'mein-umsatz',
          requiredAuthorities: ['MY_SALES'],
          tileImageFileName: 'assets/img/tiles/svg/umsatz-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/umsatz-hover-kachel.svg',

          tilePosition: 20,
          pageStatus: this.getPageStatus(7, this.parseDate('2023-03-31T23:59:00'))
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.DATA.PAYBACK.LINK',
          isTile: true,
          tileTitle: 'NAVIGATION.DATA.PAYBACK.TILE',
          heading: 'NAVIGATION.DATA.PAYBACK.HEADING',
          component: 'payback',
          path: 'payback',
          requiredAuthorities: ['PAYBACK'],
          tileImageFileName: 'assets/img/tiles/svg/payback-new-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/payback-new-hover-kachel.svg',
          tilePosition: 30
        })
      ]
    }));

    this.tree.push(new NavItem({
      navLinkName: 'NAVIGATION.ORDER.LINK',
      heading: 'NAVIGATION.ORDER.HEADING',
      activatesPath: ['bestellung'],
      dataCy: 'order-and-delivery',
      requiredAuthorities: [
        'ORDERS',
        'TRACK_TRACE'
      ],
      tree: [
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.PRODUCTS.LINK',
          heading: 'NAVIGATION.ORDER.PRODUCTS.HEADING',
          component: 'doku-light',
          activatesPath: ['doku-light-details'],
          path: ProductType.DokuLight,
          isTile: true,
          tileTitle: 'NAVIGATION.ORDER.PRODUCTS.TILE',
          tileImageFileName: 'assets/img/tiles/svg/doku-light-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/doku-light-hover-kachel.svg',
          dataCy: 'doku-light',
          requiredAuthorities: ['ORDERS'],
          tilePosition: 2
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.NACHLIEFERUNGLISTE.LINK',
          heading: 'NAVIGATION.ORDER.NACHLIEFERUNGLISTE.HEADING',
          component: 'nachlieferung',
          path: 'nachlieferung',
          isTile: true,
          tileTitle: 'NAVIGATION.ORDER.NACHLIEFERUNGLISTE.TILE',
          tileImageFileName: 'assets/img/tiles/svg/nachlieferungliste-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/nachlieferungliste-hover-kachel.svg',
          requiredAuthorities: ['E_SARE'],
          tilePosition: 2,
          pageStatus: this.getPageStatus(1, this.parseDate('2022-09-25T18:00:00'))
        }),
        new NavItem({

          navLinkName: 'NAVIGATION.ORDER.NACHLIEFERUNGLISTE.LINK',
          heading: 'NAVIGATION.ORDER.NACHLIEFERUNGLISTE.HEADING',
          component: 'nachlieferung',
          path: 'nachlieferung',
          isTile: true,
          tileTitle: 'NAVIGATION.ORDER.NACHLIEFERUNGLISTE.TILE',
          tileImageFileName: 'assets/img/tiles/svg/nachlieferungliste-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/nachlieferungliste-hover-kachel.svg',
          excludedAuthorities: ['E_SARE'],
          tilePosition: 2,
          type: NavType.DISABLED,
          class: 'tile--inactive'
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.VORMERKUNGEN.LINK',
          heading: 'NAVIGATION.ORDER.VORMERKUNGEN.HEADING',
          component: 'vormerkungen',
          path: ProductType.Vormerkungen,
          isTile: true,
          tileTitle: 'NAVIGATION.ORDER.VORMERKUNGEN.TILE',
          tileImageFileName: 'assets/img/tiles/svg/syringe-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/syringe-hover-kachel.svg',
          requiredAuthorities: ['ORDERS'],
          type: NavType.VACCINE,
          tilePosition: 3,
          start: vaccineConfig.activeFrom,
          end: vaccineConfig.activeUntil,
          class: 'yellowTile',
          pageStatus: this.getPageStatus(2, this.parseDate('2021-02-25T18:00:00'))
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.ZUTEILUNGSMENGE.LINK',
          heading: 'NAVIGATION.ORDER.ZUTEILUNGSMENGE.HEADING',
          component: 'zuteilungsmenge',
          path: 'zuteilungsmenge',
          isTile: true,
          tileTitle: 'NAVIGATION.ORDER.ZUTEILUNGSMENGE.TILE',
          tileImageFileName: 'assets/img/tiles/svg/rechnung-abfragen-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/rechnung-abfragen-hover-kachel.svg',
          requiredAuthorities: ['ORDERS'],
          type: NavType.VACCINE,
          tilePosition: 4,
          start: '2021-03-29T00:00:00'
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.LAGERAKTION.LINK',
          component: 'lageraktion',
          path: 'lageraktion/grippe2023',
          isTile: true,
          tileTitle: 'NAVIGATION.ORDER.LAGERAKTION.TILE',
          tileImageFileName: 'assets/img/tiles/svg/vorbestellung_grippeimpfstoff_2022_kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/vorbestellung_grippeimpfstoff_2022_kachel-hover.svg',
          requiredAuthorities: ['ORDERS'],
          type: NavType.VACCINE,
          tilePosition: 5,
          start: '2022-06-27T00:00:00',
          class: 'tealTile',
          pageStatus: this.getPageStatus(1, this.parseDate('2023-02-14T17:59:00'))
        }),

        /*                new NavItem({
                            navLinkName: 'NAVIGATION.ORDER.FLUSHOP.LINK',
                            component: 'flu-shop',
                            path: 'vorbestellung/grippe2023',
                            isTile: true,
                            tileTitle: 'NAVIGATION.ORDER.FLUSHOP.TILE',
                            tileImageFileName: 'assets/img/tiles/svg/vorbestellung_grippeimpfstoff_2022_kachel.svg',
                            tileImageFileNameHover: 'assets/img/tiles/svg/vorbestellung_grippeimpfstoff_2022_kachel-hover.svg',
                            requiredAuthorities: ['ORDERS'],
                            type: NavType.VACCINE,
                            tilePosition: 5,
                            start: '2022-06-27T00:00:00',
                            pageStatus: this.getPageStatus(1, this.parseDate('2023-02-14T17:59:00'))
                        }),*/
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.FLUSHOPARCHIVE.LINK',
          heading: 'NAVIGATION.ORDER.FLUSHOPARCHIVE.HEADING',
          component: 'flu-shop-archive',
          path: 'vorbestellung/grippe2022-archive',
          isTile: true,
          tileTitle: 'NAVIGATION.ORDER.FLUSHOPARCHIVE.TILE',
          tileImageFileName: 'assets/img/tiles/svg/vorbestellung_grippeimpfstoff_2022_kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/vorbestellung_grippeimpfstoff_2022_kachel-hover.svg',
          requiredAuthorities: ['ORDERS'],
          type: NavType.VACCINE,
          tilePosition: 6,
          start: '2022-01-01T00:00:00',
          pageStatus: this.getPageStatus(1, this.parseDate('2022-05-31T19:59:00'))
        }),
        /*    new NavItem({
                navLinkName: 'NAVIGATION.ORDER.PROFAX.LINK',
                heading: 'NAVIGATION.ORDER.PROFAX.HEADING',
                component: 'profax',
                path: ProductType.Profax,
                isTile: false,
                tileTitle: 'NAVIGATION.ORDER.PROFAX.TILE',
                tileImageFileName: 'assets/img/tiles/svg/profax-kachel.svg',
                tileImageFileNameHover: 'assets/img/tiles/svg/profax-hover-kachel.svg',
                requiredAuthorities: ['ORDERS'],
                tilePosition: 5
            }), */
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.PROMO.LINK',
          heading: 'NAVIGATION.ORDER.PROMO.HEADING',
          component: 'promo',
          path: 'promotions',
          isTile: true,
          tileTitle: 'NAVIGATION.ORDER.PROMO.TILE',
          tileImageFileName: 'assets/img/tiles/svg/promotions-01.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/promotions-hover-01.svg',
          requiredAuthorities: ['ORDERS'],
          tilePosition: 7,
          start: '2022-12-11T08:00:00',
          pageStatus: this.getPageStatus(2, this.parseDate('2022-03-04T23:59:00'))
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.PARAGRAPH73.LINK',
          heading: 'NAVIGATION.ORDER.PARAGRAPH73.HEADING',
          component: 'paragraph73',
          path: ProductType.Paragraph73,
          isTile: true,
          tileTitle: 'NAVIGATION.ORDER.PARAGRAPH73.TILE',
          tileImageFileName: 'assets/img/tiles/svg/amg-73-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/amg-73-kachel-hover.svg',
          requiredAuthorities: ['ORDERS'],
          tilePosition: 8
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.UBERWEISER.LINK',
          heading: 'NAVIGATION.ORDER.UBERWEISER.HEADING',
          component: 'uberweiser',
          path: 'uberweiser',
          isTile: true,
          tileTitle: 'NAVIGATION.ORDER.UBERWEISER.TILE',
          tileImageFileName: 'assets/img/tiles/svg/uberweiser.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/uberweiser-hover.svg',
          requiredAuthorities: ['ORDERS'],
          type: NavType.UBER,
          tilePosition: 9,
          start: '2030-12-31T23:59:59',
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.TOPPRISMA.LINK',
          heading: 'NAVIGATION.ORDER.TOPPRISMA.HEADING',
          component: ProductType.TopPrisma,
          path: ProductType.TopPrisma,
          isTile: true,
          tileTitle: 'NAVIGATION.ORDER.TOPPRISMA.TILE',
          tileImageFileName: 'assets/img/tiles/svg/prisma-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/prisma-hover-kachel.svg',
          requiredAuthorities: ['ORDERS'],
          pageStatus: this.getPageStatus(1, this.parseDate('2022-05-05T18:00:00')),
          tilePosition: 12
        }),
        // new NavItem({
        //     navLinkName: 'NAVIGATION.ORDER.EXTRAPRISMA.LINK',
        //     heading: 'NAVIGATION.ORDER.EXTRAPRISMA.HEADING',
        //     component: ProductType.ExtraPrisma,
        //     path: ProductType.ExtraPrisma,
        //     isTile: true,
        //     tileTitle: 'NAVIGATION.ORDER.EXTRAPRISMA.TILE',
        //     tileImageFileName: 'assets/img/tiles/svg/extra-prisma-kachel.svg',
        //     tileImageFileNameHover: 'assets/img/tiles/svg/extra-prisma-hover-kachel.svg',
        //     requiredAuthorities: ['ORDERS'],
        //     tilePosition: 9
        // }),
        /*PXPPO-1443
          new NavItem({
              navLinkName: 'NAVIGATION.ORDER.EMERGENCY-DEPOT.LINK',
              isTile: true,
              tileTitle: 'NAVIGATION.ORDER.EMERGENCY-DEPOT.TILE',
              priorPortalUrl: 'NAVIGATION.ORDER.EMERGENCY-DEPOT.URL',
              tileImageFileName: 'assets/img/tiles/svg/notfalldepot-kachel.svg',
              tileImageFileNameHover: 'assets/img/tiles/svg/notfalldepot-hover-kachel.svg',
              type: NavType.PRIORPORTAL
          }),*/
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.PRODUCTWISH.LINK',
          isTile: true,
          tileTitle: 'NAVIGATION.ORDER.PRODUCTWISH.TILE',
          priorPortalUrl: 'NAVIGATION.ORDER.PRODUCTWISH.URL',
          tileImageFileName: 'assets/img/tiles/svg/artikelneuaufnahme-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/artikelneuaufnahme.hover-kachel.svg',
          shouldOpenInNewTab: true,
          requiredAuthorities: ['ORDERS'],
          type: NavType.DISABLED,
          tilePosition: 13
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.TRACKANDTRACE.LINK',
          heading: 'NAVIGATION.ORDER.TRACKANDTRACE.HEADING',
          component: 'track-trace',
          path: 'track-trace',
          isTile: true,
          requiredAuthorities: ['TRACK_TRACE'],
          tileTitle: 'NAVIGATION.ORDER.TRACKANDTRACE.TILE',
          tileImageFileName: 'assets/img/tiles/svg/track-and-trace.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/track-and-trace-hover.svg',
          tilePosition: 14
        }),

        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.ADVERTORIAL.LINK',
          heading: 'NAVIGATION.ORDER.ADVERTORIAL.HEADING',
          activatesPath: ['advertorial/:link'],
          component: 'advertorial',
          path: 'amflee-combo',
          isTile: true,
          tileTitle: 'AMFLEE combo',
          tileImageFileName: 'assets/img/tiles/advertorial/KACHEL_GRAFIK.png',
          tileImageFileNameHover: 'assets/img/tiles/advertorial/KACHEL_GRAFIK.png',
          tilePosition: 27,
          type: NavType.ADVERTORIAL,
          class: 'whiteTile advertorial',
          start: '2023-06-16T00:00:00',
          end: '2023-06-30T23:59:00',
          pageStatus: this.getPageStatus(6, this.parseDate('2023-07-31T23:00:00')),
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.ADVERTORIAL.LINK',
          heading: 'NAVIGATION.ORDER.ADVERTORIAL.HEADING',
          activatesPath: ['advertorial/:link'],
          component: 'advertorial',
          path: 'curaprox',
          isTile: true,
          tileTitle: 'CURAPROX',
          tileImageFileName: 'assets/img/tiles/advertorial/curaprox-tile.svg',
          tileImageFileNameHover: 'assets/img/tiles/advertorial/curaprox-tile.svg',
          tilePosition: 26,
          type: NavType.ADVERTORIAL,
          class: 'whiteTile advertorial',
          start: '2023-06-01T00:00:00',
          end: '2023-06-15T23:59:00',
          pageStatus: this.getPageStatus(6, this.parseDate('2023-07-31T23:00:00')),
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.ADVERTORIAL.LINK',
          heading: 'NAVIGATION.ORDER.ADVERTORIAL.HEADING',
          activatesPath: ['advertorial/:link'],
          component: 'advertorial',
          path: 'terratrust',
          isTile: true,
          tileTitle: 'TerraTrust',
          tileImageFileName: 'assets/img/tiles/advertorial/terratrust_tile.svg',
          tileImageFileNameHover: 'assets/img/tiles/advertorial/terratrust_tile.svg',
          tilePosition: 28,
          type: NavType.ADVERTORIAL,
          class: 'whiteTile advertorial',
          start: '2023-06-16T00:00:00',
          end: '2023-06-30T23:40:00',
          pageStatus: this.getPageStatus(6, this.parseDate('2024-05-31T23:00:00')),
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.ADVERTORIAL.LINK',
          heading: 'NAVIGATION.ORDER.ADVERTORIAL.HEADING',
          activatesPath: ['advertorial/:link'],
          component: 'advertorial',
          path: 'mytolac',
          isTile: true,
          tileTitle: 'Mytolac',
          tileImageFileName: 'assets/img/tiles/advertorial/mytolac_tile.svg',
          tileImageFileNameHover: 'assets/img/tiles/advertorial/mytolac_tile.svg',
          tilePosition: 28,
          type: NavType.ADVERTORIAL,
          class: 'whiteTile advertorial',
          start: '2023-06-16T00:00:00',
          end: '2023-06-30T23:40:00',
          pageStatus: this.getPageStatus(6, this.parseDate('2024-05-31T23:00:00')),
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.CONTEST.LINK',
          heading: 'NAVIGATION.ORDER.CONTEST.HEADING',
          activatesPath: ['contest/:url'],
          component: 'cms',
          path: 'emra',
          isTile: true,
          tileTitle: 'Endlich Sommer!',
          tileImageFileName: 'https://cspappsacc.blob.core.windows.net/cms/images/PR23105 - EMRAmed - Phoenix - Gewinnspiel - Kachelbild - 100x100px.png',
          tileImageFileNameHover: 'https://cspappsacc.blob.core.windows.net/cms/images/PR23105 - EMRAmed - Phoenix - Gewinnspiel - Kachelbild - 100x100px.png',
          tilePosition: 30,
          type: NavType.CONTEST,
          class: 'whiteTile advertorial',
          start: '2023-03-17T00:00:00',
          end: '2023-06-30T23:40:00',
          pageStatus: this.getPageStatus(8, this.parseDate('2023-06-30T23:00:00')),
        }),

      ]
    }));
    // Create a sub-tree for the service related links and tiles
    const serviceTree = [];

    serviceTree.push(
      new NavItem({
        navLinkName: 'NAVIGATION.SERVICE.LINDA.LINK',
        heading: 'NAVIGATION.SERVICE.LINDA.HEADING',
        component: 'linda',
        path: 'pkp',
        activatesPath: ['pkp'],
        isTile: true,
        tileTitle: 'NAVIGATION.SERVICE.LINDA.TILE',
        tileImageFileName: 'assets/img/tiles/svg/LINDA-kachel.svg',
        tileImageFileNameHover: 'assets/img/tiles/svg/LINDA-kachel.svg',
        shouldOpenInNewTab: false,
        tilePosition: 1
      }),
      new NavItem({
        // requiredAuthorities: ['PAYBACK', 'GROUP_MARKETING', 'GROUP_KSC', 'GROUP_SALES'],
        navLinkName: 'NAVIGATION.SERVICE.DEINEAPOTHEKESTORE.LINK',
        isTile: true,
        tileTitle: 'NAVIGATION.SERVICE.DEINEAPOTHEKESTORE.TILE',
        tileImageFileName: 'assets/img/tiles/svg/phoenixstore-kachel.svg',
        tileImageFileNameHover: 'assets/img/tiles/svg/phoenixstore-hover-kachel.svg',
        shouldOpenInNewTab: true,
        type: NavType.DEINEAPOTHEKESTORE,
        tilePosition: 20,
      }),
      new NavItem({
        requiredAuthorities: ['GROUP_ACCOUNT_OWNER', 'GROUP_MARKETING', 'GROUP_KSC', 'GROUP_SALES'],
        navLinkName: 'NAVIGATION.SERVICE.PRIVATSORTIMENT.LINK',
        isTile: true,
        tileTitle: 'NAVIGATION.SERVICE.PRIVATSORTIMENT.TILE',
        tileImageFileName: 'assets/img/tiles/svg/genuss-kultur-kachel.svg',
        tileImageFileNameHover: 'assets/img/tiles/svg/genuss-kultur-hover-kachel.svg',
        shouldOpenInNewTab: true,
        type: NavType.PRIVATSORTIMENT,
        tilePosition: 30
      }),
    );
    if (obj && obj.length) {

      obj.forEach((element:any) => {
        serviceTree.push(new NavItem({
          navLinkName: element.title,
          heading: element.title,
          extUrl: element.url,
          path: 'static-content',
          isTile: true,
          tileTitle: element.title,
          tileImageFileName: cmsHost + element['button-icon-normal'],
          tileImageFileNameHover: cmsHost + element['button-icon-hover'],
          pageStatus: this.getPageStatus(element['page-status'], element['page-status-until']),
          tilePosition: 5
        }));
      });
    }

    this.tree.push(new NavItem({
      navLinkName: 'NAVIGATION.SERVICE.LINK',
      tree: serviceTree
    }));

    this.tree.push(new NavItem({
      navLinkName: 'NAVIGATION.PREFERENCES.LINK',
      requiredAuthorities: ['GROUP_ACCOUNT_OWNER'],
      tree: [
        new NavItem({
          requiredAuthorities: ['GROUP_ACCOUNT_OWNER'],
          heading: 'NAVIGATION.PREFERENCES.SUBUSERMANAGEMENT.HEADING',
          navLinkName: 'NAVIGATION.PREFERENCES.SUBUSERMANAGEMENT.LINK',
          component: 'mitbenutzerverwaltung',
          path: 'mitbenutzerverwaltung',
          isTile: true,
          tileTitle: 'NAVIGATION.PREFERENCES.SUBUSERMANAGEMENT.TILE',
          tileImageFileName: 'assets/img/tiles/svg/mitbenutzer-verwalten-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/mitbenutzer-verwalten-hover-kachel.svg',
          tilePosition: 1
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.PREFERENCES.PHARMACY-DATA.LINK',
          heading: 'NAVIGATION.PREFERENCES.PHARMACY-DATA.HEADING',
          requiredAuthorities: ['GROUP_ACCOUNT_OWNER'],
          component: 'apothekenstammdaten',
          path: 'apothekenstammdaten',
          isTile: true,
          tileTitle: 'NAVIGATION.PREFERENCES.PHARMACY-DATA.TILE',
          tileImageFileName: 'assets/img/tiles/svg/apothekenstammdaten-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/apothekenstammdaten-hover-kachel.svg',
          tilePosition: 2
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.PREFERENCES.INTEGRATIONS.LINK',
          heading: 'NAVIGATION.PREFERENCES.INTEGRATIONS.HEADING',
          requiredAuthorities: ['GROUP_ACCOUNT_OWNER', 'E_SARE'],
          component: 'schnittstellen',
          path: 'schnittstellen',
          isTile: true,
          tileTitle: 'NAVIGATION.PREFERENCES.INTEGRATIONS.TILE',
          tileImageFileName: 'assets/img/tiles/svg/schnittstellen-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/schnittstellen-hover-kachel.svg',
          tilePosition: 3
        })
      ]
    }));
    this.tree.push(new NavItem({
      requiredAuthorities: ['CONTACT_PERSON'],
      navLinkName: 'NAVIGATION.SUPPORTS.LINK',
      heading: 'NAVIGATION.SUPPORTS.HEADING',
      component: 'fragenundantworten',
      path: 'fragenundantworten',
    }));
    this.tree.push(new NavItem({
      requiredAuthorities: ['CONTACT_PERSON'],
      heading: 'NAVIGATION.MAIL.HEADING',
      component: 'postfach',
      path: 'postfach',
      type: NavType.QUICK
    }));
  }

  getParent(currentlyActivePath: string, extUrl: string|null = null): string|null {
    if (!this.tree || currentlyActivePath === 'dashboard'
      || (currentlyActivePath === 'static-content' && extUrl == null)) {
      return null;
    }
    let ret = null;
    let done = false;
    if (currentlyActivePath.indexOf('static-content') !== -1 && extUrl !== null) {
      this.tree.forEach((navItem:NavItem) => {
        if (navItem.tree) {
          navItem.tree.forEach((subNavItem: NavItem) => {
            if (subNavItem.extUrl && subNavItem.extUrl.indexOf(extUrl) !== -1) {
              ret = navItem.navLinkName;
              done = true;
              return;
            }
          });
        }
        if (done) {
          return;
        }
      });
    } else {
      this.tree.forEach((navItem:NavItem) => {
        if (navItem.tree) {
          navItem.tree.forEach((subNavItem: NavItem) => {
            if (subNavItem.path === currentlyActivePath || subNavItem.activatesPath.indexOf(currentlyActivePath) !== -1) {
              ret = navItem.navLinkName;
              done = true;
              return;
            }
          });
        }
        if (done) {
          return;
        }
      });
    }
    return ret;
  }

  getProperty(currentlyActivePath: string, extUrl: string|null = null, property: any = 'heading'): string | boolean {
    if (!this.tree || currentlyActivePath === 'dashboard'
      || (currentlyActivePath === 'static-content' && extUrl == null)) {
      return false;
    }
    let ret: string | boolean | number | (() => boolean) | NavType | (() => boolean) | ((component: string) => boolean) | string[] | NavItem[] = false;
    let done = false;
    if (currentlyActivePath.indexOf('static-content') !== -1 && extUrl !== null) {
      if (property === 'path') {
        property = 'extUrl';
      }
      this.tree.forEach((navItem:NavItem) => {
        if (navItem.extUrl && navItem.extUrl.indexOf(extUrl) !== -1 && navItem.hasOwnProperty(property)) {
          let prop = property as keyof NavItem;
          ret = navItem[prop];
          done = true;
        } else if (navItem.tree) {
          navItem.tree.forEach((subNavItem: NavItem) => {
            if (subNavItem.extUrl && subNavItem.extUrl.indexOf(extUrl) !== -1 && subNavItem.hasOwnProperty(property)) {
              let prop = property as keyof NavItem;
              ret = subNavItem[prop];
              done = true;
              return;
            }
          });
        }
        if (done) {
          return;
        }
      });
    } else {
      this.tree.forEach((navItem:NavItem) => {
        if ((navItem.path === currentlyActivePath || navItem.activatesPath.indexOf(currentlyActivePath) !== -1)
          && navItem.hasOwnProperty(property)) {
          let prop = property as keyof NavItem;
          ret = navItem[prop];
          done = true;
        } else if (navItem.tree) {
          navItem.tree.forEach((subNavItem: NavItem) => {
            if ((subNavItem.path === currentlyActivePath || subNavItem.activatesPath.indexOf(currentlyActivePath) !== -1)
              && subNavItem.hasOwnProperty(property)) {
              let prop = property as keyof NavItem;
              ret = subNavItem[prop];
              done = true;
              return;
            }
          });
        }
        if (done) {
          return;
        }
      });
    }
    return ret;
  }


  getAuthoritiesForRoute(route: string): string[] {
    if (!this.tree || route === '/dashboard') {
      return [];
    }
    let authorities: string[] = [];
    this.tree.forEach((navItem:NavItem) => {
      if (route.indexOf(navItem.path) !== -1) {
        authorities = navItem.hasOwnProperty('requiredAuthorities') ? navItem['requiredAuthorities'] : [];
      } else if (navItem.tree) {
        navItem.tree.forEach((subNavItem: NavItem) => {
          if (route.indexOf(subNavItem.path) !== -1) {
            authorities = subNavItem.hasOwnProperty('requiredAuthorities') ? subNavItem['requiredAuthorities'] : [];
          }
        });
      }
    });
    return authorities;
  }

  getFeatureForRoute(route: string): string {
    if (!this.tree || route === '/dashboard') {
      return '';
    }

    let feature = '';

    this.tree.forEach((navItem:NavItem) => {
      if (route.indexOf(navItem.path) !== -1) {
        feature = navItem.feature ? navItem.feature : '';
      } else if (navItem.tree) {
        navItem.tree.forEach((subNavItem: NavItem) => {
          if (route.indexOf(subNavItem.path) !== -1) {
            feature = subNavItem.feature ? subNavItem.feature : '';
          }
        });
      }
    });

    return feature;
  }

  parseDate(string:string):number {
    const time = new Date(string);
    return time.getTime() / 1000;
  }

  getPageStatus(pageStatus: number, until: number) {
    let status: string | null;
    const now = new Date();

    if (until < now.getTime() / 1000) {
      return 0;
    }

    switch (pageStatus) {
      case 1:
        status = 'neu';
        break;
      case 2:
        status = 'update';
        break;
      case 3:
        status = 'letzte chance';
        break;
      case 4:
        status = 'Letzte Chance Efluelda';
        break;
      case 5:
        status = 'Hier teilnehmen';
        break;
      case 6:
        status = 'Advertorial';
        break;
      case 7:
        status = 'Neue Inhalte';
        break;
      case 8:
        status = 'Gewinnspiel';
        break;
      default:
        status = null;
        break;
    }

    return status;
  }
}

