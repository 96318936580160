import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {PasswordRestService} from "../../core/auth/services/password.rest.service";
import {distinctUntilChanged, takeUntil, tap} from "rxjs/operators";
import {Subject} from "rxjs";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss']
})
export class ForgottenPasswordComponent implements OnDestroy, OnInit {
  protected ngUnsubscribe$: Subject<void> = new Subject<void>();

  public submitSuccess = false;
  public email:string ='';
  public resetPasswordForm;
  public zip_code = new FormControl('', [
    Validators.required,
    Validators.pattern(/^\d{5}$/)
  ]);
  public error = '';

  public idf = new FormControl('', [
    Validators.required,
    Validators.pattern(/^\d{2,7}$/)
  ]);

  public columns = 1;
  Breakpoints = Breakpoints;
  currentBreakpoint:string = '';

  readonly breakpoint$ = this.breakpointObserver
  .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small])
  .pipe(
    tap((value: any) => {
      // console.log(value)
    }),
    distinctUntilChanged()
  );

  constructor(public formBuilder: FormBuilder,
              private breakpointObserver: BreakpointObserver,
              private passwordRestService: PasswordRestService) {
    this.resetPasswordForm = this.formBuilder.group({
      idf: this.idf,
      zip_code: this.zip_code
    });
    this.breakpointChanged();
  }

  ngOnInit() {
    this.breakpoint$.subscribe(() =>
      this.breakpointChanged()
    );
  }

  submit() {
    if(!this.resetPasswordForm.valid){
      return;
    }

    this.error = '';
    const request = this.resetPasswordForm.value;
    request.idf = request.idf?.trim();
    request.zip_code = request.zip_code?.trim();
    this.passwordRestService.initPasswordReset(request).pipe(takeUntil(this.ngUnsubscribe$))
    .subscribe((success:any) =>{
      this.submitSuccess = true;
      this.email = success.return_object;
    },
      (error: any) => {
        if (error.error.error_code === 'UNCOMBINABLE_IDF_ZIPCODE'){
          this.error = 'IDF oder PLZ ist nicht korrekt. Bitte überprüfen Sie Ihre Eingabe und versuchen es erneut.' +
            '<p>Das liegt möglicherweise an:<br>' +
            '1. Einem Schreibfehler bei Eingabe der Haupt-IDF oder Postleitzahl<br>' +
            '2. Sie haben versucht, die Zugangsdaten für eine Filiale zurückzusetzen. Dies ist nicht möglich. Bitte wenden Sie sich an den/die Inhaber/in.</p>';
        } else {
          this.error = 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal.r'
        }
      })
  }

  private breakpointChanged() {
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.columns = 2;
    } else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.columns = 2;
    } else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = 1;
    }
  }

  /**
   * unsubcribe all subscriptions on destroy
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
