import {NotificationsType} from "./enums/notifications.type.enum";
import {NotificationsTableElement} from "./interfaces/notifications.table.element";
import * as moment from 'moment';

export class NotificationsModel{
  title: string;
  active: boolean;
  type: NotificationsType;
  start: string;
  end: string;
  filter: string;
  text: string;
  index: number;

  constructor(obj: any = {}) {
    this.title = obj && obj.title || null;
    this.active = obj && obj.active || false;
    this.type = obj && obj.type || NotificationsType.INFO;
    this.start = obj && obj.start || null;
    this.end = obj && obj.end || null;
    this.filter = obj && obj.filter || null;
    this.text = obj && obj.text || null;
    this.index = obj && obj.index || 0;
  }


  toTableData(data: any) {
    const tableData: NotificationsTableElement[] = [];
    for (let line of data) {
      const notification = new NotificationsModel(line);
      let date = '';
      if (notification.start){
        date += moment(notification.start).format('DD.MM.YYYY')
      }
      if(notification.end) {
        date += ' bis ' + moment(notification.end).format('DD.MM.YYYY')
      }

      tableData.push({
        active: notification.active,
        date: date,
        notification: notification,
        filter: notification.filter,
        title: notification.title,
        type: notification.type
      })
    }
    return tableData;
  }
}
