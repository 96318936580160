/**
 * represent the basic user model
 */
import {UsersTableElement} from "./interfaces/users.table.element";

export class UserModel {
  id: number;
  title: string;
  name: string;
  last_name: string;
  authorities: number[] = [];
  authoritiesEnum: string[] = [];
  username: string;
  salutation: string;
  featuresEnum: string[] = [];
  canary: boolean;
  status: string;
  group: string;
  email: string;
  first_name: string;
  created_at: string;
  activated_at: string;
  accounts: any[] = [];

  constructor(obj: any) {
    this.id = obj && obj.id || null;
    this.title = obj && obj.title || '';
    this.name = obj && obj.name || null;
    this.last_name = obj && obj.last_name || null;
    this.salutation = obj && obj.user_salutation || null;
    this.username = obj && obj.username || null;
    this.authorities = obj && obj.authorities || [];
    this.authoritiesEnum = obj && obj.authoritiesEnum || [];
    this.featuresEnum = obj && obj.featuresEnum || [];
    this.canary = obj && obj.canary || false;
    this.status = obj && obj.status || '';
    this.group = obj && obj.group || '';
    this.email = obj && obj.email || '';
    this.first_name = obj && obj.first_name;
    this.created_at = obj && obj.created_at;
    this.accounts = obj && obj.accounts || []
    this.activated_at = obj && obj.activated_at || '';
  }

  toTableData(data: any) {
    const userTableData: UsersTableElement[] = [];

    for(let line of data) {
      const user = new UserModel(line);

      userTableData.push(
        {
          status: line.status,
          name: line.name,
          username: line.username,
          email: line.email,
          idfs: line.idfs,
          mainIdf: line.main_idf,
          group: line.group,
          user: user
        }
      );
    }

    return userTableData;
  }

  toExportData(data: any){
    const exportData: any[] = [];


      for(let line of data.accounts) {
        const user = new UserModel(data);
        console.log(data);

        exportData.push({
          idf: line.idf,
          apotheke: line.name,
          activated: user.activated_at,
          created: data.created_at ? data.created_at : '',
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          group: data.group,
          status: data.status
        })
      }
    return exportData;
  }
}
