import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SafeHtmlPipe} from "../../core/pipes/safeHtml.pipe";


/**
 * Shared Messenger Module
 */
@NgModule({
  declarations: [SafeHtmlPipe],
  exports: [SafeHtmlPipe],
  imports: [CommonModule],
})
export class SafeHtmlModule {
}
