import {createAction, props} from '@ngrx/store';

export const LOAD_USERS = createAction('[USER] Loads User', props<{ payload: any }>());
export const LOAD_USERS_COMPLETE = createAction('[USER] Load Users Complete');
export const LOAD_USERS_FAIL = createAction('[USER] Load Users FAIL');

export const LOAD_USER_DETAILS = createAction('[USER] Load User');
export const LOAD_USER_DETAILS_COMPLETE = createAction('[USER] Load User Complete');
export const LOAD_USER_DETAILS_FAIL = createAction('[USER] Load User FAIL');


export const LOAD_INTERNAL_USERS = createAction('[USER] Load Internal User', props<{ payload: any }>());
export const LOAD_INTERNAL_USERS_COMPLETE = createAction('[USER] Load Internal  User Complete');
export const LOAD_INTERNAL_USERS_FAIL = createAction('[USER] Load Internal  User FAIL');

export const DELETE_INTERNAL_USER = createAction('[USER] Delete Internal User', props<{ payload: any }>());
export const DELETE_INTERNAL_USER_COMPLETE = createAction('[USER] Delete Internal User Complete');
export const DELETE_INTERNAL_USER_FAIL = createAction('[USER] Delete Internal  User FAIL');
