import { Component, OnDestroy } from '@angular/core';
import {PreorderArchiveService} from "./services/preorder-archive.service";
import {UserService} from "../../core/user/services/user.service";
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {PdfService} from "../../core/export/pdf.service";
import {AccountService} from "../../core/account/services/account.rest.service";
import {PreorderArchivedItemModel} from "./models/preorder-archive-item.model";

@Component({
  selector: 'app-preorders-archive',
  standalone: false,
  templateUrl: './preorders-archive.component.html',
  styleUrl: './preorders-archive.component.scss'
})

export class PreordersArchiveComponent implements OnDestroy{
  protected preordersArchive: any[] = [];
  private ngUnsubscribe$ = new Subject<void>();
  displayedColumns: string[] = ['vendor', 'idf', 'period_start', 'period_end', 'download',];
  private selectedPreorderData: any[] = [];
  accountData: any[] = [];

  constructor(private preordersArchiveService: PreorderArchiveService,
              private exportPdfService: PdfService,
              private userService: UserService,
              private accountService: AccountService) {
    this.userService.getMainIdfObservable()?.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((response: any) => {
      if (response.return_object != undefined) {
        this.getPreordersArchive(response.return_object)
      }
    });
  }

  getPreordersArchive($event: any) {
    this.loadShippingAddress($event);

    this.preordersArchiveService.loadPreordersArchive($event).subscribe((result: any) => {
      this.preordersArchive = result.return_object;
      console.log(result.return_object)
    })
  }

  loadShippingAddress(idf: string) {
    this.accountService.getShippingAddress(idf).subscribe((response: any) => {
        this.accountData = response.return_object;
      }
    );
  }

  getPreorder(preorderItem: any) {
    this.selectedPreorderData = [];
    this.preordersArchiveService.loadPreorderItems(preorderItem.idf, preorderItem.pre_order_id)
    .pipe(takeUntil(this.ngUnsubscribe$)).subscribe((items: any) => {
      this.selectedPreorderData = items.return_object;

      const cols: any[] = ['PZN', 'Artikel', 'Bestellmenge\n' + 'Pck.', 'Impfstoffdosen\n' + 'Stk.'];
      const rows: any[] = [];
      const title = 'Bestelleingangsbestätigung\n\n';
      const desc = 'Sehr geehrte Damen und Herren,\n\n' + 'hiermit bestätigen wir Ihnen nachstehende Vorbestellung:\n';
      const fileName = `${preorderItem.idf}_vorbestellung_${preorderItem.vendor}_grippeimpfstoffe`;
      let totalQuantity = 0;

      this.selectedPreorderData.forEach((element: PreorderArchivedItemModel) => {
        let temp: any[];
        const pzn = element.pzn,
          artikel = element.name + ' - ' + element.unit,
          bestellmenge: number = element.quantity !== null ? element.quantity : 0,
          impfstoffdosen: number = parseInt(element.unit, 10) * element.quantity;
        temp = [
          pzn,
          artikel,
          bestellmenge,
          impfstoffdosen
        ];
        rows.push(temp);
        totalQuantity += parseInt(element.unit, 10) * element.quantity;
      });
      const footerTitle = `Ihre Vorbestellung von ${preorderItem.vendor} Impfstoffdosen: ${totalQuantity}`;
      const footerDesc = 'Auslieferung nach Chargenfreigabe durch das Paul-Ehrlich-Institut unter dem Vorbehalt rechtzeitiger, richtiger Selbstbelieferung (somit Auslieferung an Sie nach erfolgtem Wareneingang bei uns).\n\nGrippe-Impfstoffe sind von der Retoure ausgeschlossen. Lieferung in apothekenüblichen Mengen, solange Vorrat reicht. Ergänzend gelten unsere Verkaufs- und Lieferbedingungen. Die Auslieferung der Impfstoffe erfolgt in der Reihenfolge des Bestelleingangs.';
      const account: any = this.accountData;

      let address = `${account.owner ? account.owner : null}\n${account.pharmacy ? account.pharmacy : null}\n${account.street ? account.street : null}\n${account.zip_code ? account.zip_code : null} ${account.city ? account.city : null}`;
      // using '' in string concatenation keeps an empty space so we use null and then replace all null
      address = address.replace(/null /g, '');
      const data: any = {
        desc: desc,
        footerTitle: footerTitle,
        footerDesc: footerDesc,
        date: preorderItem.pre_order_initiated_at
      };

      this.exportPdfService.exportToPdf(cols, rows, fileName, title, address, data);
    });
  }

  /**
   * Unsubscribe from all subscriptions.
   */

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

}
