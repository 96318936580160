import {createAction} from '@ngrx/store';

export const LOAD_SUB_USERS = createAction('[SUBUSER] Load SubUsers');
export const LOAD_SUB_USERS_COMPLETE = createAction('[SUBUSER] Load SubUsers complete');
export const LOAD_SUB_USERS_FAILED = createAction('[SUBUSER] Load SubUsers failed');
export const INIT_AUTH_TREE = createAction('[SUBUSER] Init SubUser Creation');
export const INIT_AUTH_TREE_COMPLETE = createAction('[SUBUSER] Init SubUser Creation complete');
export const INIT_AUTH_TREE_FAILED = createAction('[SUBUSER] Init SubUser Creation failed');
export const ADD_SUB_USER = createAction('[SUBUSER] Add SubUser');
export const UPDATE_SUB_USER = createAction('[SUBUSER] Update SubUser');
export const REMOVE_SUB_USER = createAction('[SUBUSER] Remove SubUser');
