<h1 class="title">Sie haben Ihre Zugangsdaten vergessen?</h1>
<div *ngIf="!submitSuccess">
  <mat-card *ngIf="error !== ''" class="text-center red" [innerHTML]="error"></mat-card>
  <mat-grid-list [cols]="columns" rowHeight="20rem">
    <mat-grid-tile>
      <div>
        <h2 class="subheader">Sind Sie IDF-Inhaber?</h2>
        <p>Bitte geben Sie Ihre IDF-Nummer und die Postleitzahl Ihrer Apotheke ein.</p>
        <form [formGroup]="resetPasswordForm" class="d-flex flex-column reset-form">
          <mat-form-field appearance="outline">
            <mat-label>Haupt IDF</mat-label>
            <input matInput formControlName="idf" tabindex="1">
            <mat-error *ngIf="idf.hasError('required')">
              Haben Sie Ihre Haupt-IDF angegeben? Bitte prüfen.
            </mat-error>
            <mat-error *ngIf="idf.hasError('pattern')">
              Geben Sie eine 7-stellige Zahlenfolge ein.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>PLZ</mat-label>
            <input matInput formControlName="zip_code" tabindex="2">
            <mat-error *ngIf="zip_code.hasError('required')">
              Bitte geben Sie die Postleitzahl Ihrer Haupt-Apotheke ein.
            </mat-error>
            <mat-error *ngIf="zip_code.hasError('pattern')">
              Bitte geben Sie eine gültige Postleitzahl ein.
            </mat-error>
          </mat-form-field>
          <div class="d-flex jcsb">
            <a mat-flat-button color="secondary" routerLink="">Zurück zum Login</a>
            <button mat-flat-button color="primary" (click)="submit()">Senden</button>
          </div>
        </form>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div>
        <h2 class="subheader">Sind Sie Mitbenutzer?</h2>
        <p>Bitte wenden Sie sich an den IDF-Inhaber Ihrer Apotheke. Dieser kann im Apothekenportal unter "Meine
          persönlichen
          Einstellungen" → "Mitbenutzer" Ihr Passwort neu vergeben.</p>
        <a mat-flat-button color="secondary" routerLink="">Zurück zum Login</a>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>

<div *ngIf="submitSuccess">
  <p>
    Eine Email wurde an die Emailadresse {{email}} versandt. <br>
    Sollten Sie Ihre E-Mail-Adresse geändert oder vergessen haben, wenden Sie sich über folgendes
    <a [routerLink]="['/kontakt']" tabindex="1" class="link">Kontaktformular</a> an uns.
  </p>
  <a mat-flat-button color="secondary" routerLink="">Zurück zum Login</a>
</div>
