import {ProductDetails} from './models/product.details.model';
import {ProductType} from './models/product.type.model';
import {ProductBaseModel} from "./product.base.model";

export class ProductModel extends ProductBaseModel {

  description: string;
  image: string;

  sale_status: string;
  details: ProductDetails;

  price: any;
  retail_price: number = 0;
  amount_of_prices: number;
  min_quantity: number = 0;
  thumb: string;
  type: ProductType;
  delivery: string;
  delivery_notice: string;

  campaign_id: string;
  advantage: string;
  available: number;
  can_call_availability: boolean = false;
  shipping: number;
  availability: any;

  constructor(obj: any = {}) {
    super(obj);
    console.log(obj);
    this.description = obj && obj.description || null;
    this.image = obj && obj.image || null;
    this.sale_status = obj && obj.sale_status || null;
    this.content = obj && obj.content || null;
    this.price = obj && obj.price || [{cents: 0, min_quantity: 1}];
    this.description = obj && obj.description || null;
    this.amount_of_prices = 0;
    this.delivery = obj && obj.delivery || null;
    this.delivery_notice = obj && obj.delivery_notice || null;
    this.shipping = obj && obj.shipping || null;
    this.availability = obj && obj.availability;


    /*
     * need to determine how many prices there are in order to display only the last one
     * i can't cancel out all other prices because they are required for calculation
     * could array this.price but would have to adjust logic which works well so i won't
     */
    let min = null;
    let minkey = null;

    for (const key in this.price) {
      const pr = this.price[key].cents;
      if (this.price[key].minQuantity === 0) {
        this.retail_price = pr;
        minkey = key;
        continue;
      }

      if (this.price[key].minQuantity === 1) {
        min = pr;
      }

      this.amount_of_prices++;
    }
    if (minkey != null) {
      this.price.splice(minkey, 1);
    }

    if (!this.retail_price) {
      this.retail_price = min;
    }

    this.thumb = obj && obj.thumb || null;
    if (obj && obj.type) {
      this.type = obj.type;
    } else {
      this.type = ProductType.NONE;
    }
    this.details = obj && obj.details || null;
    this.campaign_id = obj && obj.campaignId || null;
    this.advantage = obj && obj.advantage || 'N/A';
    this.available = obj && this.getAvailability() || 0;
    if (obj && obj.hasOwnProperty('canCallAvailability')) {
      this.can_call_availability = obj.canCallAvailability;
    }

  }

  getMindestbestellmenge() {
    if (this.delivery_notice && this.delivery_notice.indexOf('Mindestbestellmenge') >= 0) {
      return this.delivery_notice.split(' ')[1];
    }
    return null;
  }

  getAvailability() {
    let available = 0;
    if (this.availability) {
      const contigents = this.availability.contingents;


      if (contigents.some((contigent: any) => contigent.type === "SUBSEQUENT_DELIVERY")) {
        available = 2;
      }

      if (contigents.some((contigent: any) => contigent.type === "NORMAL" || contigent.type === "COMBINATION")) {
        available = 1;
      }
    }
    this.available = available;

    return available;
  }

  private setPrice(obj: any) {
    let min = null;
    let minkey = null;
    const price = obj.price;

    for (let key in price) {
      const pr = price[key].cents;
      if (price[key].min_quantity === 0) {
        this.retail_price = pr;
        minkey = key;
        continue;
      }

      if (obj.min_quantity === 1) {
        min = pr;
      }

      this.amount_of_prices++;
    }

    this.price = price;
    if (minkey != null) {
      this.price.splice(minkey, 1);
    }

    if (!this.retail_price) {
      this.retail_price = min;
    }
  }
}


/*
    "pzn": "14046738",
    "image": "https:\/\/pics.phoenix-online.de\/UJF2NkWIeynJdHygCTWdymg5OQAR8Tcl-DzrQYgosdejn7-eQ6KPlJk7cG1a95kt.jpg",
    "name": "BD ULTRA FINE PRO4MM PENNA",
    "aek": 2478,
    "avk": 0,
    "lieferform": "KAN",
    "hersteller": "BECTON DICKINSON GMBH",
    "menge": "105",
    "einheit": "ST",
    "preis": {"1": "1452"}
*/
