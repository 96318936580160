import {Branch} from './branch.model';

export class IdfBranches {

  idf: string;
  branches: Branch[] = [];

  constructor(obj: any) {
    this.idf = obj && obj.idf || null;
    if (obj && obj.branches) {
      this.branches = obj.branches.map((branch: any) => new Branch(branch));
    }
  }
}
