<mat-card *ngIf="error !== '' || success !== ''" class="mb-m p-m">
  <p *ngIf="success !== '' ">
    {{success}}
  </p>
  <p *ngIf="error !== '' ">
    {{error}}
  </p>
</mat-card>

<form [formGroup]="pageForm" class="d-flex jcsb">
  <div class="w-70 flex-column d-flex mt-l">
    <input type="hidden" formControlName="id" />
    <mat-form-field appearance="outline" class="full-field">
      <mat-label>Page Title</mat-label>
      <input matInput formControlName="page_title"/>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Url</mat-label>
      <input matInput formControlName="relative_url"/>
    </mat-form-field>

    <div class="editor">
      <mat-label class="mb-l">Content</mat-label>
      <editor apiKey="8rzuewmpt7g2ex8geevsbvwoosfby3wnmsm3nm7u09p1doro" [init]="{ min_height: 500, menubar: false, toolbar: 'undo redo | visualblocks | blocks | bold italic underline | alignleft aligncenter alignright alignjustify | image link | code | numlist bullist | table | searchreplace' ,
      content_css: '../styles.css',
      advlist_bullet_styles: 'default disc circle square ',
      toolbar_sticky: true}"
              formControlName="content" plugins="visualblocks link code searchreplace lists advlist table autoresize importcss image">
      </editor>
    </div>
  </div>
  <div class="w-30 flex-column d-flex pl-l">
    <h2>Settings</h2>
    <mat-form-field appearance="outline" class="full-field">
      <mat-label>Navigation Title</mat-label>
      <input matInput formControlName="nav_title"/>
    </mat-form-field>
    <div class = "py-m">
      <mat-slide-toggle formControlName="show_title" color=primary>Show Title</mat-slide-toggle>
    </div>
    <div class = "py-m">
      <mat-slide-toggle formControlName="published" color=primary>Published</mat-slide-toggle>
    </div>
    <div class = "py-m">
      <mat-slide-toggle formControlName="public" color=primary>Is public</mat-slide-toggle>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>Back Link</mat-label>
      <input matInput formControlName="back_link"/>
    </mat-form-field>
    <div class="image-area p-m w-100 my-m">
      <img src="{{bannerImage}}" alt="{{pageTitle}}" *ngIf="bannerImage"/>
    </div>
    <button mat-stroked-button class="mb-l" (click)="uploadImage(tile)">Upload Banner image</button>
    <div class="image-area p-m w-50">
      <img src="{{tile}}" alt="{{pageTitle}}" *ngIf="tile"/>
    </div>
    <button mat-stroked-button class="mt-m mb-l" (click)="uploadImage(tile)">Upload Tile image</button>
    <mat-form-field appearance="outline">
      <mat-label>Start Date</mat-label>
      <input matInput formControlName="start_date" type="date"/>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>End Date</mat-label>
      <input matInput formControlName="end_date" type="date"/>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Authorities</mat-label>
      <mat-select formControlName="authorities" multiple>
        <mat-optgroup *ngFor="let authories of authorities" [label]="authories.label">
        <mat-option *ngFor="let authority of authories.authorities | keyvalue" [value] = "authority.key">
          {{ authority.value }}
        </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
    <button mat-flat-button color="primary" (click)="savePage()">Speichern</button>
  </div>
</form>
