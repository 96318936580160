import {SubUserModel} from '../../user/subuser.model';
import {AuthorizationNodeModel} from '../../user/models/authorization.node.model';
import {phx} from "../@types/models";
import {createReducer, on} from '@ngrx/store';
import {
  ADD_SUB_USER,
  INIT_AUTH_TREE,
  INIT_AUTH_TREE_COMPLETE,
  INIT_AUTH_TREE_FAILED,
  LOAD_SUB_USERS,
  LOAD_SUB_USERS_COMPLETE,
  LOAD_SUB_USERS_FAILED,
  REMOVE_SUB_USER,
  UPDATE_SUB_USER
} from "../actions/subuser.actions";
import SubUser = phx.portal.models.SubUser;

export const initialState: SubUser = {} as SubUser;

export const subUserReducer = createReducer(
  initialState,
  on(LOAD_SUB_USERS, (state: any) => {
    return {
      ...state,
      subUserLoading: true
    }
  }),
  on(LOAD_SUB_USERS_COMPLETE, (state: any, data: any) => {
    return {
      ...state,
      ...data.payload,
      subUsersLoading: false
    };
  }),
  on(LOAD_SUB_USERS_FAILED, (state: any, data: any) => {
    return {
      ...state,
      ...data.payload,
      subUsersLoading: false
    };
  }),
  on(INIT_AUTH_TREE, (state: any) => {
    return {
      ...state,
      authTreeLoading: true
    };
  }),
  on(INIT_AUTH_TREE_COMPLETE, (state: any, data: any) => {
    const authTree = [new AuthorizationNodeModel({label: 'SUBUSER.ALL_RIGHTS'})];
    authTree[0].children = data.payload.map((node: any) => new AuthorizationNodeModel(node));
    return {
      ...state,
      authTree: authTree,
      authTreeLoading: false
    };
  }),
  on(INIT_AUTH_TREE_FAILED, (state: any, data: any) => {
    return {
      ...state,
      ...data.payload,
      authTreeLoading: false
    };
  }),
  on(ADD_SUB_USER, (state: any, data:any)=>{
    return {
      ...state,
      ...data.payload
    }
  }),
  on(UPDATE_SUB_USER,(state: any, data:any)=>{
    return {
      ...state,
      ...data.payload
    }
  }),
  on(REMOVE_SUB_USER, (state: any, data:any)=>{
    return {
      ...state,
      ...data.payload
    }
  })
)
