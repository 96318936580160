import {Component, OnDestroy, OnInit} from '@angular/core';
import {RevenueRestService} from "../../core/revenue/services/revenue.rest.service";
import {UserService} from "../../core/user/services/user.service";
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {CsvService} from "../../core/export/csv.service";
import {PdfService} from "../../core/export/pdf.service";
import {AccountService} from "../../core/account/services/account.rest.service";

@Component({
  selector: 'app-revenue',
  templateUrl: './revenue.component.html',
  styleUrls: ['./revenue.component.scss']
})
export class RevenueComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$ = new Subject<boolean>();
  public chartOptions: any;
  public revenue: any;
  public today: Date = new Date();
  private selectedIdf: any = null;

  constructor(private revenueRestService: RevenueRestService,
              private userService: UserService,
              private exportCsvService: CsvService,
              private accountRestService: AccountService,
              private exportPdfService: PdfService) {
    this.userService.getMainIdfObservable()?.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(response => {
      if (response.return_object != undefined) {
        this.requestRevenue(response.return_object)
        this.selectedIdf = response.return_object;
      }
    });
  }

  ngOnInit() {
    this.chartOptions = {
      title: {
        text: 'Monatlicher Umsatzverlauf',
        textStyle: {
          // fontFamily: 'Raleway SemiBold',
          fontFamily: 'Arial',
          fontSize: 12,
          color: '#ed2d2e'
        }
      },
      xAxis: {
        data: ['Jan', 'Feb', 'März', 'April', 'Mai', 'Juni', 'Juli', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: 'Umsatz',
          data: [820, 932, 901, 934, 1290, 1330, 1320, 215, 879, 654, 825, 454],
          type: 'line'
        }
      ]
    }
  }

  requestRevenue(idf: any) {
    this.revenueRestService.requestRevenue(idf).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((res) => {
      this.revenue = res.return_object;
      this.selectedIdf = idf;
      this.loadRevenue(res.return_object);
    });
  }

  loadRevenue(data: any) {
    const filteredChart = Array.prototype.slice.call(data.chart.series);
    filteredChart.splice(2, 1);
    filteredChart.splice(0, 1);
    this.chartOptions = {
      title: {
        text: 'Monatlicher Umsatzverlauf',
        textStyle: {
          fontFamily: 'Raleway SemiBold'
        }
      },
      tooltip: {
        trigger: 'axis',
        formatter: this.formatTooltip
      },
      xAxis: {
        data: data.chart.x_axis
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: this.formatYAxisLabel
        }
      },
      series: filteredChart,
      legend: {
        bottom: 0,
        left: 120,
        itemGap: 35
      },
      color: [
        '#ed1c24',
        '#545454',
        '#2699fb',
        '#91c7ae',
        '#2699fb',
      ],
    }
  }

  /**
   * download revenue as CSV
   */
  downloadCsv() {
    const months: string[] = ['Typ'].concat(this.revenue.chart.x_axis);
    const values: any[] = this.prepareData(months, this.revenue.chart.series);
    this.exportCsvService.exportToCsv(months, values, 'Monatlicher_Umsatzverlauf_' + this.selectedIdf);
  }


  /**
   * download revenue as PDF
   */
  downloadPdf() {
    const months: string[] = ['Typ'].concat(this.revenue.chart.x_axis);
    const values: any[] = this.prepareData(months, this.revenue.chart.series, true);
    console.log(this.revenue);
    if (this.selectedIdf !== 9999910) {
      this.accountRestService.getShippingAddress(this.selectedIdf).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(response => {
        this.exportPdfService.exportToPdf(months, values, 'Monatlicher_Umsatzverlauf_' + this.selectedIdf, 'Monatlicher Umsatzverlauf', Object.values(response.return_object));
      });
    } else {
      this.exportPdfService.exportToPdf(months, values, 'Monatlicher_Umsatzverlauf_' + this.selectedIdf, 'Monatlicher Umsatzverlauf');
    }
  }

  prepareData(header: string[], rows: any[], array: boolean = false) {
    let values: any[] = [];
    rows.forEach((value: any) => {
      if (array) {
        let row: any[] = [];
        row = [value.name].concat(value.data);
        values.push(row);
      } else {
        let row: any = {}
        row[header[0]] = value.name

        value.data.forEach((column: any, ind: any) => {
          row[header[ind + 1]] = column;
        })
        values.push(row);
      }
    });
    return values;
  }

  formatYAxisLabel(value: { toLocaleString: (arg0: string) => string; }) {
    return value.toLocaleString('de-DE') + '€';
  }

  formatTooltip(params: string | any[]) {
    let res = params[0].name;
    for (let i = 0, l = params.length; i < l; i++) {
      res += '<br/>' + params[i].marker + params[i].seriesName + ': ' + params[i].value.toLocaleString('de-DE') + '€';
    }
    return res;
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(false);
    this.ngUnsubscribe$.complete();
  }

}
