import {phx} from "../@types/models";
import {createReducer, on} from '@ngrx/store';
import * as UserAdminActions from "../actions/users.actions";
import Users = phx.portal.models.Users;

export const initialState: Users = {} as Users;

export const usersReducer = createReducer(  initialState,
  on(UserAdminActions.LOAD_USERS, (state: any) => {
    return {
      ...state,
      usersLoading: true
    }
  }),
  on(UserAdminActions.LOAD_USERS_COMPLETE, (state: any, data: any) => {
    return {
      ...state,
      ...data.payload,
      usersLoading: false
    }
  }),
  on(UserAdminActions.LOAD_USERS_FAIL, (state: any, data: any) => {
    return {
      ...state,
      ...data.payload,
      usersLoading: false
    }
  }),
  on(UserAdminActions.LOAD_INTERNAL_USERS, (state: any) => {
    return {
      ...state,
      usersLoading: true
    }
  }),
  on(UserAdminActions.LOAD_INTERNAL_USERS_COMPLETE, (state: any, data: any) => {
    return {
      ...state,
      ...data.payload,
      usersLoading: false
    }
  }),
  on(UserAdminActions.LOAD_INTERNAL_USERS_FAIL, (state: any, data: any) => {
    return {
      ...state,
      ...data.payload,
      usersLoading: false
    }
  }),
  on(UserAdminActions.DELETE_INTERNAL_USER, (state: any) => {
    return {
      ...state,
      usersLoading: true
    }
  }),
  on(UserAdminActions.DELETE_INTERNAL_USER_COMPLETE, (state: any, data: any) => {
    return {
      ...state,
      ...data.payload,
      usersLoading: false
    }
  }),
  on(UserAdminActions.DELETE_INTERNAL_USER_FAIL, (state: any, data: any) => {
    return {
      ...state,
      ...data.payload,
      usersLoading: false
    }
  })
);
