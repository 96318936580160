<div class="d-flex flex-column">
  <form [formGroup]="quickSearch" (submit)="doQuickSearch(1)" class="d-flex flex-column">
    <mat-radio-group
        aria-labelledby="IDF Auswahl"
        class="d-flex flex-column" *ngIf="!isAdmin && idfs.length > 1">
      <div class="flex flex-row">
        <mat-radio-button value="{{idf.idf}}" *ngFor="let idf of idfs"
                          [checked]="selectedIdf===idf.idf">{{ idf.idf }} {{ idf.name }}
        </mat-radio-button>
      </div>
      <mat-error *ngIf="noIdf" class="text-small">Bitte wählen Sie Ihre IDF</mat-error>
    </mat-radio-group>
    <mat-radio-group
        aria-labelledby="IDF Auswahl"
        class="d-flex flex-row" *ngIf="!isAdmin && idfs.length === 1">
      <mat-radio-button value="{{idf.idf}}" *ngFor="let idf of idfs"
                        [checked]="idf.idf">{{ idf.idf }} {{idf.name}}
      </mat-radio-button>
      <mat-error *ngIf="noIdf">Bitte wählen Sie Ihre IDF</mat-error>
    </mat-radio-group>
    <mat-form-field appearance="outline" class="mt-m mr-s">
      <mat-label>Belegnummer</mat-label>
      <input matInput formControlName="docNr">
    </mat-form-field>
    <div>
      <button mat-flat-button type="submit" color="primary">Suche</button>
    </div>
  </form>
  <!--          <form [formGroup]="quickPZNSearch" (submit)="doQuickPZNSearch(1)">-->
  <!--            <h4>Schnellsuche nach PZN Nummer</h4>-->
  <!--            <mat-form-field appearance="outline" class="mt-s mr-s">-->
  <!--              <mat-label>PZN nummer</mat-label>-->
  <!--              <input matInput formControlName="pzn">-->
  <!--            </mat-form-field>-->
  <!--          </form>-->
</div>
