export class FormAnswers {
  form: number;
  answers: Answer[]

  constructor(obj: any) {
    this.form = obj && obj.form || null;
    this.answers = obj && obj.answers || null;
  }
}

export class Answer {
  field: string;
  answer: string

  constructor(question: any, answer: any) {
    this.field = question;
    this.answer = answer;
  }
}
