import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {JwtModule} from '@auth0/angular-jwt';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DatePipe, registerLocaleData} from '@angular/common';
import {LoginComponent} from './public/login/login.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {PageNotFoundComponent} from './shared/page-not-found/page-not-found.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthService} from './core/auth/services/auth.service';
import {EffectsModule} from '@ngrx/effects';
import {ENV_PROVIDERS, environment} from '../environments/environment';
import {UserService} from './core/user/services/user.service';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {UserEffects} from './core/store/effects/user.effects';
import {SubUserEffects} from './core/store/effects/sub-user.effects';
import {InvoicesEffects} from './core/store/effects/invoices.effects';
import {SubsequentDeliveriesEffects} from './core/store/effects/subsequent.deliveries.effects';
import {subsequentDeliveriesReducer} from './core/store/reducers/subsequent.deliveries.reducer';
import {userReducer} from './core/store/reducers/user.reducer';
import {subUserReducer} from './core/store/reducers/subuser.reducer';
import {SubUserService} from './core/user/services/subuser.service';
import {UserRestService} from './core/user/services/user.rest.service';
import {SubUserRestService} from './core/user/services/subuser.rest.service';
import {SubsequentDeliveriesRestService} from './core/deliveries/services/subsequent.deliveries.rest.service';
import {AdsRestService} from './core/cms/services/ads.rest.service';
import {FormsRestService} from './core/cms/services/forms.rest.service';
import {PagesRestService} from './core/cms/services/pages.rest.service';
import {AccountService} from './core/account/services/account.rest.service';
import {InvoicesRestService} from './core/invoices/services/invoices.rest.service';
import {ProductRestService} from './core/products/services/product.rest.service';
import {VaccineRestService} from './core/vaccines/services/vaccination.rest.service';
import {CartService} from './core/sales/services/cart.service';
import {AuthInterceptor} from './core/base/auth.interceptor';
import {invoiceReducer} from './core/store/reducers/invoices.reducer';
import {InvoicesService} from './core/invoices/services/invoices.service';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CsvService} from './core/export/csv.service';
import {PdfService} from './core/export/pdf.service';
import {ContactComponent} from './shared/contact/contact.component';
import {MatCardModule} from '@angular/material/card';
import {PublicModule} from './public/public.module';
import {PrivateModule} from './private/private.module';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {CUSTOM_DATE_FORMAT} from './core/base/date.formats';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ProductSearchService} from './core/products/services/product.search.service';
import {ProductService} from './core/products/services/product.service';
import {OrderService} from './core/sales/services/order.service';
import {ProductsEffects} from './core/store/effects/products.effects';
import {productReducer} from './core/store/reducers/product.reducer';
import {SearchRestService} from './core/products/services/search.rest.service';
import {AdminSectionModule} from "./admin/admin.section.module";
import {BlobService} from "./core/base/blob.service";
import {BlobRestService} from "./core/base/blob.rest.service";
import {DialogModule} from "./shared/dialog/dialog.module";
import {ConfirmationDialogModule} from "./shared/confirmation-dialog/confirmation-dialog.module";
import {NotificationsService} from "./core/notifications/services/notifications.service";
import {ErrorInterceptor} from "./core/base/error.interceptor";
import {DialogRestService} from "./core/cms/services/dialog.rest.service";
import {SafeHtmlModule} from "./shared/safehtml/safehtml.module";
import {usersReducer} from "./core/store/reducers/users.reducer";
import {UsersEffects} from "./core/store/effects/users.effects";
import {CarouselComponent} from "./shared/carousel/carousel.component";
import {MatomoRouterModule, MatomoModule, MatomoRouteDataInterceptor} from 'ngx-matomo-client';
import {TrackTraceRestService} from "./core/track-trace/services/track.trace.rest.service";

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export function tokenGetter() {
  return localStorage.getItem('token');
}


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotFoundComponent,
    ContactComponent,
    CarouselComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DialogModule,
    ConfirmationDialogModule,
    FormsModule,
    PublicModule,
    PrivateModule,
    AdminSectionModule,
    MatDividerModule,
    MatGridListModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    HttpClientModule,
    DatePipe,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot({
      subsequentDeliveries: subsequentDeliveriesReducer,
      user: userReducer,
      subUser: subUserReducer,
      invoice: invoiceReducer,
      product: productReducer,
      users: usersReducer
    }),
    !environment.production ? StoreDevtoolsModule.instrument({connectInZone: true}) : [],
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["localhost", "hageda-dev.phoenix-apothekenportal.de", "hagedastumpf-apothekenportal.de"],
        disallowedRoutes: [],

        throwNoTokenError: true
      },
    }),
    EffectsModule.forRoot(
      [
        UserEffects,
        SubUserEffects,
        InvoicesEffects,
        SubsequentDeliveriesEffects,
        ProductsEffects,
        UsersEffects
      ]
    ),
    MatTooltipModule,
    SafeHtmlModule,
    MatCardModule,
    MatomoModule.forRoot({
       trackerUrl: 'https://phoenixgroup.matomo.cloud/', siteId: '1'    }),
    MatomoRouterModule.forRoot({
      interceptors: [MatomoRouteDataInterceptor],
      exclude: [/page/],
    }),
  ],
  providers: [
    ENV_PROVIDERS,
    AuthService,
    BlobService,
    BlobRestService,
    UserService,
    SubUserService,
    UserRestService,
    SubUserRestService,
    SubsequentDeliveriesRestService,
    AdsRestService,
    FormsRestService,
    NotificationsService,
    PagesRestService,
    AccountService,
    InvoicesRestService,
    VaccineRestService,
    CartService,
    InvoicesService,
    PdfService,
    CsvService,
    OrderService,
    ProductRestService,
    ProductSearchService,
    ProductService,
    SearchRestService,
    [{
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: ErrorInterceptor,
        multi: true,
      },
      {
        provide: LOCALE_ID,
        useValue: 'de-DE'
      }],
    DatePipe,
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT},
    DialogRestService,
    TrackTraceRestService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
