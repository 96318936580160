// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  qa: false,
  dev: true,
  isDebugMode: true
};

let PROVIDERS: any[] = [
  /**
   * Common env directives
   */
];

PROVIDERS = [
  ...PROVIDERS,
  {provide: 'AUTH_URL', useValue: 'https://dev-gateway.hagedastumpf-apothekenportal.de'},
  {provide: 'API_URL', useValue: 'https://dev-gateway.hagedastumpf-apothekenportal.de'},
  {provide: 'CMS_URL', useValue: 'cms'},
  {provide: 'RESOURCE', useValue: 'resource'},
  {provide: 'AZURE', useValue: 'azure'},
  {provide: 'USERMANAGEMENT', useValue: 'usermanagement'},
  {provide: 'CDN', useValue: 'https://dehapdsacc.blob.core.windows.net'},
  {provide: 'STORAGE', useValue:'https://dehapdsacc.blob.core.windows.net'}
];

export const ENV_PROVIDERS = [
  ...PROVIDERS
];
