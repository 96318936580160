import {Component, OnInit} from '@angular/core';
import {fadeIn, fadeOut} from "./carousel.animations";
import {transition, trigger, useAnimation} from "@angular/animations";
import {NgForOf, NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-carousel',
  // standalone: true,
  // imports: [
  //   NgForOf,
  //   NgOptimizedImage
  // ],
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
  animations: [
    trigger('slideAnimation', [
      /* fade */
      transition('void => *', [
        useAnimation(fadeIn, { params: { time: '1500ms' } })
      ]),
      transition('* => void', [
        useAnimation(fadeOut, { params: { time: '1500ms' } })
      ]),
    ])
  ]
})
export class CarouselComponent implements OnInit {
  currentSlide = 0;
  carouselInterval: any;

  public slides = [
    { src: "https://dehappsacc.blob.core.windows.net/cms/images/login/hageda-login-carousel-1.png" },
    { src: "https://dehappsacc.blob.core.windows.net/cms/images/login/hageda-login-carousel-2.jpg" },
  ];

  // preloadImages() {
  //   for (const slide of this.slides) {
  //     new Image().src = slide.src;
  //   }
  // }
  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
  }


  ngOnInit(): void {
    this.carouselInterval = setInterval(
      () => this.onNextClick(), 5000
    );
  }

  ngOnDestroy() {
    clearInterval(this.carouselInterval);
  }
}
