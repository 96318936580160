import {Inject, Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';

import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import {AuthService} from "../auth/services/auth.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(public router: Router,
              private authService: AuthService) {
  }

  /**
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler,): Observable<HttpEvent<any>> {

    return next.handle(request)
    .pipe(tap((event: HttpEvent<any>) => {
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            // redirect to login
            this.authService.logout();
            this.router.navigate(['/']);
            return;
          } else if (err.status === 503) {
            this.router.navigate(['fehler'], {queryParams: {error: JSON.stringify(err)}});
          }  else if (err.error != null && err.error.hasOwnProperty('errorCode')) {
            return;
          }
        }
      })
    );
  }
}
