import {createAction, props} from '@ngrx/store';
import {DocTypesRequest} from "../../invoices/api/doc.types.request.model";
import {InvoicesRequest} from "../../invoices/api/invoices.request.model";

export const SET_BRANCHES_FOR_INVOICE_SEARCH = createAction('[INVOICES] Set branch for invoice search');
export const GET_INVOICES_BY_BRANCH_ID = createAction('[INVOICES] Get invoices by branchId');
export const LOAD_SEARCH_INVOICES = createAction('[INVOICES] Load Search Invoices', props<{ payload: InvoicesRequest }>());
export const SEARCH_INVOICES_COMPLETE = createAction('[INVOICES] Search Invoices Complete');
export const SEARCH_INVOICES_FAIL = createAction('[INVOICES] Search Invoices Fail');
export const RESET_SEARCH_INVOICES_RESPONSE = createAction('[INVOICES] Reset invoice response');
export const LOAD_DOC_TYPES = createAction('[INVOICES] Load User Document Types', props<{ payload: DocTypesRequest }>());
export const LOAD_DOC_TYPES_COMPLETE = createAction('[INVOICES] Load User Document Types complete');
export const LOAD_DOC_TYPES_FAIL = createAction('[INVOICES] Load User Document Types FAIL');
export const LOAD_ALL_DOC_TYPES = createAction('[INVOICES] Load All User Document Types');
export const LOAD_ALL_DOC_TYPES_COMPLETE = createAction('[INVOICES] Load All User Document Types complete');
export const LOAD_ALL_DOC_TYPES_FAIL = createAction('[INVOICES] Load All User Document Types FAIL');
export const SHOW_INVOICE_CONTACT_FORM_VIEW = createAction('[INVOICES] Show Contact Form in View');
export const HIDE_INVOICE_CONTACT_FORM_VIEW = createAction('[INVOICES] hide Contact Form in View');
