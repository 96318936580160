import {AuthorizationNodeInterface} from '../interfaces/authorization.node.interface';

export class AuthorizationNodeModel implements AuthorizationNodeInterface {
  authority: string;
  children: AuthorizationNodeModel[] = [];
  granted: number = 0;
  label: string;
  readonly: boolean = false;
  idf: string = '';
  expandable: boolean = false;
  level: number = 0;
  feature: string;

  constructor(obj?: any) {
    if (obj && obj.idf === true) {
      this.idf = obj.label;
    }
    if (obj && obj.idf && (typeof obj.idf === 'string' || obj.idf instanceof String)) {
      this.idf = obj.idf;
    }
    if (obj && obj.children && obj.children.length > 0) {
      this.children = obj.children.map((node:any) => new AuthorizationNodeModel({...node, idf: this.idf})) || null;
    } else {
      this.children = [];
    }

    this.authority = obj && obj.authority || null;
    this.label = obj && obj.label || null;
    this.readonly = obj && obj.readonly || null;
    this.expandable = obj && obj.expandable || null;
    this.level = obj && obj.level || null;
    this.feature = obj && obj.feature || null;
  }
}
