export class SubsequentDeliveriesRequest{
  private _idf: string;

  constructor(obj: any = {}) {
    this._idf = obj.idf
  }

  public getIdf(): string {
    return this._idf;
  }

  public setIdf(value: string) {
    this._idf = value;
  }
}
