<h1 class="title">Registrierung</h1>
<app-register-form [token]="token" *ngIf="token"></app-register-form>

<mat-stepper linear #stepper labelPosition="bottom" *ngIf="!token">
  <mat-step [stepControl]="registerForm">
    <form [formGroup]="registerForm" class="d-flex flex-column mt-s">
        <mat-form-field appearance="outline">
          <mat-label>Haupt IDF</mat-label>
          <input matInput formControlName="idf" tabindex="1" placeholder="Haupt-IDF, z.B. 0123456">
          <mat-error *ngIf="idf.hasError('required')">
            Haben Sie Ihre Haupt-IDF angegeben? Bitte prüfen.
          </mat-error>
          <mat-error *ngIf="idf.hasError('pattern')">
            Geben Sie eine 7-stellige Zahlenfolge ein.
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>PLZ</mat-label>
          <input matInput formControlName="zipCode" tabindex="2">
          <mat-error *ngIf="zipCode.hasError('required')">
            Bitte geben Sie die Postleitzahl Ihrer Haupt-Apotheke ein.
          </mat-error>
          <mat-error *ngIf="zipCode.hasError('pattern')">
            Bitte geben Sie eine gültige Postleitzahl ein.
          </mat-error>
        </mat-form-field>
      <div>
        <mat-checkbox [required]="true" formControlName="agb">
          Ich erkläre mich mit den <a class="mr-2 pb-1 link" [routerLink]="'/open/page/nutzungsbedingungen'"
                                      [queryParams]="{heading: 'Nutzungsbedingungen HAGEDA Portal'}">
          Nutzungsbedingungen
        </a>
          von Hageda-Stumpf GmbH & Co. KG einverstanden und habe die <a class="link" target="_blank" href="/open/page/datenschutz">Hinweise zum Datenschutz</a> zur Kenntnis genommen.
        </mat-checkbox>
        <mat-error *ngIf="agb.hasError('required')">
          Bitte akzeptieren Sie die Nutzungsbedingungen!
        </mat-error>
      </div>
      <div class="my-m">
        <button mat-button matStepperPrevious type="button"><mat-icon fontSet="material-symbols-outlined" aria-hidden="false">arrow_back</mat-icon> Zurück
        </button>
        <button mat-button matStepperNext type="button" (click)="submit(); $event.stopPropagation()">Weiter <mat-icon fontSet="material-symbols-outlined" aria-hidden="false">arrow_forward</mat-icon>
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <div class="success" *ngIf="success">
      <h2 class="subheader">Vielen Dank für Ihre Registrierung.</h2>
      <div>
      Um diese vollständig abzuschließen, haben wir Ihnen eine
      Nachricht an folgende E-Mail Adresse gesendet: <h4 class='my-m'>{{response.return_object}}</h4>
      </div>
      <p>
      Bei Problemen oder Fragen zur Registrierung nutzen Sie bitte unser
      <a href="/kontakt" class="link">Kontaktformular</a>.
      </p>
      <button routerLink="/" mat-button><mat-icon fontSet="material-symbols-outlined" aria-hidden="false">arrow_back</mat-icon>Zurück zur Startseite</button>
    </div>
    <div class="error" *ngIf="error && !missingData">
      <h2 class="subheader">Ihre Anfrage konnte nicht entgegengenommen werden.</h2>
      <h3 class="">Das liegt möglicherweise an:</h3>
      <ul>
        <li>Einem Schreibfehler bei Eingabe der Haupt-IDF oder Postleitzahl</li>
        <li>Sie haben versucht, eine Filiale zu registrieren. Die Registrierung erfolgt über die Haupt-IDF,
          bitte wenden Sie sich an den/die Inhaber/in.</li>
        <li>Die Haupt-IDF ist eventuell bereits registriert. Bitte in diesem Fall die Funktion
          <a href="/forgotten-password" class="link">Passwort vergessen</a> nutzen.</li>
      </ul>
      <a href="/kontakt" class="link mr-s">Kontaktformular</a>
    <button mat-button (click)="stepper.reset()">Reset</button>
    </div>
    <div class="error" *ngIf="missingData && error">
      <app-missing-data [data]="data"></app-missing-data>
    </div>
  </mat-step>
</mat-stepper>
