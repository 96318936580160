export class ProductRequest {
  search: string;
  idf: string;
  page: number;
  size: number;
  availableOnly: boolean;

  constructor(obj: any = {}) {
    this.search = obj && obj.search || '';
    this.page = obj && obj.page || 0;
    this.idf = obj && obj.idf || '';
    this.size = obj && obj.size || 100;
    this.availableOnly = obj && obj.available_only || false;
  }
}
