<header class="header container d-flex aic jcsb">
  <div class="d-flex aic">
    <a routerLink="/dashboard">
      <img src="assets/images/hageda-stumpf-klein-logo.svg" alt="Hageda Stumpf klein logo">
    </a>
    <button mat-button color="neutral" class="ml-m" routerLink="/belege" *ngIf="canAccess(['BULK_BILL'])">BELEGE</button>
    <button mat-button color="neutral" routerLink="/umsatz" *ngIf="canAccess(['MY_SALES'])">UMSATZ</button>
    <button mat-button color="neutral" routerLink="/nachlieferung" *ngIf="canAccess(['NACH'])">NACHLIEFERUNG</button>
    <button mat-button color="neutral" routerLink="/shop" *ngIf="canAccess(['ORDERS'])">PRODUKTSUCHE</button>

    <button mat-button color="neutral" routerLink="/vorbestellung-archiv" *ngIf="canAccess(['ORDERS'])">VORBESTELLUNG ARCHIV</button>
    <button mat-button color="neutral" routerLink="/kontaktformular" *ngIf="canAccess(['CONTACT_PERSON'])">KONTAKT</button>
    <button mat-flat-button color="primary" routerLink="/admin/admin-dashboard" *ngIf="isAdmin()">
      ADMINISTRATION
    </button>
  </div>
  <div class="d-flex aic">
    <button mat-icon-button color="neutral" (click)="goToSubusers()" *ngIf="canAccess(['GROUP_ACCOUNT_OWNER'])" >
      <mat-icon fontSet="material-symbols-outlined" color="neutral" aria-hidden="false">supervisor_account</mat-icon>
    </button>
    <button mat-icon-button color="neutral" class="text-uppercase" routerLink="/stammdaten" *ngIf="canAccess(['GROUP_ACCOUNT_OWNER'])">
      <mat-icon fontSet="material-symbols-outlined" aria-hidden="false">person</mat-icon>
    </button>
    <button mat-icon-button color="neutral" class="text-uppercase" routerLink="/logout">
      <mat-icon fontSet="material-symbols-outlined" aria-hidden="false">logout</mat-icon>
    </button>
  </div>
</header>
