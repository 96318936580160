import { Component } from '@angular/core';
import {UserService} from "../../../core/user/services/user.service";
import {FormBuilder, Validators} from "@angular/forms";
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {InvoicesRequest} from "../../../core/invoices/api/invoices.request.model";
import {Router} from "@angular/router";
import {InvoicesService} from "../../../core/invoices/services/invoices.service";

@Component({
  selector: 'app-search-widget',
  templateUrl: './search-widget.component.html',
  styleUrl: './search-widget.component.scss'
})
export class SearchWidgetComponent {
  private ngUnsubscribe$ = new Subject<boolean>();
  public isAdmin = false;
  public idfs: any[] = [];
  public noIdf = true;
  public selectedIdf = '9999910';

  quickSearch = this.fb.group({
    docNr: ['', Validators.required],
    idf: ['', Validators.required]
  })

  quickPZNSearch = this.fb.group({
    pzn: ['']
  })

  constructor(private fb: FormBuilder,
              private userService: UserService,
              public router: Router,
              private invoiceService: InvoicesService){

    this.userService.getUserLoadingObservable().pipe(takeUntil(this.ngUnsubscribe$)).subscribe((load: any) => {
      if (!load) {
        this.isAdmin = this.userService.isAdmin();
      }
    });

    this.userService.getIdfsObservable('invoice')?.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((response: any) => {
      if (response != undefined && response.hasOwnProperty('idfs')) {
        this.idfs = response.idfs;
        this.selectedIdf = this.idfs[0];

        if(this.idfs.length > 0) {
          this.quickSearch.patchValue({idf: this.idfs[0].idf});
          this.noIdf = false;
        }
      }
    });
  }

  doQuickSearch(stepper: any) {
    const values = this.quickSearch.value;
    if(!this.quickSearch.valid) {
      return;
    }
    const now = new Date();
    const endDate = now.toISOString().split('T')[0];
    const startDate = new Date(now.setFullYear(now.getFullYear() - 1)).toISOString().split('T')[0];
    let request = new InvoicesRequest({
      idf: values.idf,
      branch: 21,
      startDate: startDate,
      endDate: endDate,
      docType: "ALL",
      docNr: values.docNr
    });

    console.log(request);
    this.invoiceService.quickNrSearch.next(request);
    this.router.navigate(['/belege'])
  }

  doQuickPZNSearch(stepper: any) {
    console.log('pzn search')
  }
}
