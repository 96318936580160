import { SubsequentDeliveryUpdateModel } from '../models/subsequent.delivery.update.model';

export class SubsequentDeliveriesUpdateRequest {
  branchnumber: string;
  customernumber: string;
  subsequentdeliveries : SubsequentDeliveryUpdateModel[] = [];

  constructor(obj: any = {}) {
    this.branchnumber = obj.branchnumber;
    this.customernumber = obj.idf;
    this.subsequentdeliveries.push(new SubsequentDeliveryUpdateModel(obj));
  }

  getIdf(){
    return this.customernumber;
  }
}
