export enum ProductType {
  TopPrisma = 'TOPPRISMA',
  DokuLight = 'DOKULIGHT',
  Vormerkungen = 'vormerkungen',
  Paragraph73 = 'PARAGRAPH_73',
  Promo = 'PROMO',
  Payback = 'PAYBACK',
  PrivateStock = 'PRIVATE_STOCK',
  NONE = 'UNKNOWN',
  Uber = 'UBER'
}
