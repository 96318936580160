import {map, takeUntil} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {RestServiceAbstract} from '../../base/rest.service.abstract';
import {Observable, Subject} from 'rxjs';
import {TrackTraceAllRequest} from "../api/track.trace.all.request";
import {ResponseModel} from "../../base/response.model";
import {TrackTraceRequest} from "../api/track.trace.request";

@Injectable({
  providedIn: 'root',
})
export class TrackTraceRestService extends RestServiceAbstract {

  public ngUnsubscribe$: Subject<void> = new Subject<void>();

  public getAllTours(allTours: TrackTraceAllRequest){
    return this.post(`/${this.RESOURCE}/shipmenttracking/all`, allTours).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return new ResponseModel(data);
      })
    );
  }

  public getTours(tours: TrackTraceRequest){
    return this.post(`/${this.RESOURCE}/shipmenttracking`, tours).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return new ResponseModel(data);
      })
    );
  }

  getLatestTours(request: TrackTraceRequest) {
    return this.post(`/${this.RESOURCE}/shipmenttracking/latest`, request).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return new ResponseModel(data);
      })
    );
  }
}
