<mat-stepper linear #stepper labelPosition="bottom" *ngIf="!finish">
  <mat-step>
    <div *ngIf="loading">Ihre Daten werden geladen.</div>
    <div *ngIf="!loading && !correction">
      <div class="d-flex jcsb">
        <div>
          <h2>Persönliche Angaben</h2>
          <p>{{accountInfo.salutation}}</p>
          <p>{{accountInfo.title}}</p>
          <p>{{accountInfo.first_name}}</p>
          <p>{{accountInfo.last_name}}</p>
        </div>
        <div>
          <h2>Angaben zur Apotheke</h2>
          <p>{{accountInfo.name}}</p>
          <p>IDF-Nummer {{accountInfo.idf}}</p>
          <p>{{accountInfo.street}}</p>
          <p>{{accountInfo.zip_code + " " + accountInfo.city}}</p>
          <p>E-Mail {{accountInfo.email}}</p>
          <p>Tel.Nr. {{accountInfo.phone}}</p>
          <p>Fax.Nr. {{accountInfo.fax}}</p>
        </div>
      </div>
      <button mat-button matStepperNext color="primary" type="button">Angaben bestätigen</button>
      <button mat-button (click)="correct()">Angaben korrigieren</button>
    </div>
    <div class *ngIf="correction && !loading">
      <app-data-check [data]="accountInfo" [token]="token" (continue)="checked($event, stepper)"></app-data-check>
    </div>
  </mat-step>
  <mat-step>
    <div *ngIf="corrected" class="mb-m">
      <h3>Änderungen übermittelt</h3>
      <p>Ihr Änderungswunsch wurde erfolgreich übermittelt. Ihre Apothekenstammdaten werden nach erfolgter Prüfung i. d.
        R. innerhalb von 2 Werktagen aktualisiert.</p>
    </div>
    <app-password [token]="token" (success)="onSuccess($event)"></app-password>
    <button mat-button (click)="stepper.reset()">Reset</button>
  </mat-step>
</mat-stepper>
<div *ngIf="finish">
  <h3>Willkommen im Hageda-Stumpf Apothekenportal {{accountInfo.salutation}} {{accountInfo.last_name}}</h3>
  <p class="pt-2">
    Sie haben die Registrierung für das Hageda-Stumpf Apothekenportal erfolgreich abgeschlossen. Ihre persönlichen
    Angaben und Ihr Passwort können Sie jederzeit im Apothekenportal ändern.
  </p>
  <p>
    <button mat-button color="primary" (click)="login()">Zum Apothekenportal</button>
  </p>
</div>
