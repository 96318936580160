import {Component, OnInit, OnDestroy} from '@angular/core';
import {TrackTraceRestService} from "../../../core/track-trace/services/track.trace.rest.service";
import {TrackTraceRequest} from "../../../core/track-trace/api/track.trace.request";
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {UserService} from "../../../core/user/services/user.service";
@Component({
  selector: 'app-track-trace-widget',
  templateUrl: './track-trace-widget.component.html',
  styleUrl: './track-trace-widget.component.scss'
})
export class TrackTraceWidgetComponent implements OnInit, OnDestroy{
  private ngUnsubscribe$ = new Subject<boolean>();
  tours: any[] = [];
  loading = true;
  now = new Date();
  private intervals: any[] = [];
  currentIdf = '9999910';

  constructor(private trackTraceRestService: TrackTraceRestService,
              private userService: UserService) {
    this.userService.getMainIdfObservable()?.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((response: any) => {
      if (response.return_object != undefined) {
        this.currentIdf=response.return_object;
      }
    });
  }

  ngOnInit(){
    this.loadLatestTours();
    this.intervals.push(setInterval(()=> {
      this.loadLatestTours();
    },30000))
  }

  loadLatestTours() {
    this.trackTraceRestService.getLatestTours(new TrackTraceRequest(21, this.currentIdf, this.now.toISOString().split('T')[0], false))
    .pipe(takeUntil(this.ngUnsubscribe$))
    .subscribe((tours: any) => {
        this.tours = tours.return_object.tour_information.tour;
        this.loading = false;
      },
      (error: any) => {
        this.loading = false;
      })
  }

  delay(tour: any) : string {
    const delay = Math.abs(tour.delay_in_minutes);

    if(delay) {
      if(delay > 30)
        return 'text-danger';
      else if( delay >= 10)
        return 'text-alert';
      else
        return 'text-success';
    }
    return 'no';
  }

  getTour(tour_id: any) {
    if (tour_id < 24) {
      return this.now.toLocaleDateString() + ' ' + tour_id.substring(0, 2) + ':' + tour_id.substring(2, 4);
    } else {
      const d = new Date(this.now);
      const diff = Math.floor(tour_id.substring(0, 2) / 24);
      let date = d;
      if(diff) {
        date = new Date(d.setDate(this.now.getDate() - diff));
      }

      return Intl.DateTimeFormat('de-DE').format(date) + ' ' + ('00' + (tour_id.substring(0, 2) - diff * 24)).slice(-2) + ':' + tour_id.substring(2, 4) + ' / ' + tour_id.substring(4)
    }
  }

  ngOnDestroy(): void {
    this.intervals.forEach((inter: any,  i: number) => { clearInterval(inter) });
    this.ngUnsubscribe$.next(false);
    this.ngUnsubscribe$.complete();
  }
}
