<div *ngIf="showNotification" [innerHTML]="notification.html"></div>

<div *ngIf="!showForm">
  <h1 class="title">Meine Mitbenutzer</h1>
  <p>Hier können Sie Zugänge für Ihre Mitarbeiter (Mitbenutzer) anlegen und individuelle Zugriffsrechte zu Filialen und
    Funktionen, z. B. Belegabfrage vergeben.</p>
  <mat-card class="py-m px-m mt-m">
    <mat-card-content><p *ngIf="subusers.length === 0">Sie haben noch keinen Mitbenutzer angelegt.</p>
      <div *ngIf="subusers.length > 0">
        <mat-table #subusers [dataSource] = "dataSource">
          <ng-container matColumnDef="subuser">
            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.name }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="authorities">
            <mat-header-cell *matHeaderCellDef> Apotheken </mat-header-cell>
            <mat-cell *matCellDef="let element" class="flex-column"><p *ngFor="let apo of element.idfs">{{apo}}</p> </mat-cell>
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> Aktionen </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-icon-button color="secondary" (click)="edit(element.actions)">
                <mat-icon>create</mat-icon>
              </button>
              <button mat-icon-button color="primary" (click)="remove(element.actions)">
                <mat-icon>delete</mat-icon>
              </button></mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button (click)="showForm=true" mat-flat-button color="primary" type="button">Mitbenutzer anlegen</button>
    </mat-card-actions>
  </mat-card>
</div>
<div *ngIf="showForm">
  <app-subuser
    *ngIf="(authTreeLoading$ | async) === false"
    [subUser]="subUser$"
    [authTree]="authTree"
    [allIdfs]="allIdfs"
    (cancel)="cancelSubUserForm()"
    (notify)="notify()"></app-subuser>
</div>
