export class InvoicesRequest{
  idf: number;
  docType: string;
  branch: number;
  startDate: string;
  endDate: string;
  docNr: number = 0;
  pzn: number = 0;
  description: string = ""

  constructor(obj: any) {
    /* /documents2Idf?idf=1436&branch=5&startDate=2010-10-1&endDate=2021-10-1&docNr=403746 */
    this.idf = obj && obj.idf;
    this.docType = obj && obj.docType;
    this.branch = obj && obj.branch;
    this.startDate = obj && obj.startDate;
    this.endDate = obj && obj.endDate;
    this.docNr = (obj && obj.docNr) ? obj.docNr : -1;
    this.pzn = (obj && obj.pzn) ? obj.pzn : 0;
    this.description = (obj && obj.description) ? obj.description : "";
  }
}
