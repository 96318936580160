import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';


@Component({
    selector: 'app-page-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit, OnChanges {

    @Input() body: any;
    @Input() public: boolean = false;
    public parsedBody: any[] = [];


    constructor(private router: Router,
                private elRef: ElementRef) {
    }

    ngOnInit(): void {
        this.parseBody(this.body);
    }

    private parseBody(body: string) {
        this.parsedBody = [];
        if (body !== undefined) {
            const matches = body.match(/\[form=([0-9]*)\]/g);
            if (matches) {
                for (const match of matches) {
                    const split = body.split(match);
                    this.parsedBody.push({data: split[0], type: 'string'});
                    this.parsedBody.push({data: match.replace(/\D/g, '').trim(), type: 'form'});
                    if (split.length > 1) {
                        this.parsedBody.push({data: split[1], type: 'string'});
                    }
                }
            } else {
                this.parsedBody.push({data: body, type: 'string'});
            }
        }
    }

    scrollTo($event: MouseEvent) {
        const target: any = $event.target;

        if (target && target['classList'].contains('scrollTo')) {
            $event.stopPropagation();
            $event.preventDefault();

            const hash = target['hash'].replace('#', '').trim();

            this.elRef.nativeElement.getElementsByClassName(hash)[0].scrollIntoView({behavior: 'smooth'});
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.parseBody(changes['body'].currentValue);
    }

}
