import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from "rxjs/internal/Observable";
import {
  LOAD_ALL_DOC_TYPES,
  LOAD_ALL_DOC_TYPES_COMPLETE,
  LOAD_ALL_DOC_TYPES_FAIL,
  LOAD_DOC_TYPES,
  LOAD_DOC_TYPES_COMPLETE,
  LOAD_DOC_TYPES_FAIL,
  LOAD_SEARCH_INVOICES,
  SEARCH_INVOICES_COMPLETE,
  SEARCH_INVOICES_FAIL
} from '../actions/invoices.actions';
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs/internal/observable/of";
import {Action} from "@ngrx/store";
import {InvoicesRestService} from "../../invoices/services/invoices.rest.service";
import {ResponseModel} from "../../base/response.model";

@Injectable()
export class InvoicesEffects {
  /**
   * In case of SearchInvoicesAction we're going to call the InvoicesService and then
   * we'll post a LoadInvoicesCompleteAction to the reducer or in case of failure
   * LoadInvoicesFailAction
   *
   * @type {Observable<any>}
   */
  searchInvoices$: Observable<Action> = createEffect(() => {
    return this.actions$
    .pipe(ofType(LOAD_SEARCH_INVOICES.type))
    .pipe(map((action: any) => action.payload),
      switchMap(invoicesRequest =>
        this.invoicesRestService.searchDocuments(invoicesRequest).pipe(
          map((data: ResponseModel) => ({type: SEARCH_INVOICES_COMPLETE.type, payload: data.return_object})),
          catchError((error: any) => of({type: SEARCH_INVOICES_FAIL.type, payload: error}))
        )
      ))
  });

  /**
   * On LoadUserDocTypesAction request idfs from gateway and store them via LoadUserDocTypesCompleteAction
   * or catch the error via LoadUserDocTypesFailAction
   *
   * @type {Observable<any>}
   */
  loadDocTypes$: Observable<Action> = createEffect(() => {
    return this.actions$
    .pipe(ofType(LOAD_DOC_TYPES.type),
      switchMap((action: any) => {
        return this.invoicesRestService.getDocumentTypes(action.payload).pipe(
          map((data: ResponseModel) => {
              let documentMap = new Map<number, any>();
              documentMap.set(action.payload.idf, data.return_object);
              return {type: LOAD_DOC_TYPES_COMPLETE.type, payload: {docTypes: documentMap}};
            },
            catchError((error: any) => of({type: LOAD_DOC_TYPES_FAIL.type, payload: error}))
          )
        )
      }));
  });

  /**
   * On LoadUserDocTypesAction request idfs from gateway and store them via LoadUserDocTypesCompleteAction
   * or catch the error via LoadUserDocTypesFailAction
   *
   * @type {Observable<any>}
   */
  loadAllDocTypes$: Observable<Action> = createEffect(() => {
    return this.actions$
    .pipe(ofType(LOAD_ALL_DOC_TYPES.type))
    .pipe(map((action: any) => action), switchMap(() =>
        this.invoicesRestService.getAllDocumentTypes().pipe(
          map((data: ResponseModel) => ({type: LOAD_ALL_DOC_TYPES_COMPLETE.type, payload: data.return_object})),
          catchError((error: any) => of({type: LOAD_ALL_DOC_TYPES_FAIL.type, payload: error}))
        )
      )
    );
  });

  /**
   * @param {InvoicesRestService} invoicesRestService
   * @param {Actions} actions$
   */
  constructor(
    private invoicesRestService: InvoicesRestService,
    private actions$: Actions
  ) {
  }
}
