import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatStepperModule} from "@angular/material/stepper";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from "@angular/material/button";
import {MatCheckboxModule} from '@angular/material/checkbox';
import {PublicRoutingModule} from './public-routing.module';
import {PrivateModule} from '../private/private.module';
import {PublicComponent} from "./public.component";
import {AuthService} from "../core/auth/services/auth.service";
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule} from "@angular/forms";
import {RegisterComponent} from "./register/register.component";
import {MissingDataComponent} from './register/missing-data/missing-data.component';
import {RegisterFormComponent} from './register/register-form/register-form.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {PasswordComponent} from './password/password.component';
import {ForgottenPasswordComponent} from './forgotten-password/forgotten-password.component';
import {DataCheckComponent} from "./register/data-check/data-check.component";
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {FooterModule} from "../shared/footer/footer.module";
import {MatGridListModule} from "@angular/material/grid-list";
import {ChangePasswordDialogComponent} from './change-password-dialog/change-password-dialog.component';
import {ActivateComponent} from "./activate/activate.component";
import {MatCardModule} from "@angular/material/card";

@NgModule({
  declarations: [
    PublicComponent,
    RegisterComponent,
    MissingDataComponent,
    RegisterFormComponent,
    PasswordComponent,
    ForgottenPasswordComponent,
    DataCheckComponent,
    PasswordResetComponent,
    ChangePasswordDialogComponent,
    ActivateComponent
  ],
  imports: [
    CommonModule,
    PublicRoutingModule,
    PrivateModule,
    MatStepperModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RouterModule,
    ReactiveFormsModule,
    FooterModule,
    MatGridListModule,
    MatCardModule
  ],
  providers: [
    AuthService
  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PublicModule {
}
