<div class="p-m">
<h1>Willkommen im HAGEDA Apothekenportal!</h1>
<div>
  Ihr nächster Schritt:

  <p>
    Mitbenutzer anlegen / Zugang für Ihre Filialen
  </p>
  <p>
    Damit Ihre Mitarbeiter in Ihren Apotheken ebenfalls einen Zugang zum Apothekenportal erhalten, legen Sie
    Mitbenutzer an. So können Sie bei Bedarf Ihren Filialen gezielt Zugriffe erteilen, z.B. auf Belege.
  </p>
<!--  <p>-->
<!--    Eine Anleitung finden Sie hier:<br>-->
<!--    <a href="#/open/static-content?url=%2Ffileadmin/statische-inhalte/PDF/Mitbenutzerverwaltung-Apothekenportal.pdf" target="_blank">Mitbenutzerverwaltung</a>-->
<!--  </p>-->
</div>
<div class="jcsb d-flex">
  <button color="secondary" mat-flat-button  (click)="close()">
    <span>NEIN, DANKE</span>
  </button>

  <button color="primary" mat-flat-button  (click)="close()" >
    <span routerLink="/subusers">MITBENUTZER ANLEGEN</span>
  </button>
</div>
</div>
