<h1 class="title">Mitarbeitern</h1>
<div *ngIf="showNotification"  [class]="notification.type">
  <p [innerHTML]="notification.html" class="m-m"></p>
</div>
<div *ngIf="!editUser">
  <div class="d-flex jcsb">
    <mat-form-field appearance="outline">
      <mat-label>Filter</mat-label>
      <input matInput (change)="applyFilter($event)" placeholder="Ex. Mia" #input>
    </mat-form-field>

    <div>
      <button mat-flat-button (click)="editUser=true; $event.preventDefault(); $event.stopPropagation()" color="primary">New user</button>
<!--      <button mat-flat-button color="primary" (click)="export($event); $event.preventDefault(); $event.stopPropagation()">Export users</button>-->
    </div>
  </div>
  <mat-table [dataSource]="dataSource" class="shadow" matSort #dataTable>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ 'USER.' + element.status | translate }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header="name">Name</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.name }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="username">
      <mat-header-cell *matHeaderCellDef mat-sort-header="username">Benutzername</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.username }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header="email">E-mail</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.email }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="group">
      <mat-header-cell *matHeaderCellDef mat-sort-header="group">Benutzergruppe</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ 'USER.' + element.group | translate }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="idfs">
      <mat-header-cell *matHeaderCellDef mat-sort-header="idfs">Idf liste</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-list role="list">
          <mat-list-item matListItemTitle *ngFor="let idf of element.idfs" [class.main-idf] = "idf === element.mainIdf" role="listitem">{{ idf }}</mat-list-item>
        </mat-list>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="edit(element)">
          <mat-icon fontSet="material-symbols-outlined" aria-hidden="false">create</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <mat-paginator [length]="total" [pageSize]="size" [pageSizeOptions]="[5, 10, 25, 100]" (page)="getUsers($event)" aria-label="Select page of users" class="mt-l"></mat-paginator>
</div>
<app-internal-form [user] = "selectedUser" *ngIf="editUser"></app-internal-form>
