import {Injectable} from '@angular/core';
import {jsPDF} from 'jspdf';
import autoTable from 'jspdf-autotable';
import {formatDate} from "@angular/common";

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  private baseFontSize: number = 10;
  private baseTextColor: number = 100;
  private doc: jsPDF = new jsPDF({format: 'a4', compress: true});


  exportToPdf(header: any[], rows: any[], filename: string, title: string, address?: any, data?: any) {
    this.doc = new jsPDF({compress: true, format: 'a4'});
    const pageWidth: number = this.doc.internal.pageSize.width || this.doc.internal.pageSize.getWidth();
    const pageHeight: number = this.doc.internal.pageSize.height || this.doc.internal.pageSize.getHeight();
    const noOfRows: number = rows.length;
    let footerY: number = 0;
    // logo

    const image: string = 'assets/images/logo.jpg';
    this.addImage(image, pageWidth);

    this.addHagedaAddress();
    // Pharmacy address
    if (address) {
      this.addPharmacyAddress(address);
    }
    // print date
    this.doc.setFontSize(this.baseFontSize);
    this.doc.setTextColor(this.baseTextColor);
    if (data?.hasOwnProperty('dateFormat')) {
      this.doc.text('Datum\n' + formatDate(Date.now(), data.dateFormat, 'de-DE'), pageWidth - 60, 80);
    } else {
      // this block is used by preorder archive as we are not passing any dateFormat
      this.doc.text(formatDate(Date.now(), 'mediumDate', 'de-DE'), pageWidth - 60, 80);
    }

    // title
    this.doc.setFontSize(this.baseFontSize * 2);
    this.doc.setTextColor(this.baseTextColor * 0.4);
    this.doc.text(title, 14, 95);
    // description

    let y = 105;
    if (data?.hasOwnProperty('desc')) {
      this.doc.setFontSize(this.baseFontSize);
      this.doc.setTextColor(this.baseTextColor);
      this.doc.text(data.desc, 14, y);
      y = 120;
    }
    // table
    let yPos = 0;
    autoTable(this.doc, {
      head: [header],
      headStyles: {fillColor: [237, 28, 36]},
      bodyStyles: {fontSize: this.baseFontSize * 0.8},
      body: rows,
      startY: y,
      showHead: 'firstPage',
      pageBreak: 'auto',
      didDrawPage: function(data) {
        yPos = (data.cursor?.y) ? data.cursor.y : 0;
      }
    });

    if(yPos > 0) {
      y = yPos + 18;
    } else{
      y = noOfRows*18 + y;
    }

    if(y > pageHeight - 30) {
      this.doc.addPage();
      const ycalc = y - pageHeight - 20
      footerY = (ycalc > 0) ? ycalc : 30;
    }

    let footY = 0;
    //calculate footerY
    if (data?.hasOwnProperty('footerTitle')) {
      if(footerY === 0) {
        const dimensions = this.doc.getTextDimensions(data.footerTitle);
        footY = pageHeight - 30 - dimensions.h;
      }
    }

    if (data?.hasOwnProperty('footerDesc')) {
      this.doc.setFontSize(this.baseFontSize * 1.5);
      const dimensions = this.doc.getTextDimensions(data.footerDesc);
      if(footerY === 0) {
        footY = (footY > 0) ? footY - dimensions.h : pageHeight - 30 - dimensions.h;
      }
    }

    if(footY > 0) {
      if(footY < y) {
        this.doc.addPage();
        footerY = 30;
      } else {
        footerY = footY;
      }
    }

    // footer description
    if (data?.hasOwnProperty('footerDesc')) {
      this.doc.setFontSize(this.baseFontSize);
      this.doc.setTextColor(this.baseTextColor);

      this.doc.text(data.footerDesc, 14, footerY + 10, {maxWidth: pageWidth - 28});
    }

    // footer Title
    if (data?.hasOwnProperty('footerTitle')) {
      this.doc.setTextColor(this.baseTextColor * 0.4);
      this.doc.setFontSize(this.baseFontSize * 1.5);
      this.doc.text(data.footerTitle, pageWidth / 2, footerY, {align: 'center'});
    }

    this.doc.save(`${filename}.pdf`);
  }

  addImage(image: string, pageWidth: number) {
    const img = new Image();
    img.src = 'assets/images/logo.png';
    this.doc.addImage(img, 'png', pageWidth - 40, 10, 28, 40);
  }

  addHagedaAddress() {
    // Phoenix headquarter address
    this.doc.setFontSize(this.baseFontSize * 0.8);
    this.doc.setTextColor(this.baseTextColor);
    this.doc.text(' Hageda-Stumpf GmbH & Co. KG · 81379 München', 14, 50);
  }

  addPharmacyAddress(address: any) {
    this.doc.setFontSize(this.baseFontSize);
    this.doc.setTextColor(100);
    this.doc.text(address, 14, 60);
  }
}
