<div>
  <p class="pt-2">
    Bitte legen Sie ein Passwort fest. Drei der folgenden Kriterien müssen erfüllt sein: <b>ein Klein-</b> oder <b>Großbuchstabe</b>,
    <b>eine Zahl</b> oder <b>ein Sonderzeichen</b>. Die minimale Passwortlänge beträgt 8 Zeichen.
  </p>
</div>
<form [formGroup]="passwordForm"  class="d-flex flex-column" >

      <mat-form-field appearance="outline" class="w-100" *ngIf="requiresPassword">
        <mat-label>Altes Passwort</mat-label>
        <input matInput formControlName="old_password" data-cy="old-password-field"
               [type]="hide ? 'password' : 'text'" autocomplete="off">
        <button mat-icon-button
                matSuffix
                (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
          <mat-icon fontSet="material-symbols-outlined" aria-hidden="false">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="oldPassword.hasError('required')">
          Bitte geben Sie Ihr altes Passwort ein.
        </mat-error>
      </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Passwort</mat-label>
    <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" tabindex="1">
    <button mat-icon-button
            matSuffix
            (click)="hide = !hide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
      <mat-icon fontSet="material-symbols-outlined" aria-hidden="false">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
    <mat-error *ngIf="password.hasError('minlength')">
      Ihr Passwort muss mindestens 8 Zeichen lang sein.
    </mat-error>
    <mat-error *ngIf="password.hasError('passwordval')">
      Ihr Passwort muss mindestens ein Kleinbuchstabe, ein Großbuchstabe, eine Zahl und eine Sonderzeichen haben.
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Passwort wiederholen</mat-label>
    <input matInput [type]="hide ? 'password' : 'text'" formControlName="confirmPassword" tabindex="2">
    <button mat-icon-button
            matSuffix
            (click)="hide = !hide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
      <mat-icon fontSet="material-symbols-outlined" aria-hidden="false">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
    <mat-error *ngIf="confirmPassword.hasError('required')">
      Bitte wiederholen Sie Ihr Passwort.
    </mat-error>
    <mat-error *ngIf="confirmPassword.hasError('mustMatch')">
      Ihre Passwörter stimmen nicht überein.
    </mat-error>
  </mat-form-field>
  <div class="d-flex jcsb">
    <button mat-flat-button color="primary" (click)="onSubmit()">{{buttonText}}</button>
  </div>
</form>
