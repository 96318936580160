import {Injectable, OnDestroy} from '@angular/core';
import {RestServiceAbstract} from "../../../core/base/rest.service.abstract";
import {Subject} from "rxjs";
import {OlInvoicesRequest} from "../../../core/invoices/api/ol.invoices.request.model";
import {map, takeUntil} from "rxjs/operators";
import {ResponseModel} from "../../../core/base/response.model";

@Injectable({
  providedIn: 'root'
})
export class PreordersRestService extends RestServiceAbstract implements OnDestroy {
  public ngUnsubscribe$: Subject<void> = new Subject<void>();

  public getPreorders(request: any) {
    return this.get(`/${this.RESOURCE}/preorders/${request}`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((response: Object) => {
        return new ResponseModel(response);
      })
    );
  }

  public  updatePreorders(period: any, idf: any, preorder: any) {
    return this.post(`/${this.RESOURCE}/preorders/${period}/${idf}`, preorder).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((response: Object) => {
        return new ResponseModel(response);
      })
    );
  }

  getPeriodPreorders(period_id: any, idf: string) {
    return this.get(`/${this.RESOURCE}/preorders/${period_id}/${idf}`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((response: Object) => {
        return new ResponseModel(response);
      })
    );
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    //this.ngUnsubscribe$.next();
    // This completes the subject properlly.
    this.ngUnsubscribe$.complete();
  }

}
