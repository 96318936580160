import {phx} from "../@types/models";
import {createReducer, on} from '@ngrx/store';
import * as ProductActions from '../actions/product.actions';
import Product = phx.portal.models.Product;

export const initialState: Product = {} as Product;

export const productReducer = createReducer(
  initialState,
  on(ProductActions.LOAD_PRODUCT_SEARCH, (state) => {
    return {
      ...state,
      productSearchLoading: true
    }
  }),
  on(ProductActions.LOAD_PRODUCT_SEARCH_COMPLETED, (state, data: any) => {
    return {
      ...state,
      ...data.payload,
      productSearchLoading: false
    }
  }),
  on(ProductActions.LOAD_PRODUCT_SEARCH_FAILED, (state, data: any) => {
    return {
      ...state,
      ...data.payload,
      productSearchLoading: false
    }
  }),
  on(ProductActions.LOAD_PRODUCT_SHOP, (state) => {
    return {
      ...state,
      productSearchLoading: true
    }
  }),
  on(ProductActions.LOAD_PRODUCT_SHOP_COMPLETED, (state, data: any) => {
    return {
      ...state,
      ...data.payload,
      productCache: [...state.productCache, ...data.payload.list],
      productSearchLoading: false
    }
  }),
  on(ProductActions.SEARCH_PRODUCT_SHOP, (state, data: any) => {
    return {
      ...state,
      ...data.payload,
      productCache: [],
      productSearchLoading: false
    }
  }),
  on(ProductActions.LOAD_PRODUCT_SHOP_FAILED, (state, data: any) => {
    return {
      ...state,
      ...data.payload,
      productSearchLoading: false
    }
  })
)
