import { Component } from '@angular/core';
import {UserService} from "../../core/user/services/user.service";
import {Observable, Subject} from 'rxjs';
import {UserModel} from "../../core/user/user.model";
import {map, takeUntil} from 'rxjs/operators';
import {Router, NavigationExtras} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  private user: UserModel | null = null;
  public loading = true;
  private ngUnsubscribe$ = new Subject<void>();

  constructor(private userService: UserService,
              private router: Router){
    this.userService.getUserResponseObservable().pipe(takeUntil(this.ngUnsubscribe$)).subscribe((userResponse: any) => {
      if(userResponse !== undefined){
        this.loading = false;
        this.user = userResponse.user;
      }
    });
  }

  isAdmin() {
    return this.user && (this.user.authoritiesEnum.indexOf('GROUP_MARKETING') > -1 ||
      this.user.authoritiesEnum.indexOf('GROUP_TECHNICAL') > -1);
  }


  canAccess (authorities: any[]) {
    if(this.user){
      if(this.userService.isAdmin()) {
        return true;
      }

      if(this.userService.isOwner()) {
        return true;
      }

      if(this.user.authoritiesEnum.filter(value => authorities.includes(value)).length > 0){
        return true;
      }
    }
    return false;
  }




  ngOnDestroy(){
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  goToSubusers() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        refreshToken: (new Date).getTime() //pass a dummy parameter (i.e. the time in milliseconds since 1970 or use the npm uuid library), forces reload of unique url
      },
    };

    this.router.navigate(['/subusers'], navigationExtras);
  }
}
