import {Component, Inject, Input, OnDestroy} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {PublicContactRequest} from "../../../core/contact/api/public.contact.request";
import {PublicContactRestService} from "../../../core/contact/services/public.contact.rest.service";

@Component({
  selector: 'app-missing-data',
  templateUrl: './missing-data.component.html',
  styleUrls: ['./missing-data.component.scss']
})
export class MissingDataComponent {
  @Input() data: any;
  missingDataForm = this.fb.group({
    idf: ['', [Validators.required, Validators.pattern(/^\d{2,7}$/)]],
    phone: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    zip: ['', [Validators.required, Validators.pattern(/^\d{5,5}$/)]],
    city: ['', Validators.required],
    pharmacyName: ['', Validators.required],
    ownerName: ['', Validators.required],
    contactPerson: ['']
  });
  showSuccessMessage = false;
  showErrorMessage = false;
  submitted = false;
  // contactSubscription: Subscription;

  constructor(private fb: FormBuilder,
              private publicContactRestService: PublicContactRestService) {
  }

  onSubmit() {
    if(this.missingDataForm.valid){
      const data = this.missingDataForm.value;
      let request = new PublicContactRequest(data);
      request.text = 'Übermittelte Daten:'
        + '\n\nIDF: ' + data.idf
        + '\n\nPLZ: ' + data.zip
        + '\nStadt: ' + data.city
        + '\nApothekename: ' + data.pharmacyName
        + '\nInhaber: ' + data.ownerName
        + '\nAlternative Kontaktperson: ' + data.contactPerson;

      request.subject = 'Fehlende Stammdaten für IDF ' + data.idf;

      this.publicContactRestService.makeContact(request).subscribe(()=>{
          this.showSuccessMessage = true;
          this.showErrorMessage = false;
        },
        error => {
          this.showErrorMessage = true;
          this.showSuccessMessage = false;
        });
    }
  }
}
