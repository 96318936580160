<div class="p-l">
  <h4 mat-dialog-title class="pt-0 mt-0">
    Upload file
  </h4>
  <div mat-dialog-content class="mb-l">
    <div (fileDropped)="onFileDropped($event)" class="dropzone text-center pb-s" dnd>
      <div class="m-l mt-m">
        <input (input)="onFileUploaded($event)" id="fileDrop" multiple="" type="file">
        <h4>Drag and drop file</h4>
        <h4>or</h4>
        <label for="fileDrop"><button mat-flat-button color="primary">Browse for file</button></label>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-between">
    <button (click)="onNoClick()" mat-flat-button >Nein</button>
  </div>
</div>
