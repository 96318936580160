<app-carousel></app-carousel>

<div *ngIf="action && action == 'passwort-erfolgreich-gesetzt'">
  Ihr neues Passwort wurde gespeichert. Geben Sie Ihren Benutzernamen und Ihr Passwort hier ein.
</div>

<mat-grid-list [cols]="columns" rowHeight="18rem">
  <mat-grid-tile>
    <div>
      <h1 class="title">Anmeldung</h1>
      <mat-card class="mb-l mr-s" *ngIf="loginError">
        <mat-card-content class="p-s">
          <div class="jcsb d-flex aic">
            <mat-icon fontSet="material-symbols-outlined" aria-hidden="false" color="error">error_outline</mat-icon>
            <p class="my-s"> Ihr Login war leider nicht erfolgreich. Bitte wiederholen Sie die Eingabe und achten Sie auf die
              korrekte Schreibweise von Benutzername und Passwort.</p>
          </div>
        </mat-card-content>
      </mat-card>
      <form class="d-flex flex-column" [formGroup]="loginForm" (submit)="login()">
        <mat-form-field appearance="outline">
          <mat-label>Benutzername</mat-label>
          <input matInput formControlName="username" tabindex="1">
          <!--            <mat-error *ngIf="username.hasError('required') && submitted">-->
          <!--              Bitte geben Sie Ihren Benutzernamen ein.-->
          <!--            </mat-error>-->
          <mat-error *ngIf="username.hasError('noIdf')">
            Die Eingabe Ihrer IDF-Nummer ist nicht möglich. Bitte geben Sie Ihren Benutzernamen
            ein, z. B. max.mustermann.
          </mat-error>
          <mat-error *ngIf="username.hasError('noEmail')">
            Die Eingabe Ihrer Emailadresse ist nicht möglich. Bitte geben Sie Ihren
            Benutzernamen ein, z. B. max.mustermann.
          </mat-error>
          <mat-error *ngIf="username.hasError('pattern')">
            Das Benutzernamenformat ist falsch (z. B. max.mustermann)
          </mat-error>

          <!--            <mat-error *ngIf="error || action">-->
          <!--              Das Benutzernamenformat ist falsch (z. B. max.mustermann)-->
          <!--            </mat-error>-->
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Passwort</mat-label>
          <input matInput
                 [type]="hide ? 'password' : 'text'"
                 formControlName="password"
                 tabindex="2">
          <button mat-icon-button
                  matSuffix
                  (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide">
            <mat-icon fontSet="material-symbols-outlined" aria-hidden="false">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
        </mat-form-field>
        <div class="d-flex jcsb">
          <a mat-button routerLink="/forgotten-password">Passwort vergessen?</a>
          <button mat-flat-button color="primary" tabindex="3" [disabled]="!loginForm.valid">Anmelden</button>
        </div>
      </form>
    </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <div>
      <h2 class="title">Registrierung</h2>
      <p>Registrieren Sie sich als Hageda-Stumpf Großhandelskunde kostenfrei und profitieren Sie von den zahlreichen
        Vorteilen des Apothekenportals.</p>
      <a mat-flat-button routerLink="/register" tabindex="4">zur Registrierung</a>
      <p>Sie benötigen Unterstützung?</p>
      <a mat-flat-button routerLink="open/page/hgf" tabindex="5">zu den Häufig gestellte Fragen</a>
    </div>
  </mat-grid-tile>
</mat-grid-list>
