import {Component, OnDestroy, OnInit} from '@angular/core';
import {FirstLoginService} from "../../core/auth/services/first.login.service";
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {FirstLoginDialogComponent} from "./first-login-dialog/first-login-dialog.component";
import {Subject} from 'rxjs';
import {UserModel} from "../../core/user/user.model";
import {takeUntil} from 'rxjs/operators';
import {UserService} from "../../core/user/services/user.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$ = new Subject<void>();
  public user: UserModel | null = null;
  public loading = true;

  constructor(private firstLogin: FirstLoginService,
              public userService: UserService,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    if (this.firstLogin.showDialog) {
      this.showFirstLoginDialog();
    }

    // this.userService.loadUser();
    this.userService.getUserResponseObservable().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(userResponse => {
      if (userResponse !== undefined) {
        this.loading = false;
        this.user = userResponse.user;
      }
    });
  }

  canAccessTile(authorities: any[]) {
    if (this.user) {
      if (this.userService.isAdmin()) {
        return true;
      }

      if (this.userService.isOwner()) {
        return true;
      }

      if (this.user.authoritiesEnum.filter(value => authorities.includes(value)).length > 0) {
        return true;
      }
    }
    return false;
  }

  showFirstLoginDialog() {
    const firstLoginDialogConfig = new MatDialogConfig();
    firstLoginDialogConfig.width = '35rem';
    const dialogRef = this.dialog.open(FirstLoginDialogComponent, firstLoginDialogConfig);
  }


  isAdmin() {
    return this.userService.isAdmin();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
