import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from "rxjs";
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../core/auth/services/auth.service";
import {RegistrationRequest} from "../../core/auth/api/registration.request";
import {RegistrationRestService} from "../../core/auth/services/registration.rest.service";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnDestroy, OnInit {
  public ngUnsubscribe$ = new Subject<any>(); // emit unsubscription
  public registerForm: FormGroup;
  public token: any = '';
  public success = false;
  public error = false;
  public missingData = false;
  public response: any = '';
  public data = {};

  public idf = new FormControl('', [
    Validators.required, Validators.pattern(/^\d{2,7}$/)
  ]);

  public zipCode = new FormControl('', [
    Validators.required,
    Validators.pattern(/^\d{5}$/)
  ]);

  public agb = new FormControl('', [Validators.required]);

  constructor(public authService: AuthService,
              private formBuilder: FormBuilder,
              private registrationRestService: RegistrationRestService,
              public router: Router,
              private route: ActivatedRoute) {

    this.registerForm = formBuilder.group({
      idf: this.idf,
      zipCode: this.zipCode,
      agb: this.agb
    });
  }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.ngUnsubscribe$))
    .subscribe((params: any) => {
      this.token = params['token'];
    },
      (error)=>{
        this.router.navigate(['/register'])
      });
  }

  backToLogin() {

  }

  submit() {
    this.success = false;
    this.error = false;
    this.missingData = false;
    if (this.registerForm.valid) {
      const registration = new RegistrationRequest(this.registerForm.value);

      this.registrationRestService.initRegistration(registration).pipe(takeUntil(this.ngUnsubscribe$)).subscribe((response: any) => {
          this.success = true;
          this.response = response;
        },
        (error: any) => {
          this.registerForm.reset();
          this.registerForm.updateValueAndValidity();
          this.error = true;
          this.success = false;
          this.data = registration;
          if (error.error.error_code === 'MISSING_PHARMACY_DATA') {
            this.missingData = true;
          }
        });
    }
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
