import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {UserService} from "../../core/user/services/user.service";
import {AuthService} from "../../core/auth/services/auth.service";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit{
  constructor(public userService: UserService,
              public authService: AuthService,
              public router: Router) {}

  ngOnInit() {
    this.authService.logout();
    setTimeout(() => {
      this.router.navigate(['/']);
    }, 5200);
  }
}
