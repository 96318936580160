import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {shareReplay, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {FormBuilder, Validators} from '@angular/forms';
import {PagesRestService} from '../../../../core/cms/services/pages.rest.service';
import {ResponseModel} from '../../../../core/base/response.model';
import {UserAdminRestService} from "../../../../core/user/services/user.admin.rest.service";
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {FileUploadComponent} from "../../file-upload/file-upload.component";
import {PageRequest} from "../../../../core/cms/models/page.request.model";

@Component({
  selector: 'app-page-form',
  templateUrl: './page-form.component.html',
  styleUrl: './page-form.component.scss'
})
export class PageFormComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$ = new Subject<void>();

  authorities: any[] = [];
  pageForm = this.formBuilder.group({
    id: [''],
    page_title: [''],
    nav_title: ['', Validators.required],
    relative_url: [''],
    content: ['', Validators.required],
    end_date: [''],
    start_date: [''],
    banner_image: [''],
    tile: [''],
    public: [''],
    back_link: [''],
    show_title: [''],
    published: [''],
    authorities: ['']
  })

  @Input() page: any = '';
  bannerImage: string = '';
  tile: string = '';
  pageTitle: string = '';
  success = '';
  error = '';

  constructor(private formBuilder: FormBuilder,
              private pageRestService: PagesRestService,
              private dialog: MatDialog,
              private userService: UserAdminRestService) {

  }

  ngOnInit() {
    this.userService.getAllAuthorities().subscribe((response: any) => {
      const auth = response.return_object;
      let groups: any[] = [];
      let auths: any[] = [];

      groups = auth['user_group'];
      let visAuths = auth['vis_auths'];
      let extAuths = auth['extended_auths'];
      auths = {...visAuths, ...extAuths}

      this.authorities = [
        {label: 'User group', authorities: groups},
        {label: 'Authority', authorities: visAuths}
      ]
    });

    if (this.page !== '') {
      this.pageRestService.pageDetails(this.page).pipe(takeUntil(this.ngUnsubscribe$), shareReplay(1)).subscribe((page: ResponseModel) => {
        if (page.error_code === null) {
          this.pageForm.patchValue(page.return_object)
          this.pageTitle = page.return_object.page_title;
          this.bannerImage = page.return_object.banner_image;
          this.tile = page.return_object.tile;
        }
      })
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  savePage() {
    const data = this.pageForm.value;
    const request = new PageRequest(data);
    this.pageRestService.savePage(request).pipe(takeUntil(this.ngUnsubscribe$), shareReplay(1)).subscribe((response: ResponseModel) => {
      if(response.error_code===null) {
        this.success = 'Page ' + request.page_title + ' succesfully saved';
      } else{
        this.error = 'Something went wrong, please try again later.'
      }
      setTimeout(()=>{
        this.error = '';
        this.success = '';
      }, 10000)
    });
  }

  uploadImage(tile: string) {
    let fileConfig = new MatDialogConfig();
    fileConfig.autoFocus = true;
    fileConfig.width = '35rem';

    fileConfig.data = {}

    this.dialog.open(FileUploadComponent, fileConfig).afterClosed()
    .pipe(takeUntil(this.ngUnsubscribe$)).subscribe((result: any) => {

      if (!result.error) {
        this.success = 'File ' + result.filename + ' saved';
        this.notify();
      } else {
        this.error = 'There was an error while saving your file, please, try again later.'
      }
    })
  }

  private notify() {
    setTimeout(() => {
      this.success = '';
      this.error = '';
    }, 15000);
  }
}
