import { AbstractControl, ValidatorFn } from '@angular/forms';

export class PasswordValidator {

  public static validate(): ValidatorFn {

    return (c: AbstractControl): { [key: string]: boolean } | null => {

      let isDigit = false;
      let isCaps = false;
      let isSmallLetter = false;
      let isSpecialChar = false;
      if ((/\d/.test(c.value))) {
        isDigit = true;
      }
      if (/[A-Z]/.test(c.value)) {
        isCaps = true;
      }

      if (/[a-z]/.test(c.value)) {
          isSmallLetter = true;
      }

      if (/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(c.value)) {
        isSpecialChar = true;
      }

      if (isDigit && isCaps && isSpecialChar && isSmallLetter) {
        return null;
      }
      return { passwordval: true };
    };
  }
}
