export class Address {
  idf: string;
  name: string;
  adress1: string;
  zip: string;
  city: string;

  constructor(obj: any = {}){
    this.idf = obj && obj.idf || null;
    this.name = obj && obj.name || null;
    this.adress1 = obj && obj.street || null;
    this.zip = obj && obj.zipCode || null;
    this.city = obj && obj.city || null;
  }
}
