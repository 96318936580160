import {select, Store} from '@ngrx/store';
import {UserRestService} from './user.rest.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {SubUserModel} from '../subuser.model';
import {AuthorizationNodeModel} from '../models/authorization.node.model';
import {phx} from "../../store/@types/models";
import SubUser = phx.portal.models.SubUser;
import {INIT_AUTH_TREE, LOAD_SUB_USERS} from "../../store/actions/subuser.actions";
import {map, takeUntil} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SubUserService {
  /**
   * @param {Store<State>} store
   * @param userRestService
   * @param userService
   */
  constructor(public store: Store<{ subUser: SubUser }>,
              public userRestService: UserRestService,
              public userService: UserService) {
    this.dispatchLoadSubUsersAction();
    this.dispatchInitAuthTreeAction();
  }

  dispatchLoadSubUsersAction() {
    this.store.dispatch(LOAD_SUB_USERS());
  }

  dispatchInitAuthTreeAction() {
    this.store.dispatch(INIT_AUTH_TREE());
  }

  selectSubUsersObservable(): Observable<SubUserModel[]> {
    this.dispatchLoadSubUsersAction();
    return this.store.select('subUser').pipe(map((sub:any) => {
      return sub.users
    }));
  }

  selectSubUsersLoadingObservable(): Observable<boolean> {
    return this.store.select('subUser').pipe(map((sub: any) => sub.subUsersLoading));
  }

  selectAuthTreeLoading(): Observable<boolean> {
    return this.store.select('subUser').pipe(map((sub: any) => sub.authTreeLoading));
  }

  selectInitAuthTree(): Observable<AuthorizationNodeModel[]> {
    return this.store.select('subUser').pipe(map((sub: any) => sub.authTree));
  }
}
