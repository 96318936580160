<mat-card *ngIf="success" class="mt-l">
  <mat-card-header>
    <mat-card-title><h2>Kontakt zu HAGEDA-STUMPF</h2></mat-card-title>
  </mat-card-header>
  <mat-card-content class="my-m">Ihre Anfrage wurde entgegengenommen. Wir melden uns schnellstmöglich bei Ihnen.</mat-card-content>
</mat-card>
<div class="d-flex" *ngIf="!success">
  <div class="left-container">
    <h1 class="title">NEHMEN SIE MIT UNS KONTAKT AUF!</h1>
    <p>Haben Sie Fragen, Anmerkungen oder Vorschläge?<br> Gerne können Sie uns hier Ihre Nachricht senden.</p>
    <form [formGroup]="contactForm">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" tabindex="10">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>E-Mail</mat-label>
        <input matInput formControlName="email" tabindex="20">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>IDF</mat-label>
        <input matInput formControlName="idf" tabindex="30">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Betreff</mat-label>
        <input matInput formControlName="subject" tabindex="40">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Nachricht</mat-label>
        <textarea matInput formControlName="text" tabindex="50" rows="4"
                  placeholder="Ihre Fragen und Anmerkungen"></textarea>
      </mat-form-field>
      <div>
        <button mat-flat-button color="primary" tabindex="60" [disabled]="!contactForm.valid" (click)="submitMessage()">
          Senden
        </button>
      </div>
    </form>
  </div>
  <div class="right-container">
    <h2 class="subheader">Schnell-Kontakt</h2>
    <p>Hageda-Stumpf GmbH & Co. KG</p>
    <a href="mailto:kundenportal@hagedastumpf.de" tabindex="100" class="link">kundenportal&#64;hagedastumpf.de</a>
  </div>
</div>
<app-footer></app-footer>
