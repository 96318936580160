<div *ngIf="showNotification"  [class]="notification.type">
  <p [innerHTML]="notification.html" class="m-m"></p>
</div>
<h2>Benutzer Bearbeiten</h2>
<div class="d-flex">
  <form [formGroup]="userForm" class="w-50">
    <input matInput type="hidden" formControlName="id" />
    <mat-form-field appearance="outline">
      <mat-label>Anrede</mat-label>
      <mat-select formControlName="salutation">
        <mat-option *ngFor="let salutation of getSalutationValues()" [value]="salutation">
          {{ salutation }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Titel</mat-label>
      <input matInput formControlName="title"/>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Vorname</mat-label>
      <input matInput formControlName="first_name"/>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="last_name"/>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Benutzername</mat-label>
      <input matInput formControlName="username"/>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Benutzergruppe</mat-label>
      <mat-select formControlName="group">
        <mat-option *ngFor="let group of userGroups | keyvalue" [value]="group.key">
          {{ group.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    <button mat-flat-button color="primary" (click)="submit()">Speichern</button>
  </form>
  <div class="w-50 d-flex jcsb">
    <div>
    <p>Status: {{ getStatus(user.user.status) }}</p>
    <div>Idfs:
      <mat-list>
        <mat-list-item role="listitem" *ngFor="let idf of user.idfs">
          <span [class.main-idf]="idf === user.mainIdf">{{ idf }}</span>
        </mat-list-item>
      </mat-list>
    </div>
    <p>E-mail: {{ user.email }}</p>
    </div>
    <div>
<!--      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">-->
<!--        &lt;!&ndash; This is the tree node template for leaf nodes &ndash;&gt;-->
<!--        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>-->
<!--          &lt;!&ndash; use a disabled button to provide padding for tree leaf &ndash;&gt;-->
<!--          <button mat-icon-button disabled></button>-->
<!--          {{node.name}}-->
<!--        </mat-tree-node>-->
<!--        &lt;!&ndash; This is the tree node template for expandable nodes &ndash;&gt;-->
<!--        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>-->
<!--          <button mat-icon-button matTreeNodeToggle-->
<!--                  [attr.aria-label]="'Toggle ' + node.name">-->
<!--            <mat-icon class="mat-icon-rtl-mirror">-->
<!--              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}-->
<!--            </mat-icon>-->
<!--          </button>-->
<!--          {{node.name}}-->
<!--        </mat-tree-node>-->
<!--      </mat-tree>-->
    </div>
  </div>
</div>
