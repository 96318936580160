<header class="header container d-flex aic jcsb">
  <div class="d-flex aic">
    <a routerLink="/dashboard">
      <img src="assets/images/hageda-stumpf-klein-logo.svg" alt="Hageda Stumpf klein logo">
    </a>
    <button mat-flat-button mat-link-button color="primary" class="text-uppercase ml-m" routerLink="/dashboard">Portal
    </button>
    <button mat-button color="neutral" class="text-uppercase ml-m" routerLink="/admin/admin-dashboard">Admin Dashboard
    </button>
    <button mat-button color="neutral" class="text-uppercase ml-m" routerLink="/admin/notifications-management">
      Notifications
    </button>
    <button mat-button color="neutral" class="text-uppercase ml-m" routerLink="/admin/user-management">Benutzer</button>
    <button mat-button color="neutral" class="text-uppercase ml-m" routerLink="/admin/internal-management">
      Mitarbeitern
    </button>
    <button mat-button color="neutral" class="text-uppercase ml-m" routerLink="/admin/cms-management">CMS</button>
  </div>
  <div class="d-flex aic">
    <button mat-icon-button color="neutral" class="text-uppercase" routerLink="/logout">
      <mat-icon color="neutral" aria-hidden="false">logout</mat-icon>
    </button>
  </div>
</header>
