import {Inject, Injectable, OnDestroy} from '@angular/core';
import {UserModel} from '../user.model';
import {NavModel} from '../../navigation/nav.model';
import {Store} from '@ngrx/store';
import {UserResponse} from '../api/user.response.model';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {IdfBranches} from '../../account/models/idf.branches.model';
import {Branch} from '../../account/models/branch.model';
import {UserRestService} from './user.rest.service';
import {Address} from '../../account/address.model';
// import {LogService} from '../../base/log.service';
import {phx} from "../../store/@types/models";
import {
  LOAD_ALL_USER_DISTRIBUTION_CENTER_ADDRESS,
  LOAD_AUTH_TOKEN,
  LOAD_AUTH_TOKENS,
  LOAD_USER,
  LOAD_USER_DISTRIBUTION_CENTER_ADDRESS,
  LOAD_USER_IDFS,
  LOAD_USER_IDFS_COMPLETE,
  LOAD_USER_MAIN_IDF,
  LOAD_USER_SA,
  LOAD_USER_VZS,
  LOGOUT
} from "../../store/actions/user.actions";
import User = phx.portal.models.User;

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  private ngUnsubscribe$ = new Subject<any>();

  private user$: Observable<any> | null = null;
  private mainDistributionCentersAdress$: Observable<any> | null = null;
  private allDistributionCentersAdress$: Observable<any> | null = null;
  private userResponse: UserResponse | null = null;
  private idfs: string[] = [];
  private allIdfs: any[] = [];
  private vzs: IdfBranches[] | null = null;
  private adresses$: Observable<Address[]> | null = null;
  private adressesLoading$: Observable<boolean> | boolean = false;

  private authToken$: Observable<string> | null = null;
  // PXPPO-1885 - Tokens for all of them sub IDFs, yo!
  private authTokens$: Observable<any> | null = null;

  private mainIdf$: Observable<any> | null = null;
  private idfs$: Observable<any> | null = null;
  private loadingUser = false;
  private count = 0;

  constructor(public store: Store<{ user: User }>,
              public userRestService: UserRestService,
              @Inject('CMS_URL') private CMS_URL: string,
              // public log: LogService
  ) {
  }

  /**
   * Get UserResponse from store.
   *
   * @returns {UserResponse}
   */
  public getUserResponseFromStore(): UserResponse | null {
    if (!this.user$) {
      this.user$ = this.loadUser();
    }

    if(!this.userResponse && this.user$)
    { this.user$?.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(user => {
        if(!user.userLoading){
          this.userResponse = user.userResponse;
        }
        this.loadingUser = user.userLoading;
      });
    }
    return this.userResponse;
  }

  getUserLoadingObservable(): Observable<boolean> {
    return this.store.select('user').pipe(map(user => user.userLoading));
  }

  getUserResponseObservable(): Observable<UserResponse> {
    return this.store.select('user').pipe(map(user => user.userResponse));
  }

  public loadUser(): Observable<any> | null {
    if (!this.user$) {
      this.store.dispatch(LOAD_USER());
      this.user$ = this.store.select('user');
      return this.user$;
    }
    return this.user$;
  }

  /**
   * Get User from store.
   *
   * @returns {User}
   */
  public getUser(): UserModel | null {
    if (!this.userResponse) {
      this.loadingUser = true;
      this.getUserResponseFromStore();
    }
    return this.userResponse ? this.userResponse.user : null;
  }

  getNav(): NavModel {
    return new NavModel();
  }

  getNavObservable(): Observable<NavModel> {
    return this.store.select('user').pipe(map(ur => ur.nav));
  }

  getNavLoadingObservable(): Observable<boolean> {
    return this.store.select('user').pipe(map(ur => ur.navLoading));
  }

  /**
   * @param authorities
   */
  hasRequiredAuthority(authorities: string[]): boolean {
    if (!authorities) {
      return false;
    }
    const userFromStore = this.getUserResponseFromStore();
    if (!userFromStore) {
      return false;
    }

    let user = this.getUser();

    if (!user) {
      return false;
    }
    return authorities.filter(value => -1 !== user?.authoritiesEnum.indexOf(value)).length > 0;
  }

  /**
   * @param feature
   */
  canUseFeature(feature: string): boolean {
    if (!feature) {
      return false;
    }

    const userFromStore = this.getUserResponseFromStore();

    if (!userFromStore) {
      return false;
    }

    const user = this.getUser();

    if (this.isMarketing()) {
      return true;
    }

    return (!user) ? false : user.featuresEnum?.indexOf(feature) > -1;
  }

  hasDigitalPackage(): boolean {

    const userFromStore = this.getUserResponseFromStore();

    if (!userFromStore) {
      return false;
    }

    const user = this.getUser();

    if (this.isMarketing()) {
      return true;
    }

    return (!user) ? false : user.authoritiesEnum.indexOf('E_SARE') > -1;
  }

  dispatchContextualizedIdfsRequest(context: string) {
    return this.store.dispatch(LOAD_USER_IDFS({payload: context}));
  }

  selectContextualizedIdfsObservable(context: string): Observable<any[]> {
    return this.store.select('user').pipe(takeUntil(this.ngUnsubscribe$), map(ur => ur.idfs));
  }

  getIdfsObservable(context: string = 'invoice'): Observable<any> {
    if (!this.idfs$) {
      this.dispatchContextualizedIdfsRequest(context);

      return this.idfs$ = this.store.select('user').pipe(takeUntil(this.ngUnsubscribe$), map(response => {
        return response;
      }));
    }

    return this.idfs$;
  }

  loadAllIdfs(): Observable<any[]> {
    this.dispatchContextualizedIdfsRequest('all');
    if (!this.allIdfs) {
      this.userRestService.getAllIDFs()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response) => {
        if (response.return_object) {
          const loadAllIdfComplete =
            {
              type: LOAD_USER_IDFS_COMPLETE.type,
              idfs: response.return_object,
              context: 'all'
            };
          // this.store.dispatch(loadAllIdfComplete);
        }
      });
    }
    return this.store.select('user').pipe(map((ur) => {
      this.allIdfs = ur.idfs
      return ur.idfs;
    }));
  }

  /**
   * @param idf
   */
  doesVzAlreadyExist(idf: string): boolean {
    const predicate = (vz: any) => vz.idf === idf;
    return (!this.vzs) ? false : (this.vzs.findIndex(predicate) > -1);
  }

  /**
   * @param idf
   */
  getDistributionCentersObservable(idf: string): Observable<IdfBranches[]> | null {

    if (!this.vzs || !this.doesVzAlreadyExist(idf)) {
      this.store.dispatch(LOAD_USER_VZS());
    }
    return this.store.select('user').pipe(map(ur => {
      return [new IdfBranches({})];
    }));
  }

  /**
   * @param idf
   */
  getDistributionCentersByIdf(idf: string): Branch[] | null {
    let branches: Branch[] = [];
    if (!this.vzs) {
      return null;
    }
    this.vzs.forEach((item) => {
      if (item.idf === idf) {
        // this.log.dir('UserService:getDistributionCentersByIdf:' + idf);
        // this.log.dir(item);
        branches = item.branches;
      }
    });
    return branches;
  }

  /**
   * @param idf
   */
  getMainDistributionCenterByIdf(idf: string): Branch | null {
    let branch = null;
    if (!this.vzs) {
      return null;
    }

    this.vzs.forEach((item) => {
      if (item.idf === idf) {
        // this.log.dir('UserService:getMainDistributionCenterByIdf:' + idf);
        // this.log.dir(item);
        branch = item.branches[0];
      }
    });
    return branch;
  }

  getDistributionCentersLoadingObservable(): Observable<boolean> {
    return this.store.select('user').pipe(map(ur => ur.userVZsLoading));
  }

  getIdfsLoadingObservable(): Observable<boolean> {
    return this.store.select('user').pipe(map(ur => ur.userIDFsLoading));
  }

  getShippingAdressesObservable(): Observable<Address[]> | null {
    if (!this.adresses$) {
      this.store.dispatch(LOAD_USER_SA());
    }
    this.adresses$ = this.store.select('user').pipe(map(ur => ur.shippingAdresses));
    return this.adresses$;
  }

  getShippingAdressesLoadingObservable() {
    this.adressesLoading$ = this.store.select('user').pipe(map(ur => ur.shippingAdressesLoading));
  }

  getMainDistributionCentersAdressObservable() {
    if (!this.mainDistributionCentersAdress$) {
      this.store.dispatch(LOAD_USER_DISTRIBUTION_CENTER_ADDRESS());
      this.mainDistributionCentersAdress$ = this.store.select('user').pipe(map(ur => ur.mainDistributionCenterAdress));
    }
    return this.mainDistributionCentersAdress$;
  }

  getAllDistributionCentersAdressObservable() {
    if (!this.allDistributionCentersAdress$) {
      this.store.dispatch(LOAD_ALL_USER_DISTRIBUTION_CENTER_ADDRESS());
      this.allDistributionCentersAdress$ = this.store.select('user').pipe(map(ur => ur.vzs));
    }
    return this.allDistributionCentersAdress$;
  }


  getMainIdfObservable(): Observable<any> | null {
    if (!this.mainIdf$) {
      this.store.dispatch(LOAD_USER_MAIN_IDF());
      // console.log(this.store);
      this.mainIdf$ = this.store.select('user').pipe(takeUntil(this.ngUnsubscribe$), map(user => user));
    }

    return this.mainIdf$;
    // return this.store.pipe(select(fromReducers.getUserMainIDF));
  }


  getMainIdfLoadingObservable(): Observable<boolean> {
    return this.store.select('user').pipe(map(ur => ur.mainIdfLoading));
  }

  getAuthTokenObservable(): Observable<string> | null {
    if (!this.authToken$) {
      this.store.dispatch(LOAD_AUTH_TOKEN());
      this.authToken$ = this.store.select('user').pipe(map(ur => ur.authToken));
    }
    return this.authToken$;
  }

  getAuthTokensObservable(): Observable<string> | null {
    if (!this.authTokens$) {
      this.store.dispatch(LOAD_AUTH_TOKENS());
      this.authTokens$ = this.store.select('user').pipe(map(ur => ur.authTokens));
    }
    return this.authTokens$;
  }

  getAuthTokensLoadingObservable(): Observable<boolean> {
    return this.store.select('user').pipe(map(ur => ur.authTokensLoading));
  }

  logout() {
    this.idfs = [];
    this.idfs$ = null;
    this.user$ = null;
    this.vzs = null;
    this.mainIdf$ = null;
    this.store.dispatch(LOGOUT());
  }

  isAdmin(): boolean {
    const user = this.getUser();

    if (!user) {
      return false;
    }

    return user.authoritiesEnum.indexOf('GROUP_MARKETING') > -1 ||
      user.authoritiesEnum.indexOf('GROUP_KSC') > -1 ||
      user.authoritiesEnum.indexOf('GROUP_SALES') > -1 ||
      user.authoritiesEnum.indexOf('GROUP_TECHNICAL') > -1;
  }

  isMarketing(): boolean {
    return this.getUserGroup() === 'GROUP_MARKETING';
  }

  isKsc(): boolean {
    return this.getUserGroup() === 'GROUP_KSC';
  }

  isDigitalPaket(): boolean {
    const user = this.getUser();
    return (!user) ? false : user.authoritiesEnum.indexOf('E_SARE') > -1;
  }

  isOwner(): boolean {
    return this.getUserGroup() === 'GROUP_ACCOUNT_OWNER';
  }

  getUserGroup() {
    const user = this.getUser();

    if (!user) {
      return false;
    }

    const index = user.authoritiesEnum?.findIndex(s => s.includes('GROUP_'));
    return user.authoritiesEnum[index];
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}

