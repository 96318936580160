export class AccountInfoModel {
  email: string;
  fax: string;
  idf: string;
  name: string;
  salutation: any;
  title: any;
  first_name: any;
  last_name: any;
  phone: string;
  street: string;
  zip_code: string;
  city: string;

  constructor(obj: any = {}) {
    this.email = obj && obj.email || null;
    this.fax = obj && obj.fax || null;
    this.name = obj && obj.name || null;
    if(obj && obj.hasOwnProperty('owner')){
      this.salutation = obj.owner.salutation;
      this.title = obj.owner.title;
      this.first_name = obj.owner.first_name;
      this.last_name = obj.owner.last_name;
    } else {
      this.title = null;
      this.salutation = null;
      this.first_name = null;
      this.last_name = null;
    }
    this.idf = obj && obj.idf || null;
    this.phone = obj && obj.phone || null;
    this.street = obj && obj.street || null;
    this.zip_code = obj && obj.zip_code || null;
    this.city = obj && obj.city || null;
  }
}
