export class PublicContactRequest {
  email: string;
  idf: string;
  phone: string;
  subject: string;
  text: string;


  constructor(obj: any) {
    this.email = obj && obj.email || null;
    this.idf = obj && obj.idf || null;
    this.phone = obj && obj.phone || null;
    this.subject = obj && obj.subject || null;
    this.text = obj && obj.text || null;
  }
}
