import {SubUserFormModel} from './models/subuser.form.model';
import {FlatIdfAuthoritiesModel} from './models/flat.idf.authorities.model';
import {AuthorizationNodeModel} from './models/authorization.node.model';

export class SubUserModel extends SubUserFormModel {
  id: number = 0;
  public flat_idf_authorities: FlatIdfAuthoritiesModel;
  public username: string;

  constructor(obj: any = {}) {
    super(obj);
    this.id = obj && obj.id || null;
    this.flat_idf_authorities = obj && obj.flat_idf_authorities || null;
    this.username = obj && obj.username || null;
  }

  isGranted(an: AuthorizationNodeModel) {
    if (Object.keys(this.flat_idf_authorities)
      && Object.keys(this.flat_idf_authorities).find((idf : string) => idf === an.idf)) {
      let flat_auth = (this.flat_idf_authorities as any)[an.idf];
      if (flat_auth) {
        return flat_auth.indexOf(an.authority) !== -1;
      }
    }
    return false;
  }
}
