import {Injectable} from '@angular/core';
import {CartItem} from "../cart.item.model";

@Injectable({
  providedIn: 'root',
})
export class CartService {
  public addCartItem(items: CartItem[]) {
    let addCartItems = [];
    let addQuantitiyTotal = 0;
    let addPriceTotal = 0;

    items.forEach((item: CartItem) => {
      addCartItems.push(item);
      addQuantitiyTotal += +item.quantity;
      addPriceTotal += (<number>item.quantity * <number>item.price);
    });
  }
}
