<div *ngIf="showNotification"  [class]="notification.type">
  <p [innerHTML]="notification.html" class="m-m"></p>
</div>
<h2>Benutzer Bearbeiten</h2>
<div class="d-flex">
  <form [formGroup]="userForm" class="w-50">
    <input type="hidden" formControlName="id" />
    <mat-form-field appearance="outline">
      <mat-label>Anrede</mat-label>
      <mat-select formControlName="salutation">
        <mat-option *ngFor="let salutation of this.getSalutationValues()" [value]="salutation">
          {{ salutation }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Titel</mat-label>
      <input matInput formControlName="title"/>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Vorname</mat-label>
      <input matInput formControlName="first_name"/>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="last_name"/>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>E-mail</mat-label>
      <input matInput formControlName="email"/>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="editUsername">
      <mat-label>Benutzername</mat-label>
      <input matInput formControlName="username"/>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Benutzergruppe</mat-label>
      <mat-select formControlName="group">
        <mat-option *ngFor="let group of userGroups | keyvalue" [value]="group.key">
          {{ group.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    <button mat-flat-button color="primary" (click)="submit()">Speichern</button>
  </form>
  <div class="w-50 d-flex jcsb" *ngIf="this.user.username">
    <div>
      <p>Status: {{ getStatus(user.user.status) }}</p>
      <div>Username: {{user.username}} <button mat-icon-button color="accent" (click)="editUsername=!editUsername"><mat-icon>create</mat-icon></button></div>
      <p>E-mail: {{ user.email }}</p>
    </div>
  </div>
</div>
