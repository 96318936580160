export class SubUserFormModel {
  first_name: string;
  last_name: string;
  title: string;
  salutation: string;
  password: string;
  password_repeat: string;

  constructor(obj: any) {
    this.first_name = obj && obj.first_name || null;
    this.last_name = obj && obj.last_name || null;
    this.title = obj && obj.title || null;
    this.salutation = obj && obj.user_salutation && obj.user_salutation.toLocaleUpperCase() || null;
    if (obj && obj.salutation) {
      this.salutation = obj && obj.salutation && obj.salutation.toLocaleUpperCase() || null;
    }
    this.password = obj && obj.password || null;
    this.password_repeat = obj && obj.password_repeat || null;
  }
}

