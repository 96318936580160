import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from "rxjs";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../core/auth/services/auth.service";
import {Router} from '@angular/router';
import {MustMatch} from "../../core/validators/must.match";
import {PasswordValidator} from "../../core/validators/password.validator";
import {CredentialsTokenRequest} from "../../core/auth/api/credentials.token.request";
import {RegistrationRestService} from "../../core/auth/services/registration.rest.service";
import {RenewCredentialsRequest} from "../../core/auth/api/renew.credentials.request";
import {PasswordRestService} from "../../core/auth/services/password.rest.service";

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnDestroy, OnInit {
  public ngUnsubscribe$ = new Subject<void>(); // emit unsubscription
  @Input() token: string = '';
  @Input() credentialsService: any = '';
  public hide = true;
  public password = new FormControl('', [
    Validators.required, Validators.minLength(8),
    PasswordValidator.validate()
  ]);
  public confirmPassword = new FormControl('', [
    Validators.required
  ]);
  public passwordForm: FormGroup;
  public buttonText: string = 'Registrierung abschliessen';
  @Input() type: string = ''
  @Output() success: EventEmitter<any> = new EventEmitter<any>();
  public oldPassword: FormControl = new FormControl('', [Validators.required]);
  @Input() requiresPassword= false;

  constructor(public authService: AuthService,
              private formBuilder: FormBuilder,
              public router: Router,
              private reqistrationRestService: RegistrationRestService,
              private passwordService: PasswordRestService) {
    this.passwordForm = formBuilder.group({
      password: this.password,
      confirmPassword: this.confirmPassword
    }, {
      validators: [MustMatch('password', 'confirmPassword')]
    });
    if (this.type == 'reset') {
      this.buttonText = 'Speichern';
    }

  }

  ngOnInit() {
    if(this.requiresPassword){
      this.passwordForm.addControl('old_password', this.oldPassword)
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  onSubmit() {
    if (this.passwordForm.valid) {
      var creds = this.passwordForm.value;
      if (this.token) {
        creds['token'] = this.token;
        var request = new CredentialsTokenRequest(creds);
        if(this.requiresPassword) {
          var req = new RenewCredentialsRequest(creds);
          this.passwordService.setNewCredentials(req).subscribe((data: any) => {
            this.success.next({password: this.confirmPassword.value, response: data.return_object});
          })
        }else if (this.credentialsService !== '') {
          this.credentialsService.setCredentials(request).subscribe((data: any) => {
            this.success.next({password: this.confirmPassword.value, response: data.return_object});
          })
        } else {
          this.reqistrationRestService.setCredentials(request).subscribe((data: any) => {
            this.success.next({password: this.confirmPassword.value, response: data.return_object});
          })
        }
      }
    }
  }
}
