import {Component,EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from "rxjs";
import {takeUntil} from 'rxjs/operators';
import {FormBuilder, FormControl,FormGroup, Validators} from "@angular/forms";
import {UserService} from "../../../core/user/services/user.service";
import {AccountService} from "../../../core/account/services/account.rest.service";
import {RegistrationRestService} from "../../../core/auth/services/registration.rest.service";

@Component({
  selector: 'app-data-check',
  templateUrl: './data-check.component.html',
  styleUrls: ['./data-check.component.scss']
})
export class DataCheckComponent implements OnDestroy, OnInit {
  public ngUnsubscribe$ = new Subject<any>(); // emit unsubscription
  @Input() data: any;
  @Input() token: any;
  @Output() continue: EventEmitter<string> = new EventEmitter<string>();
  public dataCheck: FormGroup;
  salutation = new FormControl('');
  title = new FormControl('');
  first_name = new FormControl('', Validators.required);
  last_name = new FormControl('', Validators.required);
  name = new FormControl('', Validators.required);
  idf = new FormControl('', [Validators.required, Validators.pattern(/^\d{2,7}$/)]);
  street = new FormControl('', Validators.required);
  zip_code = new FormControl('', [Validators.required, Validators.pattern(/^\d{5,5}$/)]);
  city = new FormControl('', Validators.required);
  email = new FormControl('', [Validators.required, Validators.email]);
  phone = new FormControl('', Validators.required);
  fax = new FormControl('');
  showErrorMessage =  false;
  showSuccessMessage =  false;

  constructor(private userService: UserService,
              private accountService: AccountService,
              private formBuilder: FormBuilder,
              private registrationRestService: RegistrationRestService) {
  this.dataCheck= this.formBuilder.group({
      salutation: this.salutation,
      title: this.title,
      first_name: this.first_name,
      last_name: this.last_name,
      name: this.name,
      idf: this.idf,
      street: this.street,
      zip_code: this.zip_code,
      city: this.city,
      email: this.email,
      phone: this.phone,
      fax: this.fax
    });
  }

  ngOnInit() {
    this.dataCheck.patchValue(this.data);
  }

  onSubmit() {
    if (!this.dataCheck.valid) {
      return;
    }
    const data = {...this.dataCheck.value, ...{token: this.token}};
    this.registrationRestService.changeData(data).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(result => {
    });
    this.continue.next('correction');
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

}
