export class DeliveryAvailabilityModel {
  distribution_center_id: bigint;
  idf: string;
  deliveries: Delivery[];

  constructor(obj: any = {}) {
    this.distribution_center_id = obj && obj.distribution_center_id || null;
    this.idf = obj && obj.idf || null;
    this.deliveries = obj && obj.deliveries?.map((delivery:any) => new Delivery(delivery)) || null;
  }
}

export class Delivery {
  pzn: string;
  quantity: bigint;

  constructor(obj: any = {}) {
    this.pzn = obj && obj.pzn || null;
    this.quantity = obj && obj.quantity || null;
  }
}
