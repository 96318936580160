import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from "./dashboard/dashboard.component";
import {authGuard} from "../core/auth/auth.guard";
import {PrivateComponent} from "./private.component";
import {InvoicesComponent} from "./invoices/invoices.component";
import {RevenueComponent} from "./revenue/revenue.component";
import {SubsequentDeliveriesComponent} from "./subsequent-deliveries/subsequent-deliveries.component";
import {LogoutComponent} from "./logout/logout.component";
import {SubusersComponent} from "./personal/subusers/subusers.component";
import {ContactComponent} from "../shared/contact/contact.component";
import {MatomoRouteData} from "ngx-matomo-client";
import {PreordersComponent} from "./preorders/preorders.component";
import {PreordersArchiveComponent} from "./preorders-archive/preorders-archive.component";
import {TrackTraceComponent} from "./track-trace/track-trace.component";

const routes: Routes = [{
  path: '',
  component: PrivateComponent,
  canActivate: [authGuard],
  data:
    {type: 'private'},
  children: [
    {
      path: '',
      // canActivateChild: [authGuard],
      children: [
        {path: '', redirectTo: 'dashboard', pathMatch: 'full', data: {name: 'Dashboard'}},
        {
          path: 'dashboard',
          component: DashboardComponent,
          canActivate: [authGuard],
          data: {
            name: 'Dashboard',
            matomo: {
              title: 'Dashboard'
            } as MatomoRouteData
          }
        },
        {
          path: 'belege',
          component: InvoicesComponent,
          canActivate: [authGuard],
          data: {
            name: 'Belege',
            matomo: {
              title: 'Belege'
            } as MatomoRouteData
          }
          },
        {
          path: 'umsatz',
          component: RevenueComponent,
          canActivate: [authGuard],
          data: {
            name: 'Umsatz',
            matomo: {
              title: 'Umsatz'
            } as MatomoRouteData
          }
        },
        {
          path: 'nachlieferung',
          component: SubsequentDeliveriesComponent,
          canActivate: [authGuard],
          data: {
            name: 'Nachlieferung',
            matomo: {
              title: 'Nachlieferung'
            } as MatomoRouteData
          }
        },
        {
          path: 'vorbestellung/:categoryUrl',
          component: PreordersComponent,
          canActivate: [authGuard],
          runGuardsAndResolvers: 'always',
          data: {
            name: 'Vorbestellung',
            matomo: {
              title: 'Vorbestellung'
            } as MatomoRouteData
          }
        },
        {
          path: 'vorbestellung-archiv',
          component: PreordersArchiveComponent,
          canActivate: [authGuard],
          runGuardsAndResolvers: 'always',
          data: {
            name: 'Vorbestellung Archiv',
            matomo: {
              title: 'Vorbestellung Archiv'
            } as MatomoRouteData
          }
        },
        {
          path: 'page/:url',
          loadChildren: () => import('../shared/cms/cms.module').then(m => m.CmsModule),
          canActivate: [authGuard],
          runGuardsAndResolvers: 'always'
        },
        {
          path: 'stammdaten', loadChildren: () => import('./personal/personal.module').then(m => m.PersonalModule),
          canActivate: [authGuard],
          runGuardsAndResolvers: 'always',
          data: {
            name: 'Stammdaten',
            matomo: {
              title: 'Meine Daten'
            } as MatomoRouteData
          }
        },
        {
          path: 'subusers', component: SubusersComponent,
          canActivate: [authGuard],
          runGuardsAndResolvers: 'always',
          data: {
            matomo: {
              title: 'Meine Mitbenutzer'
            } as MatomoRouteData
          }
        },
        {
          path: 'kontaktformular', component: ContactComponent,
          canActivate: [authGuard],
          data: {
            name: 'Kontakt',
            matomo: {
              title: 'Kontakt'
            } as MatomoRouteData
          }
        },
        {
          path: 'logout',
          component: LogoutComponent,
          data:
            {
              name: 'Logout',
              matomo: {
                title: 'Logout'
              } as MatomoRouteData
            }
        },
        {
          path: 'sendungsverfolgung',
          component: TrackTraceComponent,
          data: {
            name: 'Sendungsverfolgung',
            matomo: {
              title: 'Sendungsverfolgung'
            } as MatomoRouteData
          }
        },
        {
          path: 'shop',
          loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule),
        }
      ]
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivateRoutingModule {
}
