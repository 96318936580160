export abstract class CredentialsRequest{
  password: string;
  password_repeat: string;

  public constructor(obj: any = {}) {
    this.password = obj && obj.password || null;
    this.password_repeat = obj && obj.confirmPassword || null;
    if(obj && obj.hasOwnProperty('password_repeat')){
      this.password_repeat = obj.password_repeat;
    }
  }
}
