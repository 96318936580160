import {Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {RestServiceAbstract} from "./rest.service.abstract";
import {ResponseModel} from "./response.model";


@Injectable({
  providedIn: 'root',
})
export class BlobRestService extends RestServiceAbstract implements OnDestroy {
  protected ngUnsubscribe$: Subject<void> = new Subject<void>();

  public getSasToken() {
    return this.get(`/${this.AZURE}/editor-token`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return new ResponseModel(data);
      })
    );
  }

  public getReaderToken() {
    return this.get(`/${this.AZURE}/reader-token`).pipe(
      takeUntil(this.ngUnsubscribe$),
      map((data: any) => {
        return new ResponseModel(data);
      }));
  }



  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe$.next();
    // This completes the subject properlly.
    this.ngUnsubscribe$.complete();
  }
}
